import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { offerPackageRoutingModule } from './offerPackage-routing.module';
import { OfferPackageListComponent } from './offer-package-list/offer-package-list.component';
import { EditPackageComponent } from './edit-package/edit-package.component';
import { OfferPackageDetailsComponent } from './offer-package-details/offer-package-details.component';
import { OfferPackageAddComponent } from './offer-package-add/offer-package-add.component';

@NgModule({
    declarations: [
    
    OfferPackageListComponent,
    
    EditPackageComponent,
    
    OfferPackageDetailsComponent,
    
    OfferPackageAddComponent], 
    
    entryComponents: [

    ],
    imports: [
      CommonModule,
      FormsModule,
      MaterialModule,
      offerPackageRoutingModule
    ],
   
  })
  
  export class offerPackageModule{}