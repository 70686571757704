import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-add-artist',
  templateUrl: './add-artist.component.html',
  styleUrls: ['./add-artist.component.scss']
})
export class AddArtistComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  artistProfileinfo: FormGroup;
  artist_id: number;
  artistProfile: any;
  artistImage: any;
  firstName: any;
  lastName: any;
  accountId: any;
  intro: any;
  languages: any;
  price: any;
  social: any;
  artistCategory: any;
  songList: any;
  travelCity: any;
  bankDetails: any;
  bankdetails: any;
  kycDetails: any;
  instagram: any;
  youtube: any;
  genre_category: any;
  event_category: any;
  instrument_category: any;
  gender: any;
  career_achievements: any;
  other_details: any;
  why_hire_you: any;
  id: any;
  name: any;
  description: any;

  @ViewChild('fileInput', { static: false }) el: ElementRef;
  editFile: boolean = true;
  removeUpload: boolean = false;
  imageUrl: any;
  fileData: File = null;
  // profile_image_url:any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  profile_image_url: any;
  song_list: any;
  image_url: any;
  songList_image_url: any;
  fileDataSongImg: File = null;
  private _jsonURL = 'assets/artistData.json';
  artist_category: any;
  eventCategory: any;
  genreCategory: any;
  instrumentsCategory: any;

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    public fb: FormBuilder,
    private http: HttpClient,
    private _location: Location,
  ) {
    this.getJSON().subscribe(data => {
      console.log("json data api" + JSON.stringify(data));
      this.artist_category = data.artist_category;
      this.genreCategory = data.genre_category;
      this.eventCategory = data.event_category;
      this.instrumentsCategory = data.instruments_category;

    });
  }
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  registrationForm = this.fb.group({
    file: [null]
  })

  ngOnInit() {
    this.artistProfileinfo = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      // account_id: ["", Validators.required],
      gender: ["", Validators],
      email: ["", Validators],
      mobile: ["", Validators],
      intro: ["", Validators.required],
      career_achievements: ["", Validators],
      other_details: ["", Validators],
      why_hire_you: ["", Validators],
      fb: ["", Validators],
      youtube: ["", Validators],
      instagram: ["", Validators],
      price_one_hour: ["", Validators],
      price_two_hour: ["", Validators],
      price_three_hour: ["", Validators],
      // id: ["", Validators],
      name: ["", Validators],
      price: ["", Validators],
      description: ["", Validators],
      // song_list: this.formBuilder.group({
      //   song_name: ["", Validators],
      //   film_name: ["", Validators],
      //   image_url: ["", Validators],

      account_name: ["", Validators],
      account_no: ["", Validators],
      bank_name: ["", Validators],
      ifsc_code: ["", Validators],
      micr: ["", Validators],
      pan_no: ["", Validators],
      adhaar: ["", Validators],
      gstin: ["", Validators],
      descriptions: ["", Validators],
      languages: ["", Validators],
      artist_category: ["", Validators.required],
      genre_category: ["", Validators],
      event_category: ["", Validators],
      instrument_category: ["", Validators],
      travel_city: ["", Validators],
    })
  }

  uploadImage(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image_url = reader.result;
    }
    this.enableSpinner$.next(true);
    this.commonService.uploadImage(this.fileData).subscribe(response => {
      this.enableSpinner$.next(false);
      console.log(response);
      this.profile_image_url = response["Data"];
      this.commonService.imgurl = this.profile_image_url[0];
    })
  }




  saveForm() {
    console.log(this.artistProfileinfo.value)
    const payLoad = {
      // artist_id: { type: String, default: 'live' + uniqid(), unique: true },
      // is_deleted: { type: Boolean, default: true },
      // event_count: { type: Number, default: 0 },
      personal_info: {
        gender: this.artistProfileinfo.value.gender,
        // account_id: { type: String },
        profile_image_url: this.profile_image_url,
        first_name: this.artistProfileinfo.value.first_name,
        last_name: this.artistProfileinfo.value.last_name,
        mobile: this.artistProfileinfo.value.mobile,
        email: this.artistProfileinfo.value.email,
      },
      // is_top_pick: { type: Boolean },
      // top_pick_gallery: {
      //   card_1: { type: String },
      //   card_2: { type: String },
      //   card_3: { type: String }
      // },
      // plan: {
      //   id: { type: String },
      //   expiry_date: { type: Date },
      // },
      // razorpay_account_id: { type: String },
      // agency_account_id: { type: String },
      // travel_city: { type: [String],: [true, 'No city selected'] },
      hourly_price: {
        price_one_hour: this.artistProfileinfo.value.price_one_hour,
        price_two_hour: this.artistProfileinfo.value.price_two_hour,
        price_three_hour: this.artistProfileinfo.value.price_three_hour,
      },
      about: {
        intro: this.artistProfileinfo.value.intro,
        career_achievements: this.artistProfileinfo.value.career_achievements,
        other_details: this.artistProfileinfo.value.other_details,
        why_hire_you: this.artistProfileinfo.value.why_hire_you,
      },
      // image: { type: [String] },
      artist_category: this.artistProfileinfo.value.artist_category,
      // artist_subcategory: { type: [String], required: [true, 'No  subcategory selected'] },
      genre_category: this.artistProfileinfo.value.genre_category,
      event_category: this.artistProfileinfo.value.event_category,
      languages: this.artistProfileinfo.value.languages,
      instrument_category: this.artistProfileinfo.value.instrument_category,
      social_media: {
        // twitter: { type: String },
        fb: this.artistProfileinfo.value.fb,
        instagram: this.artistProfileinfo.value.instagram,
        youtube: this.artistProfileinfo.value.youtube,
      },
      addons: [{
        name: this.artistProfileinfo.value.name,
        // id: this.artistProfileinfo.value.id,
        price: this.artistProfileinfo.value.price,
        description: this.artistProfileinfo.value.description,
      }],
      // status: { type: String },
      // gallery:
      // {
      //   videos:
      //     [{
      //       id: { type: String },
      //       title: { type: String },
      //       subtitle: { type: String },
      //       youtube_id: { type: String },
      //       genre: { type: String },
      //     }],

      //   images:
      //     [{
      //       image_url: { type: String },
      //       id: { type: String },
      //     }],
      // },
      // onboarding_questions_answers: [{ question_slug: { type: String }, choice_id: { type: Boolean } }],
      artist_payment_details: {
        bank_details: {
          account_name: this.artistProfileinfo.value.account_name,
          account_no: this.artistProfileinfo.value.account_no,
          bank_name: this.artistProfileinfo.value.bank_name,
          branch_name: this.artistProfileinfo.value.branch_name,
          ifsc_code: this.artistProfileinfo.value.ifsc_code,
          micr: this.artistProfileinfo.value.micr,
        },
        kyc_details: {
          pan_no: this.artistProfileinfo.value.pan_no,
          adhaar: this.artistProfileinfo.value.adhaar,
          gstin: this.artistProfileinfo.value.gstin,
          description: this.artistProfileinfo.value.description,
        }
      },
      // code: { type: String },
      // artist_level: { type: String, enum: ['gold', 'silver'] },
      // artist_management_fee_rate: { type: String },
      // is_active: { type: Boolean, default: true },
      // is_popular: { type: Boolean },
      // sorting_order: { type: String },
      // location: { type: String },
      // rating_average: { type: String },
      // is_admin_approved: { type: Boolean, default: true }, 
      // review_count: { type: Number },
      // summary:
      // {
      //   satisfaction: { type: String },
      //   professionalism: { type: String },
      //   communication: { type: String },
      //   punctuality: { type: String },
      // },
      // song_list: [{ id: { type: String }, song_name: { type: String }, film_name: { type: String }, image_url: { type: String } }],
    }
    console.log(payLoad)
    this.enableSpinner$.next(true);
    this.commonService.addNewArtistInfo(payLoad).subscribe(success => {
      this.enableSpinner$.next(false);
      console.log(success.Response);
      if (success.Response == true) {
        this.openSnackbar(success.message, 'OK')
        this.router.navigate(["/viewDetail"]);
      } else{
        this.openSnackbar('Some thing went Wrong', 'ok')
        console.log("error");
      }

    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  // songImage Upload


  fileProgressSongImg(fileInput: any) {
    this.fileDataSongImg = <File>fileInput.target.files[0];
    this.previewSongImg();
  }

  previewSongImg() {
    // Show preview 
    debugger;
    this.commonService.formData = this.artistProfileinfo.value;
    var mimeType = this.fileDataSongImg.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileDataSongImg);
    reader.onload = (_event) => {
      this.songList_image_url = reader.result;
    }
    // this.onSubmit();
    // this.songImg();
  }


  //   songImg() {
  //   const formData = new FormData();
  //   formData.append('file', this.fileDataSongImg);
  //   this.http.post('http://13.233.178.250:4000/core/v1/api/userprofile/uploadimage', formData)
  //     .subscribe(res => {
  //       console.log(res);


  //       this.image_url = res["Data"];
  //       this.commonService.songImgurl = this.image_url[0];
  //       console.log("common service profile songImgurl" + JSON.stringify(this.commonService.songImgurl));
  //       // this.setFormValue(this.commonService.formData, this.commonService.songImgurl,  this.commonService.imgurl);
  //       console.log("image url is" + JSON.stringify(this.image_url));

  //       // localStorage.setItem("profile_image_url", this.profile_image_url);
  //       // this.getArtistDetails();
  //     })
  // }

  get formArr() {
    return this.artistProfileinfo.get('song_list') as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      song_name: [''],
      film_name: [''],
      image_url: [''],
    });
  }
  addNewRow() {
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
  }

  goBack() {
    this._location.back();
  }


}

