import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ChatListComponent } from './chat-list/chat-list.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'chat-List',
        pathMatch: 'full'
    },
    { path: 'chat-List', component: ChatListComponent  },
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class chatRoutingModule {}