import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

interface Select {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-user-package-list',
  templateUrl: './user-package-list.component.html',
  styleUrls: ['./user-package-list.component.scss']
})
export class UserPackageListComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  userPackageList: any = [];
  artistSearchList: any = [];
  currentPage: number = 1;
  successResult: any = [];
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  id: any;

  selection: Select[] = [
    { value: 'view', viewValue: 'View' },
    { value: 'edit', viewValue: 'Edit' },
    { value: 'delete', viewValue: 'Delete' }
  ];
  selectedValue: any;

  constructor(private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {

    this.tableData1 = {
      headerRow: ['User Id', 'Name', 'Contact No', 'Email', 'Location', 'Addon', 'Actions'],
    };
    this.getOfferPackage();
  }

  getOfferPackage() {
    this.currentPage = 1;
    const PayLoad = {
      type: 'package'
    }
    this.enableSpinner$.next(true);
    this.commonService.getAllList(PayLoad, this.nextPage).subscribe(data => {
      this.enableSpinner$.next(false);
      this.totalPage = data.Response.count;
      this.nextPage += 1;

      data.Response.forEach(element => {
        this.userPackageList.push(element)
      })

    })
  }

  addUserPackage() {
    this.router.navigate(["/userPackage", "add-userPackage"]);
  }

  selectChange(event, id) {
    console.log(event.value);
    console.log(id);
    this.selectedValue = event.value;
    if (this.selectedValue === 'view') {
      this.router.navigate(["/userPackage", "user-package-details"], { queryParams: { id: id } });
    } else if (this.selectedValue === 'edit') {
      this.router.navigate(["/userPackage", "user-package-edit"], { queryParams: { id: id } });
    } else {
      console.log('delete')
    }
  }

  // viewDetails(id){
  //   console.log(id);
  //   this.router.navigate(["/userPackage", "user-package-details"], { queryParams: { id: id }});

  // }
  // editDetails(id){
  //   console.log(id);
  //   this.router.navigate(["/userPackage", "user-package-edit"], { queryParams: { id: id }});
  // }

  viewMoreArtists() {
    this.getOfferPackage();
  }

  //search
  search(event) {
    this.searchResult = event.target.value;
    setTimeout(() => {
      this.currentPage = 1;
      this.searchApiCal();
    }, 1500);

  }

  searchApiCal() {
    if (this.searchResult.length >= 3) {
      this.userPackageList = [];
      this.nextPage = 1;
      const payload = {
        first_name: true,
        artist: this.searchResult
      }
      this.enableSpinner$.next(true);
      this.commonService.search(payload, this.currentPage).subscribe(success => {
        this.enableSpinner$.next(false);
        if (success.Response) {
          this.currentPage += 1;
          this.successResult = success.Response.result;
          success.Response.forEach(el => {
            this.userPackageList.push(el);
            console.log(this.userPackageList);
          })
        }
      });
    } else if (this.searchResult.length < 1) {
      this.nextPage = 1;
      this.userPackageList = [];
      this.getOfferPackage();
    }
  }

  viewMoreSearch() {
    const payload = {
      first_name: true,
      artist: this.searchResult
    }
    this.enableSpinner$.next(true);
    this.commonService.search(payload, this.currentPage).subscribe(success => {
      this.enableSpinner$.next(false);
      if (success.Response) {
        this.currentPage += 1;
        this.successResult = success.Response.result;
        success.Response.forEach(el => {
          this.userPackageList.push(el);
        })
      }
    });
  }

}
