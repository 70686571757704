import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-events',
  templateUrl: './edit-events.component.html',
  styleUrls: ['./edit-events.component.scss']
})
export class EditEventsComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  enquiryBasicInfoForm: FormGroup;
  private _jsonURL = 'assets/artistData.json';
  artist_category: any;
  genreCategory: any;
  eventCategory: any;
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  contactName: any;
  description: any;
  userId: any;
  message: any;
  artist: any;
  location: any;
  eventType: any;
  artistCategory: any;
  artistSubCategory: any;
  genre: any;
  genre_category: any;
  event_category: any;
 

  constructor( private http: HttpClient,
               private formBuilder: FormBuilder,
               private commonService:CommonService,
               private ativateRouter: ActivatedRoute,
               private _location: Location,
               private snackBar: MatSnackBar,
               private router: Router) { 
                  this.getJSON().subscribe(data => {
                  // console.log("json data api" + JSON.stringify(data));
                  this.artist_category = data.artist_category;
                  this.genreCategory = data.genre_category;
                  this.eventCategory = data.event_category;                  
                }); 
              }
   public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
  
  ngOnInit() {

    this.ativateRouter.queryParams.subscribe(param =>{
      this.id= param.id;
    })
  this.getEventDetails();

    console.log(this.id)
    this.enquiryBasicInfoForm = this.formBuilder.group({
      user: this.formBuilder.group({
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        contact_no: ["", Validators.required],
        email: ["", Validators.required],
        message: ["", Validators.required],
      }),
  
      // budget: this.formBuilder.group({
      //   min: ["", Validators.required],
      //   max: ["", Validators.required]
      // }),

      event_loction: this.formBuilder.group({
        full_address: ["", Validators.required],
      }),
      // event_date:["", Validators.required],
      artist_category: ["", Validators.required],
      genre_category: ["", Validators.required],
      event_category: ["", Validators.required]
    }) 
   
  }
  getEventDetails(){
    this.enableSpinner$.next(true);
    this.commonService.eventDetails(this.id).subscribe(data =>{
      this.enableSpinner$.next(false);
    console.log(data.Response[0]);
    this.firstName= data.Response[0].user.first_name;
    console.log(this.firstName)
    this.lastName= data.Response[0].user.last_name;
    this.contactName= data.Response[0].user.contact_no;
    this.email= data.Response[0].user.email;
    this.userId= data.Response[0].user.user_id;
    this.message= data.Response[0].user.message;
    this.artistCategory= data.Response[0].artist_category;
    this.artistSubCategory= data.Response[0].artist_subcategory;
    this.event_category= data.Response[0].event_type;
    console.log(this.event_category)
    this.genre_category= data.Response[0].genre;
    this.location= data.Response[0].event_location.full_address;
    this.description= data.Response[0].description;
    this.id= data.Response[0].id;
    this.artist= data.Response[0].artists;
    this.enquiryBasicInfoForm.patchValue({
      user: {
        first_name: this.firstName,
        last_name:this.lastName,
        contact_no:this.contactName,
        email: this.email,
        message: this.message,
      },
      event_loction: {
        full_address: this.location,
      },
      // event_date: this.eventDate,
      artist_category: this.artistCategory,
      genre_category: this.genre_category,
      event_category: this.event_category,
    })
    });
  } 
  
  saveForm(){
    console.log(this.enquiryBasicInfoForm.value);
    // console.log(...this.enquiryBasicInfoForm.value)
    const PayLoad= {
      artist_category: this.enquiryBasicInfoForm.value.artist_category,
      genre: this.enquiryBasicInfoForm.value.genre_category,
      event_category: this.enquiryBasicInfoForm.value.event_category,

      // event_date: this.enquiryBasicInfoForm.value.event_date,
      event_loction: {
        lat: "asd",
        lng: "skdj",    
        full_address: this.enquiryBasicInfoForm.value.event_loction.full_address
      },
     
      id: this.id,
      user: {
        first_name: this.enquiryBasicInfoForm.value.user.first_name,
        last_name: this.enquiryBasicInfoForm.value.user.last_name,
        contact_no: this.enquiryBasicInfoForm.value.user.contact_no,
        email: this.enquiryBasicInfoForm.value.user.email,
        message: this.enquiryBasicInfoForm.value.user.message,
        user_id: "aaa"
      },
      enquiry_type: "direct",
    }
    
    let live= this.id;
    console.log(PayLoad);
    this.enableSpinner$.next(true);
    this.commonService.editEvent(this.id,PayLoad).subscribe(response =>{
      this.enableSpinner$.next(false);
      console.log(response);
      if(response.success === true){
        // this.openSnackbar('Event Save Successfully', 'ok');
        this.goBack();
      } else {
        this.openSnackbar('Error Occured', 'ok')
      }
    })
  }
  openSnackbar(message:string, action: string){
    this.snackBar.open(message,action,{
      duration: 2000,
    });
  }

  goBack(){
    this._location.back();
  }
}

