import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ArtistListComponent } from './artist-list/artist-list.component';
import { AddArtistComponent } from './add-artist/add-artist.component';
import { EditArtistComponent } from './edit-artist/edit-artist.component';
import { ViewArtistDetailComponent } from './view-artist-detail/view-artist-detail.component';
import { ViewReviewComponent } from './view-review/view-review.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'artist-list',
        pathMatch: 'full'
    },
    { path: 'artist-list', component: ArtistListComponent },
    { path: 'addArtist', component: AddArtistComponent },
    { path: 'viewDetail', component: ViewArtistDetailComponent }, 
    { path: 'editArtist', component: EditArtistComponent },
    {path: 'artistReview', component: ViewReviewComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class artistRoutingModule { }