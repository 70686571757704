import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'artistCategoryPipe' })
export class ArtistCategoryPipe implements PipeTransform {

    artistData: any ={
        "singer":"Singer",
        "music_band":"Music Band",
        "d_j":"Dj",
        "musician":"Musician",
        "comedian":"Comedian",
        "magician":"Magician",
        "emcee":"Emcee / Anchor",
        "dancer":"Dancer / Troupe",
        "speaker":"Speaker",
        "variety_artist":"Variety Artist",
        "celebrity":"Celebrity",
        "kids_entertainers":"Kids Entertainers",
        "unique_acts":"Unique Acts",
        "live_acts":"Live Acts"
     };


    transform(value: any){
        return this.artistData[value];
        // return this.citiesData[value];
        
    }
}