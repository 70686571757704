import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

declare interface AdminInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}


declare interface ManagerInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
    // { path: '/dashboard', title: 'Dashboard',  icon: 'pe-7s-graph', class: '' },
    { path: '/artists', title: 'Artist',  icon:'pe-7s-user', class: '' },
  { path: '/artist-sourcing', title: 'Artist Sourcing',  icon:'pe-7s-user', class: '' },
    // { path: '/enquiry', title: 'Enquiry',  icon:'pe-7s-user', class: '' },
    // { path: '/event', title: 'Event',  icon:'pe-7s-user', class: '' },
    // { path: '/chat', title: 'Chat',  icon:'pe-7s-user', class: '' },
    // { path: '/package', title: 'Package',  icon:'pe-7s-user', class: '' },
    // { path: '/userPackage', title: 'User Package',  icon:'pe-7s-user', class: '' },
    // { path: '/offerPackage', title: 'User Offer',  icon:'pe-7s-user', class: '' },
    // { path: '/suprise', title: 'Suprise',  icon:'pe-7s-user', class: '' },
];

export const Admin : AdminInfo[]=[
  { path: '/dashboard', title: 'Dashboard',  icon: 'pe-7s-graph', class: '' },
  { path: '/artists', title: 'Artist',  icon:'pe-7s-user', class: '' },
  { path: '/artist-sourcing', title: 'Artist Sourcing',  icon:'pe-7s-user', class: '' },
  // { path: '/new-artists-registered', title: 'New Artists',  icon:'pe-7s-user', class: '' },
  { path: '/enquiry', title: 'Enquiry',  icon:'pe-7s-user', class: '' },
  { path: '/event', title: 'Event',  icon:'pe-7s-user', class: '' },
  { path: '/business-enquiry', title: 'Business Enquiry',  icon:'pe-7s-user', class: '' },
  { path: '/business-events', title: 'Business Events',  icon:'pe-7s-user', class: '' },
  {path: '/account', title:'Accounts', icon:'pe-7s-user', class:''},
  {path: '/payment', title: 'Payment', icon: 'pe-7s-user', class:''},
  { path: '/reviews', title: 'Reviews',  icon:'pe-7s-user', class: '' },
  // { path: '/chat', title: 'Chat',  icon:'pe-7s-user', class: '' },
  { path: '/package', title: 'Package',  icon:'pe-7s-user', class: '' },
  { path: '/userPackage', title: 'User Package',  icon:'pe-7s-user', class: '' },
  // { path: '/offerPackage', title: 'User Offer',  icon:'pe-7s-user', class: '' },
]

export const Manager : ManagerInfo[]=[
  // { path: '/dashboard', title: 'Dashboard',  icon: 'pe-7s-graph', class: '' },
  { path: '/artists', title: 'Artist',  icon:'pe-7s-user', class: '' },
  { path: '/enquiry', title: 'Enquiry',  icon:'pe-7s-user', class: '' },
  // { path: '/event', title: 'Event',  icon:'pe-7s-user', class: '' },
  // { path: '/chat', title: 'Chat',  icon:'pe-7s-user', class: '' },
  // { path: '/package', title: 'Package',  icon:'pe-7s-user', class: '' },
  // { path: '/userPackage', title: 'User Package',  icon:'pe-7s-user', class: '' },
  // { path: '/offerPackage', title: 'User Offer',  icon:'pe-7s-user', class: '' },
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  adminMenuItems: any[];
  token: string;
  showMenu = false
  live101: boolean = false;
  admin: boolean= false;
  delhiManager: boolean = false;
  mumbaiManager: boolean =false;
  managerMenuItems: ManagerInfo[];
  live101_admin: boolean = false;

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.adminMenuItems = Admin.filter(adminMenuItem => adminMenuItem);
    this.managerMenuItems = Manager.filter(managerMenuItems => managerMenuItems)
    this.getToken();
  }

  getToken() {
    this.token = localStorage.getItem('username');
    if (this.token === 'live101'){
      this.live101 = true;
      this.admin = false;
      this.delhiManager = false;
      this.mumbaiManager = false;
    } else if(this.token === 'admin'){
      this.live101 = false;
      this.admin = true;
      this.delhiManager = false;
      this.mumbaiManager = false;
    }
    else if(this.token === 'event_manager_delhi'){
      this.live101 = false;
      this.admin = false;
      this.delhiManager = true;
      this.mumbaiManager = false;
    }
    else if(this.token === 'event_manager_mumbai'){
      this.live101 = false;
      this.admin = false;
      this.delhiManager = false;
      this.mumbaiManager = true;
    }
    else if(this.token === 'live101_admin'){
      this.live101 = false;
      this.admin = false;
      this.delhiManager = false;
      this.mumbaiManager = false;
      this.live101_admin = true;
    }
  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
