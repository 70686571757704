import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'citiesPipe' })
export class CitiesPipe implements PipeTransform {

    citiesData: any=
        {
                "del": "Delhi",
                "mum": "Mumbai",
                "mbi": "Mumbai",
                "blr": "Bangalore",
                "hyd": "Hyderabad",
                "jpr": "Jaipur",
                "pne": "Pune",
                "ngr": "Nagpur",
                "srt": "Surat",
                "asr": "Amritsar",
                "chn": "Chennai",
                "jdpr": "Jodhpur",
                "cgrh": "Chandigarh",
                "goa": "Goa",
                "nsk": "Nashik",
                "adb": "Ahmedabad",
                "indb": "Indore",
                "mert": "Meerut",
                "ludb": "Ludhiana",
                "jldh": "Jhalandar",
                "lknw": "Lucknow",
                "knpr": "Kanpur",
                "rjkt": "Rajkot",
                "udpr": "Udaipur",
                "bknr": "Bikaner",
                "ptn": "Patna",
                "ripr": "Raipur",
                "rnch": "Ranchi",
                "bvnshr": "Bhubaneswar",
                "drdn": "Dehradun",
                "shmla": "Shimla",
                "klkta": "Kolkata",
                "asansl": "Asansol",
                "guwht": "Guwahati",
                "silgri": "Siliguri",
                "vrnsi": "Varanasi",
                "jmmu": "Jammu",
                "kshmr": "Kashmir",
                "ajmr" : "Ajmer",
                "pshkr" : "Pushkar",
                "kta" : "Kota",
                "vadodara": "Vadodara",
                "jamshedpur": "Jamshedpur",
                "agra": "Agra",
                "kerala": "Kerala",
                "nainital": "Nainitaal",
                "northeast": "Northeast",
                "dehradoon": "Dehradoon",
                "tripura": "Tripura",
                "nagaland": "Nagaland",
                "arunachal": "Arunachal Pradesh",
                "shillong": "Shillong",
                "mizoram": "Mizoram",
                "manipur": "Manipur",
                "bhopal": "Bhopal",
                "cuttack": "Cuttack",
                "vadodra": "Vadodra",
                "vishakhapatnam": "Vishakhapatnam",
                "vijayawada": "Vijayawada",
                "madurai": "Madurai",
                "coimbatore": "Coimbatore",
                "haldwani": "Haldwani",
                "poducherry": "Poducherry",
                "jabalpur": "Jabalpur"
          }
    ;



    

    transform(value: any){
        if(!this.citiesData[value]){
            return "NA"
        }
        return this.citiesData[value];
        
    }
}