import { Component, OnInit, ViewChild} from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material';

interface Select {
  value: string;
  viewValue: string;
}

export interface UserData {
  accountId: string;
  name: string;
  image: string;
  travelCity: string;
  ArtistCategory: string;
  GenreCategory: string;
  EventCategory: string;
  InstrumentsCategory: string;
  Actions: string;
}

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  eventList: any =[];
  artistSearchList: any =[];
  currentPage:number = 1;
  successResult:any = [];
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  id: any;
  payment_status: any = "completed";
  event_type : any =  "upcoming";
  sort_by : any = "eventDate";
  selection: Select[] = [
    {value: 'view', viewValue: 'View'},
    {value: 'sendAlert', viewValue: 'Send Alert'},

    // {value: 'edit', viewValue: 'Edit'},
    // {value: 'delete', viewValue: 'Delete'}
  ];
  selectedValue: string;

  constructor( private commonService: CommonService, private snackBar : MatSnackBar,
               private router:Router) {}

  ngOnInit() {
 
    this.tableData1 = {
      headerRow: [ 'Event ID', 'Name', 'Contact No','Email', 'Location', 'Artist Category', 'Event Category', 'Genre Category', 'Actions'],
  };
    this.getEventList("upcoming","event");
  }

  applyFilter(event){
    console.log(event.value);
    this.nextPage =1;
    this.eventList=[];
      this.getEventList(this.event_type,this.sort_by, this.payment_status)
  }



  getEventList(filter?, sort?,payment?){
    this.currentPage =1;
    this.enableSpinner$.next(true);
    console.log(sort);
    this.commonService.getEvents(this.nextPage,filter, sort,payment).subscribe(data =>{
      this.enableSpinner$.next(false);
      this.totalPage= data.Response.count;
      this.nextPage +=1;
      console.log(this.totalPage)
      this.dataReceived= data.Response.result;

      data.Response.result.forEach(element => {
        this.eventList.push(element)
      })
    })
  }

  selectChange(event , id){
    console.log(event.value);
    console.log(id);
    this.selectedValue= event.value;
    if(this.selectedValue === 'view'){
      this.router.navigate(["/event", "event-Details"], { queryParams: { id: id }});
    } else if(this.selectedValue === 'edit'){
      this.router.navigate(["/event", "event-edit"], { queryParams: { id: id }});
    } else if(this.selectedValue === "sendAlert"){
      this.sendAlert(id);
    }
  }

  sendAlert(id){
    this.commonService.sendAlert(id).subscribe(data =>{
      console.log(data);
      this.openSnackbar('Alert Send Successfully', 'ok');
      
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');
      
    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  // viewDetails(id){
  //   console.log(id);
  //   this.router.navigate(["/event", "event-Details"], { queryParams: { id: id }});

  // }
  // editArtist(id){
  //   console.log(id);
  //   this.router.navigate(["/event", "event-edit"], { queryParams: { id: id }});
  // }

  viewMoreArtists(){
    this.getEventList(this.event_type,this.sort_by, this.payment_status)

  }

  //search
  search(event){
    this.searchResult= event.target.value;
    setTimeout (() => {
      this.currentPage = 1;
      this.searchApiCal();
   }, 1500);
        
        }

searchApiCal(){
  if(this.searchResult.length >= 3 ){
    this.eventList=[];
    this.nextPage = 1;
      const payload={
          first_name: true,
          artist: this.searchResult
      }
      this.enableSpinner$.next(true);
      this.commonService.search(payload,this.currentPage).subscribe(success =>{
        this.enableSpinner$.next(false);
      this.currentPage +=1;
      this.successResult= success.Response.result;
      success.Response.result.forEach(el => {
        this.eventList.push(el);
        console.log(this.eventList);
})
      });
  }else if(this.searchResult.length < 1){  
    this.nextPage = 1;
    this.eventList=[];
     this.getEventList();
  }
}

  viewMoreSearch(){
    const payload={
      first_name: true,
      artist: this.searchResult
  }
  this.enableSpinner$.next(true);
    this.commonService.search(payload,this.currentPage).subscribe(success =>{
      this.enableSpinner$.next(false);
      this.currentPage +=1;
      this.successResult= success.Response.result;
      success.Response.result.forEach(el => {
        this.eventList.push(el);
})
      });
  }
  
}


