import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-enquiry',
  templateUrl: './edit-enquiry.component.html',
  styleUrls: ['./edit-enquiry.component.scss']
})
export class EditEnquiryComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  enquiryBasicInfoForm: FormGroup;
  private _jsonURL = 'assets/artistData.json';
  artist_category: any;
  eventCategory: any;
  genreCategory: any;
  id: any;
  firstName: any;
  lastName: any;
  contactName: any;
  email: any;
  userId: any;
  message: any;
  budgetMin: any;
  budgetMax: any;
  fullAddress: any;
  eventDate: any;
  artistcategoryes: any;
  eventCategoryes: any;
  genreCategoryes: any;

  constructor( private http: HttpClient,
               private formBuilder: FormBuilder,
               private commonService:CommonService,
               private ativateRouter: ActivatedRoute,
               private _location: Location,
               private snackBar: MatSnackBar,
               private router: Router) { 
                  this.getJSON().subscribe(data => {
                  // console.log("json data api" + JSON.stringify(data));
                  this.artist_category = data.artist_category;
                  this.genreCategory = data.genre_category;
                  this.eventCategory = data.event_category;                  
                }); 
              }
   public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
  
  ngOnInit() {
    this.ativateRouter.queryParams.subscribe(param =>{
      this.id= param.id;
    })
  this.getEnquiryDetails();
    this.enquiryBasicInfoForm = this.formBuilder.group({
      // user_information: this.formBuilder.group({
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        contact_no: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required]),
      // }),
  
      // budget: this.formBuilder.group({
        min: new FormControl('', [Validators.required]),
        max: new FormControl('', [Validators.required]),
      // }),

      // event_loction: this.formBuilder.group({
        full_address: new FormControl('', [Validators.required]),
      // }),
      event_date:new FormControl('', [Validators.required]),
      artist_category: new FormControl('', [Validators.required]),
      genre_category: new FormControl('', [Validators.required]),
      event_category: new FormControl('', [Validators.required]),
    }) 
   
  }

  getEnquiryDetails(){
    const data={
      enquiry_id: this.id
    }
    this.enableSpinner$.next(true);
    this.commonService.enquiryDetails(data).subscribe(data =>{
      this.enableSpinner$.next(false);
      console.log(data.Response[0])
    // console.log("checking responsse",data.Response[0].user_information);
    this.firstName= data.Response[0].user_information.first_name;
    this.lastName= data.Response[0].user_information.last_name;
    this.contactName= data.Response[0].user_information.contact_no;
    this.email= data.Response[0].user_information.email;
    // this.userId= data.Response[0].user_information.user_id;
    // this.message= data.Response[0].user_information.message;
    this.budgetMin= data.Response[0].budget.min;
    this.budgetMax= data.Response[0].budget.max;
    // this.fullAddress= data.Response[0].event_location.full_address;
    this.artistcategoryes= data.Response[0].artist_category;
    this.eventDate= this.formatDate(data.Response[0].event_date);
    this.eventCategoryes= data.Response[0].event_category;
    this.genreCategoryes= data.Response[0].genre_category;
    // this.id= data.Response[0].id;
    this.enquiryBasicInfoForm.patchValue({
      // user_information: {
        first_name: this.firstName,
        last_name:this.lastName,
        contact_no:this.contactName,
        email: this.email,
        min: this.budgetMin,
        max: this.budgetMax,
      // event_loction: {
        // full_address: this.fullAddress,
      // },
      event_date: this.eventDate,
      artist_category: this.artistcategoryes,
      genre_category: this.genreCategoryes,
      event_category: this.eventCategoryes
    })
    });   
  } 

  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  saveForm(){
    console.log(this.enquiryBasicInfoForm.value)
    console.log(...this.enquiryBasicInfoForm.value)
    const PayLoad= {
      artist_category: this.enquiryBasicInfoForm.value.artist_category,
      genre_category: this.enquiryBasicInfoForm.value.genre_category,
      event_category: this.enquiryBasicInfoForm.value.event_category,

      event_date: this.enquiryBasicInfoForm.value.event_date,
      event_loction: {
        lat: "asd",
        lng: "skdj",    
        full_address: this.enquiryBasicInfoForm.value.full_address
      },
      budget: {
        min: this.enquiryBasicInfoForm.value.min,
        max: this.enquiryBasicInfoForm.value.max
      },
      id: this.id,
      user_information: {
        first_name: this.enquiryBasicInfoForm.value.first_name,
        last_name: this.enquiryBasicInfoForm.value.last_name,
        contact_no: this.enquiryBasicInfoForm.value.contact_no,
        email: this.enquiryBasicInfoForm.value.email,
        // message: "hello",
        // user_id: "aaa"
      },
      // enquiry_type: "direct",
      artists: [
        {
          // avg_rating: "asd",
          // review_count: "sadas",
          // artist_name: "sdas",
          // artist_category: "asd",
          // artist_city: "szdasd",
          // image_url: "sdas",
          // artist_id: "sdas",
          is_admin_approved: true
        }
      ]
    }
    
    let live= this.id;
    console.log(PayLoad);
    this.enableSpinner$.next(true);
    this.commonService.editEnquiry(PayLoad).subscribe(response =>{
      this.enableSpinner$.next(false);
      console.log(response);
      if(response.success === true){
        this.openSnackbar('Enquiry Save Successfully', 'ok');
        this.router.navigate(['/enquiry']);
      } else {
        this.openSnackbar('Error Occured', 'ok')
      }
    })
  }
  openSnackbar(message:string, action: string){
    this.snackBar.open(message,action,{
      duration: 2000,
    });
  }

  goBack(){
    this._location.back();
  }
}
