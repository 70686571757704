import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.scss']
})
export class AccountEditComponent implements OnInit {
  accountId: any;
  accountDetails: any;
  accountDetailsForm: FormGroup;
  fileData: File = null;
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  image_url: string | ArrayBuffer;
  pan_image_url: any;
  uploadedPanUrl: any;
  gst_image_url: any;
  uploadedGstUrl: any;

  constructor(private activateRouter: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.accountId = param.id;
    });
    this.accountDetailsForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required]),
      pan: new FormControl('', [Validators.required]),
      gst: new FormControl('', [Validators.required]),
      aadhaar: new FormControl('', [Validators.required]),
      panurl: new FormControl('', [Validators.required]),
      gsturl: new FormControl('', [Validators.required]),
    })
    this.getAccountDetails();
  }

  // Profile Image upload
  uploadPanImage(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview('pan');
  }
  uploadGstImage(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview('gst');
  }

  preview(from) {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image_url = reader.result;
    }
    let pics = []

    this.enableSpinner$.next(true);
    this.commonService.uploadImage(this.fileData).subscribe(response => {
      this.enableSpinner$.next(false);
      console.log(response);
      if (from == 'pan') {
        this.pan_image_url = response["Data"];
        // this.commonService.imgurl = this.[profile_image_url0];
        this.uploadedPanUrl = this.pan_image_url[0];
      } else {
        this.gst_image_url = response["Data"];
        // this.commonService.imgurl = this.profi_image_url[0];
        this.uploadedGstUrl = this.gst_image_url[0];
      }
    })
  }

  saveForm() {
    let inputData = {
      "pan": this.accountDetailsForm.value.pan ? this.accountDetailsForm.value.pan : "",
      "gst": this.accountDetailsForm.value.gst ? this.accountDetailsForm.value.gst : "",
      "aadhaar": this.accountDetailsForm.value.aadhaar ? this.accountDetailsForm.value.aadhaar : "",
      "gst_url": this.uploadedGstUrl,
      "pan_url": this.uploadedPanUrl,
      "name": this.accountDetailsForm.value.name ? this.accountDetailsForm.value.name : '',
      "email": this.accountDetailsForm.value.email ? this.accountDetailsForm.value.email : '',
      "mobile": this.accountDetailsForm.value.mobile ? this.accountDetailsForm.value.mobile : '',
    }
    this.commonService.updateAdminApproved(inputData, this.accountId).subscribe(data => {
      this.openSnackbar('Account Updated Successful', 'ok');

    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');
    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }
  getAccountDetails() {
    this.commonService.getAccountsDetails(this.accountId).subscribe(data => {
      this.accountDetails = JSON.parse(JSON.stringify(data.Response));
      this.accountDetailsForm.patchValue({
        name: this.accountDetails.name,
        email: this.accountDetails.email,
        mobile: this.accountDetails.mobile,
        pan: this.accountDetails.pan,
        gst: this.accountDetails.gst,
        aadhaar: this.accountDetails.aadhaar,
        panurl: this.accountDetails.pan_url,
        gsturl: this.accountDetails.gst_url
      })
    })
  }
}
