import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { chatRoutingModule } from './chat-routing.module';
import { ChatListComponent } from './chat-list/chat-list.component';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { MatDialogComponent } from './mat-dialog/mat-dialog.component';

@NgModule({
    declarations: [
    ChatListComponent,
    MatDialogComponent], 
    entryComponents: [
      MatDialogComponent
    ],
    imports: [
      CommonModule,
      chatRoutingModule,
      FormsModule, 
      MaterialModule
    ],
   
  })
  
  export class chatModule{}