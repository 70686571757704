import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { enquiryRoutingModule } from './enquiry-routing.module';
import { EnquiryListsComponent } from './enquiry-lists/enquiry-lists.component';
import { EnquiryDetailComponent } from './enquiry-detail/enquiry-detail.component';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { AddEnquiryComponent } from './add-enquiry/add-enquiry.component';
import { EditEnquiryComponent } from './edit-enquiry/edit-enquiry.component';
import { SelectArtistToEnquiryComponent } from './select-artist-to-enquiry/select-artist-to-enquiry.component';
import { AddArtistToEnquiryComponent } from './add-artist-to-enquiry/add-artist-to-enquiry.component';
import { ArtistCategoryPipe } from 'app/pipes/artist.pipe';
import { CitiesPipe } from 'app/pipes/cities.pipe';
import { EventPipe } from 'app/pipes/event.pipe';
import { Genre } from 'app/pipes/genre.pipe';
import { Instrument } from 'app/pipes/instrument.pipe';
import { Language } from 'app/pipes/language.pipe';
import { artistModule } from 'app/artists/artist.module';
import { SendQuotationComponent } from './send-quotation/send-quotation.component';

@NgModule({
    declarations: [
    EnquiryListsComponent,
    EnquiryDetailComponent,
    AddEnquiryComponent,
    EditEnquiryComponent,
    SelectArtistToEnquiryComponent,
    AddArtistToEnquiryComponent,
    SendQuotationComponent
  ], 
    entryComponents: [

    ],
    imports: [
      CommonModule,
      enquiryRoutingModule,
      FormsModule,
      MaterialModule,
      artistModule
    ],
   
  })
  
  export class enquiryModule{}