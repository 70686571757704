import {Component, OnInit, ViewChild} from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material';
interface Select {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.scss']
})
export class PackageListComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  packageList: any =[];
  artistSearchList: any =[];
  currentPage:number = 1;
  successResult:any = [];
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  selectedValue: any;

  selection: Select[] = [
    {value: 'view', viewValue: 'View'},
    {value: 'edit', viewValue: 'Edit'},
    {value: 'delete', viewValue: 'Delete'}
  ];
 
  constructor(private commonService: CommonService,
              private router:Router,private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.tableData1 = {
      headerRow: [ 'Package Id', 'Package Name', 'Price', 'Discount Price', 'Image', 'Actions'],
  };
    this.getPackageList();
  }


  getPackageList(){
    this.currentPage =1;
    this.enableSpinner$.next(true);
    this.commonService.getpackage(this.nextPage).subscribe(data =>{
      this.enableSpinner$.next(false);
      if(data.Response){
      this.totalPage= data.Response.count;
      this.nextPage +=1;
      console.log(this.totalPage)
      this.dataReceived= data.Response.result;
      if(this.dataReceived){
        data.Response.result.forEach(element => {

          if(!element.is_deleted){
            this.packageList.push(element);
          }
        })
      }
    }
    })
  }

  viewMoreArtists(){
    this.getPackageList();
  }

  //search
  search(event){
    this.searchResult= event.target.value;
    setTimeout (() => {
      this.currentPage = 1;
      this.searchApiCal();
   }, 1500);
        
        }

searchApiCal(){
  if(this.searchResult.length >= 3 ){
    this.packageList=[];
    this.nextPage = 1;
      const payload={
          first_name: true,
          artist: this.searchResult
      }
      this.enableSpinner$.next(true);
      this.commonService.search(payload,this.currentPage).subscribe(success =>{
        this.enableSpinner$.next(false);
      this.currentPage +=1;
      this.successResult= success.Response.result;
      success.Response.result.forEach(el => {
        this.packageList.push(el);
        console.log(this.packageList);
})
      });
  }else if(this.searchResult.length < 1){  
    this.nextPage = 1;
    this.packageList=[];
     this.getPackageList();
  }
}

  viewMoreSearch(){
    const payload={
      first_name: true,
      artist: this.searchResult
  }
  this.enableSpinner$.next(true);
    this.commonService.search(payload,this.currentPage).subscribe(success =>{
      this.enableSpinner$.next(false);
      this.currentPage +=1;
      this.successResult= success.Response;
      success.Response.forEach(el => {
        this.packageList.push(el);
})
      });
  }
  
  addPackage(){
    this.router.navigate(["/package","package-add"]);
  }

  selectChange(event , id){
    console.log(event.value);
    console.log(id);
    this.selectedValue= event.value;
    if(this.selectedValue === 'view'){
      this.router.navigate(["/package","package-details"], { queryParams: { package_id: id }});
    } else if(this.selectedValue === 'edit'){
      this.router.navigate(["/package","package-edit"], { queryParams: { package_id: id }});
    } else if(this.selectedValue === 'delete'){
      this.commonService.deletePackage(id, {is_deleted : true}).subscribe(response => {
        if (response.success == true) {
          this.openSnackbar('Package Deleted Successfully', 'ok')

          this.packageList = this.packageList.filter(item => item.id !== id);
          console.log(this.packageList);
          
        }
        else {
          this.openSnackbar('Error Occured', 'ok');
        }
      }, err => {
        console.log(err);
        this.openSnackbar('Error Occured', 'ok');
        
      })
    }
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }
}


