import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-artist',
  templateUrl: './edit-artist.component.html',
  styleUrls: ['./edit-artist.component.scss']
})
export class EditArtistComponent implements OnInit {

  private _jsonURL = 'assets/artistData.json';
  private artistCategory_json = 'assets/artist_categories.json'
  private _citiesJson = 'assets/travelCities.json';
  private _languagesJson = 'assets/languages.json';
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  artistProfileinfo: FormGroup;
  artistPofileDetails: any;
  artist_id: number;
  artistProfile: any;
  artistImage: any;
  firstName: any;
  lastName: any;
  accountId: any;
  intro: any;
  languages: any;
  price: any;
  social: any;
  artistCategory: string;
  songList: any;
  travelCity: any;
  kycDetails: any;
  instagram: any;
  youtube: any;
  genre_category: any;
  event_category: any;
  instrument_category: any;
  genders: any;
  career_achievements: any;
  other_details: any;
  why_hire_you: any;
  id: any;
  name: any;
  description: any;
  imageUrl: any;
  fileData: File = null;
  profile_image_url: any;
  artist_category: any;
  eventCategory: string;
  genreCategory: any;
  instrumentsCategory: any;
  image_url: any;
  uploadedUrl: any;
  image: any;
  priceOnehour: number;
  priceTwohour: any;
  priceThreehour: any;
  accountName: any;
  accountNumber: any;
  bankName: any;
  ifscCode: any;
  mirc: any;
  panNo: any;
  adhaar: any;
  gstin: any;
  descriptions: any;
  url: any;
  galleries: Array<any> = [];
  updatedGallery: Array<any> = []
  updatedVideos: Array<any> = []
  travelCities: string;
  artist_category_list: any;
  genre_category_list: any;
  languagesList: any;
  event_category_list: any;
  instrument_category_list: any;
  currentCity: any;
  artistJsonData: any = [];
  experience: number;
  gigs: number;
  isTravelCity: any;
  isPopular: any;
  isPremium: any;
  liveScore: any;
  mobile: any;
  isAdminApproved: any;
  email: any;
  isBankVerified: any;
  isPanVerified: any;
  isAdhaarVerified: any;
  satisfaction: any;
  professionalism: any;
  communication: any;
  punctuality: any;
  commissionPer: any;
  aboutImage: any = [];
  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    public fb: FormBuilder,
    private _location: Location,
    private http: HttpClient
  ) {
    this.getJSON(this.artistCategory_json).subscribe(data => {
      console.log(data);
      this.artistJsonData = data.artist_categories;
      this.artist_category_list = data.artist_categories;
    });
    this.getJSON(this._languagesJson).subscribe(data => {
      console.log(data.languages);
      this.languagesList = data.languages;
    });
    this.getJSON(this._jsonURL).subscribe(data => {
      console.log(data);
      
      // console.log("json data api" + JSON.stringify(data));
      // this.artistJsonData = data;
      // this.artist_category_list = data.artist_category;
      // this.genre_category_list = data.genre_category;
      this.event_category_list = data.event_category;
      this.instrument_category_list = data.instruments_category;
    });

    this.getCities().subscribe(data => {
      this.travelCities = data.cities;
    })
  }
  public getJSON(jsonUrl): Observable<any> {
    return this.http.get(jsonUrl);
  }

  public getCities(): Observable<any> {
    return this.http.get(this._citiesJson);
  }
  artistChange(event){
    console.log(event);
    
    console.log(this.artistJsonData.findIndex(x => x.value === event));

    let index = this.artistJsonData.findIndex(x => x.value === event);
    const genreList = this.artistJsonData[index]['genres'];
    this.genre_category_list = genreList;


    // this.genre_category_list = this.artistJsonData[index].genres;
    
    // if(event == 'singer') this.genre_category_list = this.artistJsonData.singerGenres;
    // else if(event == 'music_band') this.genre_category_list = this.artistJsonData.musicGenres;
    // else if(event == 'd_j') this.genre_category_list = this.artistJsonData.djGenres;
    // else if(event == 'musician') this.genre_category_list = this.artistJsonData.musicianGenres;
    // else if(event == 'comedian') this.genre_category_list = this.artistJsonData.comedianGenres;
    // else if(event == 'magician') this.genre_category_list = this.artistJsonData.magicianGenres;
    // else if(event == 'emcee') this.genre_category_list = this.artistJsonData.musicGenres;




  }
  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(param => {
      this.artist_id = param.artist_id;
      console.log(this.artist_id)
    });
    this.galleries = [];
    // this.galleries = [{_id:Math.random() * 10 +'abc',image_url:'https://s3.ap-south-1.amazonaws.com/com.file.upload/folder/1591703774925gallery1.png'}]

    //"https://s3.ap-south-1.amazonaws.com/com.file.upload/folder/1591703774925gallery1.png"

    this.artistProfileinfo = this.formBuilder.group({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      // // account_id:new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
      intro: new FormControl('', [Validators.required]),
      career_achievements: new FormControl('', [Validators.required]),
      other_details: new FormControl('', [Validators.required]),
      why_hire_you: new FormControl('', [Validators.required]),
      // fb: new FormControl('', [Validators.required]),
      // youtube: new FormControl('', [Validators.required]),
      // instagram: new FormControl('', [Validators.required]),
      price_one_hour: new FormControl('', [Validators.required]),
      // price_two_hour: new FormControl('', [Validators.required]),
      // price_three_hour: new FormControl('', [Validators.required]),
      // id: new FormControl('', [Validators.required]),
      // name: new FormControl('', [Validators.required]),
      // price: new FormControl('', [Validators.required]),
      // description: new FormControl('', [Validators.required]),
      account_name: new FormControl('', [Validators.required]),
      account_no: new FormControl('', [Validators.required]),
      bank_name: new FormControl('', [Validators.required]),
      ifsc_code: new FormControl('', [Validators.required]),
      micr: new FormControl('', [Validators.required]),
      pan_no: new FormControl('', [Validators.required]),
      adhaar: new FormControl('', [Validators.required]),
      gstin: new FormControl('', [Validators.required]),
      descriptions: new FormControl('', [Validators.required]),
      languages: new FormControl('', [Validators.required]),
      artist_category: new FormControl('', [Validators.required]),
      genre_category: new FormControl('', [Validators.required]),
      event_category: new FormControl('', [Validators.required]),
      instrument_category: new FormControl('', [Validators.required]),
      current_city: new FormControl('', [Validators.required]),
      travel_city: new FormControl('', [Validators.required]),
      song_list: new FormControl('', [Validators.required]),
      experience_year: new FormControl('', [Validators.required]),
      gigs: new FormControl('', [Validators.required]),
      is_travel_city: new FormControl('', [Validators.required]),
      is_popular : new FormControl('', [Validators.required]),
      is_admin_approved : new FormControl('', [Validators.required]),
      is_bank_verified : new FormControl('', [Validators.required]),
      is_pan_verified : new FormControl('', [Validators.required]),
      is_adhaar_verified : new FormControl('', [Validators.required]),
      commission_percentage: new FormControl('',[Validators.required]),

      is_premium : new FormControl('', [Validators.required]),
      live101_score: new FormControl('',[Validators.required]),
      mobile: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required]),
      satisfaction: new FormControl('',[Validators.required]),
      communication: new FormControl('',[Validators.required]),
      professionalism: new FormControl('',[Validators.required]),
      punctuality: new FormControl('',[Validators.required]),





    })
    this.getArtistDetails();
  }

  // Profile Image upload
  uploadImage(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview('profile');
  }

  preview(from) {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.image_url = reader.result;
    }
    let pics = []

    this.enableSpinner$.next(true);
    this.commonService.uploadImage(this.fileData).subscribe(response => {
      this.enableSpinner$.next(false);
      console.log(response);
      if(from == 'about'){
        pics.push(response["Data"])

        // localStorage.setItem('galleryPics', JSON.stringify(pics))
        // let newImg = [{ image: response["Data"][0] }]
        this.aboutImage.push(response["Data"][0])
        this.cd.detectChanges()
      }
      else if (from == 'gallery') {
        pics.push(response["Data"])

        localStorage.setItem('galleryPics', JSON.stringify(pics))
        let newImg = [{ _id: Math.random() * 10 + 'abc', image_url: response["Data"][0] }]
        this.galleries.push(...newImg)
        this.cd.detectChanges()
      } else {
        this.profile_image_url = response["Data"];
        this.commonService.imgurl = this.profile_image_url[0];
        this.uploadedUrl = this.profile_image_url[0];
      }

    })
  }

  getArtistDetails() {
    var id = this.artist_id;
    this.enableSpinner$.next(true);
    this.commonService.viewDetails(id).subscribe(success => {
      this.enableSpinner$.next(false);
      if(success.success){
            console.log(success);

          this.artistProfile = success.Response;
          this.accountId = success.Response.personal_info.account_id;
          this.image = success.Response.personal_info.profile_image_url;
          this.firstName = success.Response.personal_info.first_name;
          this.lastName = success.Response.personal_info.last_name;
          this.genders = success.Response.personal_info.gender;
          this.mobile = success.Response.personal_info.mobile;
          this.email = success.Response.personal_info.email;
          // this.accountId = success.Response.personal_info.account_id;
          this.intro = success.Response.about.intro;
          this.career_achievements = success.Response.about.career_achievements;
          this.other_details = success.Response.about.other_details;
          this.why_hire_you = success.Response.about.why_hire_you;
          this.languages = success.Response.languages;
          this.artistCategory = success.Response.artist_category[0]; 
          this.artistChange(success.Response.artist_category[0]);

          
          this.genreCategory = success.Response.genre_category;
          this.eventCategory = success.Response.event_category;
          this.instrumentsCategory = success.Response.instrument_category;
          console.log(this.instrumentsCategory)
          this.currentCity = success.Response.personal_info.city;
          this.travelCity = success.Response.travel_city;
          this.priceOnehour = success.Response.hourly_price.price_one_hour;
          
          this.priceTwohour = success.Response.hourly_price.price_two_hour;
          this.priceThreehour = success.Response.hourly_price.price_three_hour;
          this.accountName = success.Response.artist_payment_details.bank_details.account_name;
          this.accountNumber = success.Response.artist_payment_details.bank_details.account_no;
          this.bankName = success.Response.artist_payment_details.bank_details.bank_name;
          this.ifscCode = success.Response.artist_payment_details.bank_details.ifsc_code;
          this.mirc = success.Response.artist_payment_details.bank_details.micr;
          this.songList = success.Response.song_list;
          console.log(this.priceOnehour);

          this.panNo = success.Response.artist_payment_details.kyc_details.pan_no;
          this.adhaar = success.Response.artist_payment_details.kyc_details.adhaar;
          this.gstin = success.Response.artist_payment_details.kyc_details.gstin;
          this.descriptions = success.Response.artist_payment_details.kyc_details.description;
          this.experience = success.Response.year_of_exp;
          this.gigs = success.Response.live101_gigs;
          this.isTravelCity = success.Response.is_travel_city;
          this.isPopular = success.Response.is_popular;
          this.isAdminApproved = success.Response.is_admin_approved;
          this.isBankVerified = success.Response.artist_payment_details.bank_details.is_bank_verified;
          this.isPanVerified = success.Response.artist_payment_details.kyc_details.is_pan_verified;
          this.isAdhaarVerified = success.Response.artist_payment_details.kyc_details.is_adhaar_verified;
          this.isPremium = success.Response.is_premium;
          this.liveScore = success.Response.live101_score;
          this.satisfaction = success.Response.summary.satisfaction;
          this.professionalism = success.Response.summary.professionalism;
          this.communication = success.Response.summary.communication;
          this.punctuality = success.Response.summary.punctuality;
          this.commissionPer = success.Response.commission_percentage;

          // this.id = success.Response.addons[0].id;
          // this.name = success.Response.addons[0].name;
          // this.price = success.Response.addons[0].price;
          // this.fb = success.Response.social_media.fb;
          // this.instagram = success.Response.social_media.instagram;
          // this.youtube = success.Response.social_media.youtube;
          // this.description = success.Response.addons[0].description;
          this.aboutImage.push(...success.Response.image);
          this.galleries.push(...success.Response.gallery.images);
          console.log(this.aboutImage);
          
          this.updatedVideos.push(...success.Response.gallery.videos);
          console.log(this.updatedVideos);
          
          this.artistProfileinfo.patchValue({
            first_name: this.firstName,
            last_name: this.lastName,
            mobile : this.mobile,
            email: this.email,
            account_id: this.accountId,
            gender: this.genders,
            intro: this.intro,
            current_city: this.currentCity,
            career_achievements: this.career_achievements,
            other_details: this.other_details,
            why_hire_you: this.why_hire_you,
            fb: this.fb,
            youtube: this.youtube,
            instagram: this.instagram,
            price_one_hour: this.priceOnehour,
            commission_percentage: this.commissionPer,
            // price_two_hour: this.priceTwohour,
            // price_three_hour: this.priceThreehour,
            // id: this.id,
            // name: this.name,
            // price: this.price,
            // description: this.description,
            languages: this.languages,
            artist_category: this.artistCategory,
            genre_category: this.genreCategory,
            event_category: this.eventCategory,
            instrument_category: this.instrumentsCategory,
            travel_city: this.travelCity,
            account_name: this.accountName,
            account_no: this.accountNumber,
            bank_name: this.bankName,
            ifsc_code: this.ifscCode,
            micr: this.mirc,
            pan_no: this.panNo,
            adhaar: this.adhaar,
            gstin: this.gstin,
            descriptions: this.descriptions,
            experience_year: this.experience,
            gigs : this.gigs,
            live101_score : this.liveScore,
            is_travel_city : this.isTravelCity,
            is_popular: this.isPopular,
            is_admin_approved: this.isAdminApproved,
            is_bank_verified: this.isBankVerified,
            is_pan_verified: this.isPanVerified,
            is_adhaar_verified: this.isAdhaarVerified,
            satisfaction : this.satisfaction,
            punctuality: this.punctuality,
            communication: this.communication,
            professionalism: this.professionalism,
            
            is_premium: this.isPremium
          })
      }
      
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');
      
    })
  }

  removeGallery(id) {
    this.galleries = this.galleries.filter((img) => {
      return img._id !== id
    })
    this.cd.detectChanges()
    console.log(this.galleries)
  }
  removeAbout(id) {
    this.aboutImage = this.aboutImage.filter((img) => {
      return img !== id
    })
    this.cd.detectChanges()
    console.log(this.aboutImage)
  }


  removeVideo(id){
    console.log(this.updatedVideos);
    
    this.updatedVideos = this.updatedVideos.filter((video) => {
      return video._id !== id
    })
    this.cd.detectChanges();
    console.log(this.updatedVideos);
    
  }

  uploadGalleryImage(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log(this.fileData)
    this.preview('gallery');
  }
  uploadAboutImage(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log(this.fileData)
    this.preview('about');
  }

  makeImgFormat() {
    this.updatedGallery = []
    console.log(this.galleries);
    
    this.galleries.map((img) => {
      this.updatedGallery.push({ image_url: img.image_url})
    })
    console.log(this.updatedGallery)
  }

  OnInput(value) {
    // this.serverName = value;
    console.log(value);
    this.updatedVideos.push({youtube_id: value});
    console.log(this.updatedVideos);
    
    
   }

  saveForm() {
    //get all gallery images before saving
    this.makeImgFormat()
    var id = this.artist_id;
    if (this.profile_image_url === undefined) {
      console.log("if");
      this.url = this.image;
    } else {
      console.log("else");
      this.url = this.profile_image_url[0];
    }

    console.log(this.artistProfileinfo.value)
    const payLoad = {
      // artist_id: { type: String, default: 'live' + uniqid(), unique: true },
      // is_deleted: { type: Boolean, default: true },
      // event_count: { type: Number, default: 0 },
      personal_info: {
        gender: this.artistProfileinfo.value.gender,
        // account_id: { type: String },
        account_id: this.accountId,
        profile_image_url: this.url,
        first_name: this.artistProfileinfo.value.first_name,
        last_name: this.artistProfileinfo.value.last_name,
        mobile: this.artistProfileinfo.value.mobile,
        email: this.artistProfileinfo.value.email,
        city: this.artistProfileinfo.value.current_city
      },
      // is_top_pick: { type: Boolean },
      // top_pick_gallery: {
      //   card_1: { type: String },
      //   card_2: { type: String },
      //   card_3: { type: String }
      // },
      // plan: {
      //   id: { type: String },
      //   expiry_date: { type: Date },
      // },
      // razorpay_account_id: { type: String },
      // agency_account_id: { type: String },
      travel_city: this.artistProfileinfo.value.travel_city,
      live101_gigs: this.artistProfileinfo.value.gigs,
      year_of_exp: this.artistProfileinfo.value.experience_year,
      is_travel_city: this.artistProfileinfo.value.is_travel_city,
      is_popular: this.artistProfileinfo.value.is_popular,
      is_admin_approved: this.artistProfileinfo.value.is_admin_approved,
      is_premium: this.artistProfileinfo.value.is_premium,
      live101_score: this.artistProfileinfo.value.live101_score,
      hourly_price: {
        price_one_hour: this.artistProfileinfo.value.price_one_hour,
        price_two_hour: this.artistProfileinfo.value.price_two_hour,
        price_three_hour: this.artistProfileinfo.value.price_three_hour,
      },
      about: {
        intro: this.artistProfileinfo.value.intro,
        career_achievements: this.artistProfileinfo.value.career_achievements,
        other_details: this.artistProfileinfo.value.other_details,
        why_hire_you: this.artistProfileinfo.value.why_hire_you,
      },
      // image: { type: [String] },
      artist_category: this.artistProfileinfo.value.artist_category,
      // artist_subcategory: { type: [String], required: [true, 'No  subcategory selected'] },
      genre_category: this.artistProfileinfo.value.genre_category,
      event_category: this.artistProfileinfo.value.event_category,
      languages: this.artistProfileinfo.value.languages,
      instrument_category: this.artistProfileinfo.value.instrument_category,
      commission_percentage: this.artistProfileinfo.value.commission_percentage,

      // social_media: {
      //   // twitter: { type: String },
      //   fb: this.artistProfileinfo.value.fb,
      //   instagram: this.artistProfileinfo.value.instagram,
      //   youtube: this.artistProfileinfo.value.youtube,
      // },
      // addons: [{
      //   name: this.artistProfileinfo.value.name,
      //   id: this.artistProfileinfo.value.id,
      //   price: this.artistProfileinfo.value.price,
      //   description: this.artistProfileinfo.value.description,
      // }],
      // status: { type: String },
      image: this.aboutImage,
      gallery:
      {
        videos: this.updatedVideos,
        //   [{
        //     id: { type: String },
        //     title: { type: String },
        //     subtitle: { type: String },
        //     youtube_id: { type: String },
        //     genre: { type: String },
        //   }],

        images: this.updatedGallery
        // [{
        //   image_url: { type: String },
        //   id: { type: String },
        // }],
      },
      // onboarding_questions_answers: [{ question_slug: { type: String }, choice_id: { type: Boolean } }],
      artist_payment_details: {
        bank_details: {
          account_name: this.artistProfileinfo.value.account_name,
          account_no: this.artistProfileinfo.value.account_no,
          bank_name: this.artistProfileinfo.value.bank_name,
          branch_name: this.artistProfileinfo.value.branch_name,
          ifsc_code: this.artistProfileinfo.value.ifsc_code,
          micr: this.artistProfileinfo.value.micr,
          is_bank_verified: this.artistProfileinfo.value.is_bank_verified
        },
        kyc_details: {
          pan_no: this.artistProfileinfo.value.pan_no,
          adhaar: this.artistProfileinfo.value.adhaar,
          gstin: this.artistProfileinfo.value.gstin,
          description: this.artistProfileinfo.value.description,
          is_adhaar_verified: this.artistProfileinfo.value.is_adhaar_verified,
          is_pan_verified: this.artistProfileinfo.value.is_pan_verified
        }
      },
      // code: { type: String },
      // artist_level: { type: String, enum: ['gold', 'silver'] },
      // artist_management_fee_rate: { type: String },
      // is_active: { type: Boolean, default: true },
      // is_popular: { type: Boolean },
      // sorting_order: { type: String },
      // location: { type: String },
      // rating_average: { type: String },
      // is_admin_approved: { type: Boolean, default: true }, 
      // review_count: { type: Number },
      summary:
      {
      satisfaction: this.artistProfileinfo.value.satisfaction,
      professionalism: this.artistProfileinfo.value.professionalism,
      communication: this.artistProfileinfo.value.communication,
      punctuality: this.artistProfileinfo.value.punctuality,
      },
      // song_list: [{ id: { type: String }, song_name: { type: String }, film_name: { type: String }, image_url: { type: String } }],
    }

    console.log(payLoad)
    this.commonService.saveArtistInfo(id, payLoad).subscribe(response => {
      if (response.success == true) {
        this.openSnackbar('Artist Details Saved Successfully', 'ok')
      }
      else {
        this.openSnackbar('Error Occured', 'ok');
      }
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');
      
    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  goBack() {
    this._location.back();
  }


}
