import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-offer-package-details',
  templateUrl: './offer-package-details.component.html',
  styleUrls: ['./offer-package-details.component.scss']
})
export class OfferPackageDetailsComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  id: any;
  userId: any;
  userName: any;
  contact: any;
  email: any;
  offerId: any;
  userOfferId: any;
  recommendation: any;
  currency: any;
  price: any;
  totalAmount: any;
  addOnArray: any = [];
  transactionDetails: any;
  transactionId: any;
  transactionAmount: any;
  transactionCurrency: any;
  userOffer: any;

  constructor(private activateRouter: ActivatedRoute,
    private commonService: CommonService,
    private _location: Location,
    private router: Router) { }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.id = param.id;
      console.log(this.id)
    })
    this.getOfferDetails();
  }

  getOfferDetails() {
    const payLoad = {
      user_offer_id: this.id,
    }
    this.enableSpinner$.next(true);
    this.commonService.getOfferpackageDetails(payLoad).subscribe(data => {
      this.enableSpinner$.next(false);
      if (data.Response) {
        this.userOffer = data.Response.user_offer;

        console.log(data)
        // this.userId= data.Response.user_offer.user_id;
        // this.userName= data.Response.user_offer.user_name;
        // this.contact= data.Response.user_offer.contact;
        // this.email= data.Response.user_offer.email;
        // this.offerId= data.Response.user_offer.offer_id;
        // this.price= data.Response.user_offer.price;
        // this.recommendation= data.Response.user_offer.recommendation;
        // this.totalAmount= data.Response.user_offer.total_amount;
        // this.currency= data.Response.user_offer.currency;
        this.userOfferId = data.Response.user_offer.user_offer_id;
        // this.addOnArray= data.Response.user_offer.addons_array;
        this.transactionId = data.Response.user_offer.transaction_details.transaction_id;
        console.log(this.transactionId);
        this.transactionAmount = data.Response.user_offer.transaction_details.amount;
        this.transactionCurrency = data.Response.user_offer.transaction_details.currency;

        data.Response.user_offer.addons_array.forEach(el => {
          this.addOnArray.push(el);
          console.log(this.addOnArray);
        })
      }
    })
  }

  editOffrePackage(id) {
    console.log(id);
    this.router.navigate(["/offerPackage", "offerPackage-edit"], { queryParams: { id: id } });
  }

  goBack() {
    this._location.back();
  }

}
