import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-package-details',
  templateUrl: './user-package-details.component.html',
  styleUrls: ['./user-package-details.component.scss']
})
export class UserPackageDetailsComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  id: any;
  userPackage: any;
  package: any;
  imagePotrait: any;
  imageLandScape: any;
  itemsProvided: any;

  constructor(private activateRoute: ActivatedRoute,
              private commonService: CommonService,
              private _location: Location,
              private router: Router) { }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(param =>{
      this.id= param.id;
      console.log( this.id)
    })
    this.getUserpackageDetails();
  }

  getUserpackageDetails(){
    const payLoad={
      user_package_id: this.id,
    }
    this.enableSpinner$.next(true);
    this.commonService.userPackageDetails(payLoad).subscribe(data =>{
      this.enableSpinner$.next(false);
      if(data.Response){
        this.userPackage= data.Response.user_package;
        this.package= data.Response.package;
        this.imagePotrait= data.Response.package.potrait_image_url;
        this.imageLandScape= data.Response.package.landscape_image_url;
        this.itemsProvided= data.Response.package.items_provided;
      }
    })
  }

  goBack(){
    this._location.back();
  }

  editUserPackage(id){
    this.router.navigate(["/userPackage", "user-package-edit"], { queryParams: { id: id }});
  }
}
