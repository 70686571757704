
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

interface Select {
  value: string;
  viewValue: string;
}

export interface UserData {
  accountId: string;
  name: string;
  image: string;
  travelCity: string;
  ArtistCategory: string;
  GenreCategory: string;
  EventCategory: string;
  InstrumentsCategory: string;
  Actions: string;

}
@Component({
  selector: 'app-artist-sourcing',
  templateUrl: './artist-sourcing.component.html',
  styleUrls: ['./artist-sourcing.component.scss']
})
export class ArtistSourcingComponent implements OnInit {
  // p: number = 1;
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  artistList: any = [];
  artistSearchList: any = [];
  currentPage: number = 1;
  successResult: any = [];
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  loaded = false;
  payload: any;
  private _jsonURL = 'assets/artistData.json';
  private citiUrl = 'assets/travelCities.json';
  private artistCategory_json = 'assets/artist_categories.json';
  filter_list = [
    { value: 'artist_name', label: 'Artist Name' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'email', label: 'Email' },
    { value: 'live101_id', label: 'Live101 Id' },
    { value: 'city', label: 'City' },
    { value: 'artist_category', label: 'Artist Category' },
    { value: 'event_category', label: 'Event Category' },
    { value: 'gender', label: 'Gender' },
    { value: 'budget', label: 'Budget' },
    { value: 'is_admin_approved', label: 'Admin Approved' }
  ];

  selection: Select[];
  selectedValue: string;
  filterValue: any;
  showSearch: boolean = false;
  artistJsonData: any;
  artist_category_list: any;
  event_category_list: any;
  instrument_category_list: any;
  filterCategory: any;
  eventFilter: boolean = false;
  artistFilter: boolean = false;
  BudgetFilter: boolean = false;
  min_budget: any;
  max_budget: any;
  editScore: boolean = false;
  enableEditIndex = null;
  live101_score: any;
  categorySelected: any = null;
  genreSelected: any = null;
  eventSelected: any = null;
  genderSelected: any = null;
  locationSelected: any = null;
  rangeSelected: any = null;

  updated_live101_score: any;
  live101_score_regex = /^[1-9]$|^[1-9][0-9]$|^(100)$/;
  budgetList = [
    { id: "0", value: "3000-5000", label: "₹3,000 - ₹5,000" },
    { id: "1", value: "5000-10000", label: "₹5,000 - ₹10,000" },
    { id: "2", value: "10000-25000", label: "₹10,000 - ₹25,000" },
    { id: "3", value: "25000-50000", label: "₹25,000 - ₹50,000" },
    { id: "4", value: "50000-100000", label: "₹50,000 - ₹1,00,000" },
    { id: "5", value: "100000-250000", label: "₹1,00,000 - ₹2,50,000" },
    { id: "6", value: "250000-500000", label: "₹2,50,000 - ₹5,00,000" },
    { id: "7", value: "500000-1000000", label: "₹5,00,000 - ₹10,00,000" },
    { id: "8", value: "1000000-1000000000", label: "₹10,00,000 & above" }
  ];
  genderList = [{ value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' }];
  citiesList: any;
  genre_category_list: any;
  onlySearch: boolean;

  constructor(private commonService: CommonService, private router: Router, private snackBar: MatSnackBar, private http: HttpClient) {
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.tableData1 = {
      headerRow: ['Artist ID', 'Name', 'Images', 'City', 'Artist Category', 'Genre Category', 'Mobile No.', 'Email', 'Live101 Score (Max 100)', 'Actions', 'Website Link'],
    };
    this.getArtistList();
    console.log(localStorage.getItem('username'));
    if (localStorage.getItem('username') == 'admin') {
      this.selection = [
        { value: 'view', viewValue: 'View' },
        { value: 'edit', viewValue: 'Edit' },
        { value: 'delete', viewValue: 'Delete' }
      ];
    } else if (localStorage.getItem('username') == 'live101_admin') {
      this.onlySearch = true;
      this.selection = [
        { value: 'view', viewValue: 'View' }
      ];
    } else {
      this.selection = [
        { value: 'view', viewValue: 'View' },
        { value: 'edit', viewValue: 'Edit' }
      ];
    }

    this.getJSON(this.artistCategory_json).subscribe(data => {
      console.log(data);
      this.artistJsonData = data.artist_categories;
      this.artist_category_list = data.artist_categories;
    })
    this.getJSON(this._jsonURL).subscribe(data => {
      console.log(data);
      this.event_category_list = data.event_category;
    });

    this.getJSON(this.citiUrl).subscribe(data => {
      console.log(data);
      this.citiesList = data.cities;
    })
  }


  artistChange(event) {
    console.log(event);

    console.log(this.artistJsonData.findIndex(x => x.value === event));

    let index = this.artistJsonData.findIndex(x => x.value === event);
    const genreList = this.artistJsonData[index]['genres'];
    this.genre_category_list = genreList;



  }

  public getJSON(jsonUrl): Observable<any> {
    return this.http.get(jsonUrl);
  }

  scoreChange(e, i) {
    this.editScore = true;
    this.enableEditIndex = i;
  }

  //Update Live101 Score
  editLiveSCore(event, id, index) {
    const payload = { live101_score: this.updated_live101_score };
    this.commonService.saveArtistInfo(id, payload).subscribe(response => {
      if (response.success == true) {
        this.artistList[index] = response.Response;
        this.openSnackbar('Artist Details updated Successfully', 'ok');
        this.editScore = false;
        this.enableEditIndex = null;
      }
      else {
        this.openSnackbar('Error Occured', 'ok');
      }
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');
    })
  }

  getArtistList(is_admin_approved?) {
    this.currentPage = 1;
    this.enableSpinner$.next(true);
    this.commonService.getArtist(this.nextPage, is_admin_approved).subscribe(data => {
      this.enableSpinner$.next(false);
      this.totalPage = data.Response.count;
      this.nextPage += 1;
      console.log(this.totalPage)
      this.dataReceived = data.Response.result;

      data.Response.result.forEach(element => {
        console.log(element);

        if (!element.is_deleted) {
          this.artistList.push(element)
          console.log(this.artistList);
        }

      })
      this.loaded = true;
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');

    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  selectChange(event, id) {
    console.log(event.value);
    console.log(id);
    this.selectedValue = event.value;
    if (this.selectedValue === 'view') {
      this.router.navigate(["/artists", "viewDetail"], { queryParams: { artist_id: id } });
    } else if (this.selectedValue === 'edit') {
      this.router.navigate(["/artists", "editArtist"], { queryParams: { artist_id: id } });
    } else if (this.selectedValue === 'delete') {
      this.commonService.saveArtistInfo(id, { is_deleted: true }).subscribe(response => {
        if (response.success == true) {
          this.openSnackbar('Artist Profile Deleted Successfully', 'ok')

          this.artistList = this.artistList.filter(item => item.artist_id !== id);

          // const artistId = this.artistList.findIndex(artistList =>  artistList.artist_id === id);
          // this.artistList.slice(artistId);
          console.log(this.artistList);

        }
        else {
          this.openSnackbar('Error Occured', 'ok');
        }
      }, err => {
        console.log(err);
        this.openSnackbar('Error Occured', 'ok');

      })
    }
  }

  viewDetails(artist_id) {
    console.log(artist_id);
    this.router.navigate(["/artists", "viewDetail"], { queryParams: { artist_id: artist_id } });

  }
  editArtist(artist_id) {
    console.log(artist_id);
    this.router.navigate(["/artists", "editArtist"], { queryParams: { artist_id: artist_id } });
  }

  viewMoreArtists() {
    this.getArtistList();
  }

  //search
  search(event) {
    this.searchResult = event.target.value;
    setTimeout(() => {
      this.currentPage = 1;
      this.searchApiCal();
    }, 500);
  }

  searchApiCal() {
    this.artistList = [];
    console.log(this.searchResult)
    this.nextPage = 1;

    if (this.searchResult) {
      this.payload = {
        first_name: true,
        artist: this.searchResult
      }
      this.commonService.search(this.payload, this.currentPage).subscribe(success => {
        if (success.Response) {
          this.currentPage += 1;
          this.successResult = success.Response.result;
          console.log(this.successResult)
          success.Response.result.forEach(el => {
            if (!el.is_deleted) {
              this.artistList.push(el);
              console.log(this.artistList);
            }

          })
        } else {
          console.log("error")
        }

      });
    } else {
      this.payload = { 
        "event_category": this.eventSelected, 
        "genre_category": this.genreSelected, 
        "travel_city": this.locationSelected, 
        "gender": this.genderSelected, 
        "languages": null, 
        "budget": this.rangeSelected, 
        "is_popular": null,
        "artist_category": this.categorySelected 
      }
      this.commonService.searchMultiple(this.payload, this.currentPage).subscribe(success => {
        if (success.Response) {
          this.currentPage += 1;
          this.successResult = success.Response.result;
          console.log(this.successResult)
          success.Response.result.forEach(el => {
            if (!el.is_deleted) {
              this.artistList.push(el);
              console.log(this.artistList);
            }

          })
        } else {
          console.log("error")
        }

      });
    }

    // if (this.searchResult.length < 1) {
    //   this.nextPage = 1;
    //   this.artistList = [];
    //   this.getArtistList();
    // }
  }

  onFilterCatSelect(event) {
    this.searchResult = event.value;
  }

  applyFilter() {
    this.currentPage = 1;

    this.searchApiCal();

  }

  onBudgetSelect(event) {
    console.log(event);
    this.min_budget = event.value.min_budget;
    this.max_budget = event.value.max_budget;
    console.log(this.min_budget, this.max_budget);
  }

  onFilterSelect(event) {
    console.log(event);
    this.filterValue = event.value;
    if (this.filterValue === "artist_name" || this.filterValue === "mobile" || this.filterValue === "live101_id" || this.filterValue === "email") {
      this.showSearch = true;
      this.eventFilter = false;
      this.artistFilter = false;
    }
    else if (this.filterValue === "artist_category") {
      this.showSearch = false;
      this.eventFilter = false;
      this.BudgetFilter = false;
      this.artistFilter = true;
      this.filterCategory = this.artist_category_list;
    } else if (this.filterValue === "event_category") {
      this.showSearch = false;
      this.BudgetFilter = false;
      this.eventFilter = true;
      this.artistFilter = false;
      this.filterCategory = this.event_category_list;
    } else if (this.filterValue === "city") {
      this.showSearch = false;
      this.eventFilter = false;
      this.BudgetFilter = false;

      this.artistFilter = true;
      this.getJSON(this.citiUrl).subscribe(data => {
        console.log(data);
        this.filterCategory = data.cities;
      })
    } else if (this.filterValue === "gender") {
      this.showSearch = false;
      this.eventFilter = false;
      this.artistFilter = true;
      this.BudgetFilter = false;
      this.filterCategory = [{ value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },];
    }
    else if (this.filterValue === "budget") {
      this.showSearch = false;
      this.eventFilter = false;
      this.artistFilter = false;
      this.BudgetFilter = true;
      this.filterCategory = [
        { id: "0", value: "3000-5000", label: "₹3,000 - ₹5,000" },
        { id: "1", value: "5000-10000", label: "₹5,000 - ₹10,000" },
        { id: "2", value: "10000-25000", label: "₹10,000 - ₹25,000" },
        { id: "3", value: "25000-50000", label: "₹25,000 - ₹50,000" },
        { id: "4", value: "50000-100000", label: "₹50,000 - ₹1,00,000" },
        { id: "5", value: "100000-250000", label: "₹1,00,000 - ₹2,50,000" },
        { id: "6", value: "250000-500000", label: "₹2,50,000 - ₹5,00,000" },
        { id: "7", value: "500000-1000000", label: "₹5,00,000 - ₹10,00,000" },
        { id: "8", value: "1000000-1000000000", label: "₹10,00,000 & above" },
      ];
      this.budgetList = this.filterCategory;
    } else if (this.filterValue == 'is_admin_approved') {
      this.nextPage = 0;
      this.artistList = [];
      this.getArtistList(true);
    }

  }

  viewNewArtists() {
    this.router.navigate(["/new-artists-registered"]);
  }

  viewMoreSearch() {
    // this.searchApiCal();
    if (this.searchResult) {
      this.commonService.search(this.payload, this.currentPage).subscribe(success => {
        if (success.Response) {
          this.currentPage += 1;
          this.successResult = success.Response.result;
          console.log(this.successResult)
          success.Response.result.forEach(el => {
            if (!el.is_deleted) {
              this.artistList.push(el);
              console.log(this.artistList);
            }
          })
        } else {
          console.log("error")
        }
      });
    } else {
      this.commonService.searchMultiple(this.payload, this.currentPage).subscribe(success => {
        this.currentPage += 1;
        this.successResult = success.Response;
        success.Response.result.forEach(el => {
          this.artistList.push(el);
        })
      });
    }

  }
  addArtist() {
    this.router.navigate(["/artists", "addArtist"]);
  }
}


