import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { YouTubePlayerModule } from '@angular/youtube-player';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ArtistCategoryPipe } from 'app/pipes/artist.pipe';
import { CitiesPipe } from 'app/pipes/cities.pipe';
import { EventPipe } from 'app/pipes/event.pipe';
import { Genre } from 'app/pipes/genre.pipe';
import { Instrument } from 'app/pipes/instrument.pipe';
import { Language } from 'app/pipes/language.pipe';
import { ImageModalComponent } from 'app/image-modal/image-modal.component';
import { YoutubeModalComponent } from 'app/youtube-modal/youtube-modal.component';
import { SafePipe } from 'app/pipes/SafePipe.pipe';
import { AccountListComponent } from './account-list/account-list.component';
import { AccountRoutingModule } from './account-routing.module';
import { artistModule } from 'app/artists/artist.module';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { AccountDetailsComponent } from './account-details/account-details/account-details.component';
import { AccountEditComponent } from './account-edit/account-edit/account-edit.component';

@NgModule({
    declarations: [
      AccountListComponent,
      AccountDetailsComponent,
      AccountEditComponent],
    entryComponents: [

      ImageModalComponent, YoutubeModalComponent
    ],
    imports: [
      CommonModule,AccountRoutingModule,artistModule,
      MaterialModule,
      FormsModule,YouTubePlayerModule, IvyCarouselModule
    ],
    exports:[CitiesPipe,
      ArtistCategoryPipe,
      Genre,
      Instrument,
      Language,
      EventPipe]
   
  })
  
  export class AccountModule{}