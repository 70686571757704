import { Injectable } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  imgurl: any;
  formData: any;
  searchArtist: any;
  pageLimit: number = 15;
  subject$ = new Subject<any>();

  //  subject$ = new Subject<any>();

  constructor(private http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  baseUrl = `https://core-api.live101.in`;
  getToken(): string {
    return localStorage.getItem('username');
  }

  uploadImage(fileData): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileData);
    return this.http.post(`${this.baseUrl}/core-services/v1/api/userprofile/uploadimage`, formData)
  }

  getUrl(id) {
    const url = 'https://www.youtube.com/embed/' + id;
    return url;
  }

  getArtist(nextPage, is_admin_approved?): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/artistprofile/admin/getall`;
    if (is_admin_approved) {
      return this.http.get(url + '?limit=' + this.pageLimit + '&page=' + nextPage + '&is_admin_approved=' + is_admin_approved);
    } else {
      return this.http.get(url + '?limit=' + this.pageLimit + '&page=' + nextPage);

    }
  }

  getAccounts(nextPage, inputData): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/account/getall`;
    return this.http.get(url + '?limit=' + this.pageLimit + '&page=' + nextPage, inputData);
  }
  
  updateAdminApproved(inputData, accountId): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/account/update/${accountId}`;
    return this.http.put(url, inputData);
  }
  
  getAccountsDetails(accountId): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/account/get/${accountId}`
    return this.http.get(url)
  }

  getAccountBySearch(queryParams): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/account/getall/?limit=15${queryParams}`;
    return this.http.get(url);
  }

  getNewArtist(nextPage, startDate?, endDate?): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/artistprofile/admin/getall`;
    return this.http.get(url + '?limit=' + this.pageLimit + '&page=' + nextPage + '&start_date=' + startDate + '&end_date=' + endDate);
  }

  viewDetails(data): Observable<any> {
    return this.http.get(`${this.baseUrl}/core-services/v1/api/artistprofile/profile/` + data);
  }
  getReview(data, limit?, page?): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/artistreview/get/admin/artist/`
    return this.http.get(url + data + '?limit=' + limit + '&page=' + page);
  }
  getAllReviews(limit, page): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/artistreview/getadminrecentreview`
    return this.http.get(url + '?limit=' + limit + '&page=' + page);
  }

  updateReview(id, postData: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/artistreview/update/` + id, postData);
  }

  saveArtistInfo(data, postData: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/artistprofile/update/` + data, postData);
  }

  deletePackage(data, postData: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/package/update/` + data, postData);
  }

  search(key, nextPage): Observable<any> {
    let searchUrl = `${this.baseUrl}/search-services/v1/api/artistprofile/admin/multipleprofile`
    return this.http.post(searchUrl + '?limit=' + this.pageLimit + '&page=' + nextPage, key)
  }
  searchMultiple(key, nextPage): Observable<any> {
    let searchUrl = `${this.baseUrl}/search-services/v1/api/artistprofile/filtermultipleprofile`
    return this.http.post(searchUrl + '?limit=' + this.pageLimit + '&page=' + nextPage, key)
  }

  setSearchArtist(data) {
    this.searchArtist = data;
    console.log(this.searchArtist)

  }
  getSearchArtist() {
    return this.searchArtist;
  }
  addNewArtistInfo(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/artistprofile/register`, payLoad);
  }

  enquiryList(nextPage): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/enquiry/admin/getall`;
    return this.http.get(url + '?limit=' + this.pageLimit + '&page=' + nextPage);
  }
  
  sendQuotation(payload): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/artistenquiry/register`, payload);
  }
  businessEnquiryList(nextPage): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/enquiry/admin/getall`;
    return this.http.get(url + '?limit=' + this.pageLimit + '&page=' + nextPage + '&account_type=BUSINESS');
  }

  enquiryDetails(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/enquiry/getviaid`, data);
  }

  updateEnquiry(payLoad): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/artistenquiry/update`, payLoad)
  }

  editEnquiry(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/enquiry/register`, payLoad)
  }

  addEnquiry(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/artistenquiry/register`, payLoad)
  }

  deleteEnquiry(data, payload): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/enquiry/admin/update/` + data, payload);
  }

  //event
  getEvents(nextPage, filter?, sort?, payment?): Observable<any> {
    let payload = {
    }
    let url = `${this.baseUrl}/core-services/v1/api/event/admin/getall`;
    return this.http.post(url + '?filter=' + filter + '&sort=' + sort + '&limit=' + this.pageLimit + '&page=' + nextPage + '&payment_status=' + payment, payload);
  }
  getBusinessEvents(nextPage, filter?, sort?, payload?): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/event/admin/getall`;
    return this.http.post(url + '?sort=' + sort + '&limit=' + this.pageLimit + '&page=' + nextPage + '&account_type=BUSINESS', {});
  }
  sendAlert(id): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/event/eventsignedurl/`;
    return this.http.get(url + id);
  }
  sendAlertEnquiry(id): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/enquiry/enquirysignedurl/`;
    return this.http.get(url + id);
  }
  eventDetails(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/core-services/v1/api/event/get/` + id, {});
  }

  eventReport(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/core-services/v1/api/eventreport/getall?event_id=` + id, {});
  }
  editEvent(id, payLoad): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/event/update/` + id, payLoad)
  }

  //Chat 
  getChatList(): Observable<any> {
    return this.http.get(`${this.baseUrl}/core-services/v1/api/conversation/get/unapprove`);
  }
  update(id, payLoad): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/conversation/message/update/` + id, payLoad)
  }

  //package
  getpackage(nextPage): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/package/admin/getall`;
    return this.http.post(url + '?limit=' + this.pageLimit + '&page=' + nextPage, {});
  }
  packageDetails(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/core-services/v1/api/package/get/` + id, {});
  }
  addPackage(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/package/register`, payLoad)
  }
  getDashboardData(payload): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/artistprofile/admin/dashboard`, payload);
  }
  editPackage(id, payLoad): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/package/update/` + id, payLoad)
  }

  sendComment(id, payLoad): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/eventreport/update/` + id, payLoad)
  }

  pushArtistToEvent(id, payload): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/event/push/` + id, payload);
  }

  //offer Package
  getAllList(payLoad, nextPage): Observable<any> {
    let url = `${this.baseUrl}/core-services/v1/api/userpackage/admin/getall`
    return this.http.post(url + '?limit=' + this.pageLimit + '&page=' + nextPage, payLoad)
  }

  getOfferpackage(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/core-services/v1/api/userpackage/get/` + id, {});
  }
  getOfferpackageDetails(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/userpackage/aggregated`, payLoad);
  }
  editOfferPackage(id, payLoad): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/userpackage/update/` + id, payLoad);
  }
  addOfferPackage(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/useroffer/register`, payLoad);
  }

  //add user package
  addUserPackage(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/userpackage/register`, payLoad);
  }
  userPackageDetails(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/userpackage/aggregated`, payLoad);
  }
  editUserPackage(id, payLoad): Observable<any> {
    return this.http.put(`${this.baseUrl}/core-services/v1/api/userpackage/update/` + id, payLoad);
  }



  //get userid for addpackage
  getUserId(payLoad): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/userprofile/check`, payLoad)
  }

  userGetpackages(): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/package/admin/getall`, {});
  }
  offerGetpackages(): Observable<any> {
    return this.http.post(`${this.baseUrl}/core-services/v1/api/offer/admin/getall`, {});
  }
}