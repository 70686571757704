import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export interface packageDetails {
  performance_time: string,
}
@Component({
  selector: 'app-package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  liveId: any;
  potraitImage: any;
  landscapeImage: any;
  packageName: any;
  packageId: any;
  price: any;
  description: any;
  packageDetail : packageDetails;
  performanceTime: any;
  itemsProvided: any = [];
  discountPrice: string;
  duration: string;
  addons: any = [];
 
  constructor(private activateRouter: ActivatedRoute,
              private commonService: CommonService,
              private router: Router,
              private _location: Location){ }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.liveId= param.package_id;
    });
    console.log(this.liveId);
    this.getPackageDetails();
  }

getPackageDetails(){
  this.enableSpinner$.next(true);
  this.commonService.packageDetails(this.liveId).subscribe(data =>{
    this.enableSpinner$.next(false);
          console.log(data.Response);
          this.packageDetail = data.Response;
          if(data.Response){
                // this.packageDetail = data.Response;
                this.potraitImage= data.Response.potrait_image_url;
                this.landscapeImage= data.Response.landscape_image_url;
                this.packageName=  data.Response.package_name;
                this.packageId= data.Response.id;
                this.price= data.Response.price;
                this.description= data.Response.description;
                this.performanceTime = data.Response.performance_time;
                this.discountPrice = data.Response.discount_price;
                this.duration = data.Response.duration;
                // this.itemsProvided= this.packageDetail.items_provided;
                data.Response.add_ons.forEach(element => {
                  this.addons.push(element)
                  console.log(this.addons);
                });
                data.Response.items_provided.forEach(element => {
                  this.itemsProvided.push(element)
                  console.log(this.itemsProvided);
                })
          }
  });
} 
editPackage(id){
  this.router.navigate(["/package","package-edit"],{ queryParams: {package_id: id}});
}


goBack(){
  this._location.back();
}
}
