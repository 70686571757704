import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
// import { MatSnackBar } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-add-user-package',
  templateUrl: './add-user-package.component.html',
  styleUrls: ['./add-user-package.component.scss']
})
export class AddUserPackageComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  addUserPackageForm: FormGroup;
  allPackage: any;
  packageList: any =[];
  selectedPackage: any;
  showAll = false;
  userId: any;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private _snackBar: MatSnackBar,
    private _location: Location
  ) { }


  ngOnInit() {
    this.addUserPackageForm = this.formBuilder.group({
      user_Name: new FormControl('',[Validators.required]),
      user_Email: new FormControl('',[Validators.required]),
      user_Contact: new FormControl('',[Validators.required]),
      user_Message: new FormControl('',[Validators.required]),
      location: new FormControl('',[Validators.required]),
      recommendation: new FormControl('',[Validators.required]),
      price: new FormControl('',[Validators.required]),
      currency: new FormControl('',[Validators.required]),
      total_amount: new FormControl('',[Validators.required]),
      packageSelection: new FormControl('',[Validators.required]),
    })
    this.getPackage();
  }

  getPackage(){
    this.enableSpinner$.next(true);
    this.commonService.userGetpackages().subscribe(data =>{
      this.enableSpinner$.next(false);
      if(data.Response){
        this.allPackage= data.Response.result;

        data.Response.result.forEach(element => {
          this.packageList.push(element)
          console.log(this.packageList);
        })
      }
    })
  }

  selectChange(selected,id){
    console.log(selected.value.id);
    this.selectedPackage= selected.value.id;
  }

  next(){
    const payLoadId={
      name : this.addUserPackageForm.value.user_Name,
      email : this.addUserPackageForm.value.user_Email,
      mobile : this.addUserPackageForm.value.user_Contact
    }
    this.enableSpinner$.next(true);
    this.commonService.getUserId(payLoadId).subscribe(response => {
      this.enableSpinner$.next(false);
      this.userId = response.user.user_id;
     console.log('getUserid', response.user.user_id)
     if (response.success == true) {
       this.showAll = true;
     }
    })
  }

  saveForm() {
    console.log(this.addUserPackageForm.value);
    const payLoad = {
      user_name: this.addUserPackageForm.value.user_Name,
      user_id:  this.userId,
      email: this.addUserPackageForm.value.user_Email,
      contact: this.addUserPackageForm.value.user_Contact,
      message: this.addUserPackageForm.value.user_Message,
      // transaction_details:{
        // transaction_id:{type:String},
      // amount:{type:Number},
      // currency:{type:String}},
      // addons_array: [{
        // name: {type: String}, 
        // id:{type: String}, 
        // price: {type: Number},
        //  description:    {type: String}}],//selected addon from master package
      location: this.addUserPackageForm.value.location,
      recommendation: this.addUserPackageForm.value.recommendation,
      package_id:this.selectedPackage,
      price: this.addUserPackageForm.value.price,
      currency: this.addUserPackageForm.value.currency,
      total_amount: this.addUserPackageForm.value.total_amount,
    }
    this.enableSpinner$.next(true);
    this.commonService.addUserPackage(payLoad).subscribe(response => {
      this.enableSpinner$.next(false);
      console.log(response)
      if (response.success == true) {
        // this.openSnackBar('Enquiry added Successfully', 'ok')
        this._location.back();
        // this.addUserPackageForm.reset();
        // this.selectedPackage.clear();
      }
      else {
        this.openSnackBar('Error Occured', 'ok')
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 1000,
    });
  }

  goBack() {
    this._location.back();
  }
} 
