import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from 'app/services/common.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-add-enquiry',
  templateUrl: './add-enquiry.component.html',
  styleUrls: ['./add-enquiry.component.scss']
})
export class AddEnquiryComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  enquiryBasicInfo: FormGroup;
  private _jsonURL = 'assets/artistData.json';
  artist_category: any;
  eventCategory: any;
  genreCategory: any;
  id: any;
  userId: any;
  showAll = false;

  constructor( private http: HttpClient,
               private formBuilder: FormBuilder,
               private commonService:CommonService,
               private ativateRouter: ActivatedRoute,
               private snackBar: MatSnackBar,
               private _location: Location) { 
                  this.getJSON().subscribe(data => {
                  console.log("json data api" + JSON.stringify(data));
                  this.artist_category = data.artist_category;
                  this.genreCategory = data.genre_category;
                  this.eventCategory = data.event_category;
                  
                }); 
              }
   public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }
  
  ngOnInit() {
    this.ativateRouter.queryParams.subscribe(param =>{
      this.id= param.id;
    })
    console.log(this.id)
    this.enquiryBasicInfo = this.formBuilder.group({
        first_name: ["", Validators.required],
        last_name: ["", Validators.required],
        contact_no: ["", Validators.required],
        email: ["", Validators.required],
        message: ["", Validators.required],
        min: ["", Validators.required],
        max: ["", Validators.required],
        full_address: ["", Validators.required],
       // artists: this.formBuilder.group({
      //   avg_rating:  ["", Validators.required],
      //   review_count:  ["", Validators.required],
      //   artist_name:  ["", Validators.required],
      //   artist_category:  ["", Validators.required],
      //   artist_city:  ["", Validators.required],
      //   image_url:  ["", Validators.required],
      //   artist_id:  ["", Validators.required],
      // }),
      // enquiry_type: ["", Validators.required],
      event_date:["", Validators.required],
      artist_category: ["", Validators.required],
      genre_category: ["", Validators.required],
      event_category: ["", Validators.required]
    })
  }

  next(){
    console.log('checking',this.enquiryBasicInfo.value)
    const payLoadId={
      name : this.enquiryBasicInfo.value.first_name,
      email : this.enquiryBasicInfo.value.email,
      mobile : this.enquiryBasicInfo.value.contact_no
    }
    this.enableSpinner$.next(true);
    this.commonService.getUserId(payLoadId).subscribe(response => {
      this.enableSpinner$.next(false);
      this.userId = response.user.user_id;
     console.log('getUserid', response.user.user_id)
     if (response.success == true) {
       this.showAll = true;
     }
    })
  }
  
  saveForm(){
    console.log(this.enquiryBasicInfo.value)
    const PayLoad= {
      artist_category: this.enquiryBasicInfo.value.artist_category,
      genre_category: this.enquiryBasicInfo.value.genre_category,
      event_category: this.enquiryBasicInfo.value.event_category,

      event_date: this.enquiryBasicInfo.value.event_date,
      event_loction: {
        lat: "latitude ",
        lng:  "longitude ",   
        full_address: this.enquiryBasicInfo.value.full_address,
      },
      budget: {
        min: this.enquiryBasicInfo.value.min,
        max: this.enquiryBasicInfo.value.max
      },
      id: this.userId,
      user_information: {
        first_name: this.enquiryBasicInfo.value.first_name,
        last_name: this.enquiryBasicInfo.value.last_name,
        contact_no: this.enquiryBasicInfo.value.contact_no,
        email: this.enquiryBasicInfo.value.email,
        message: this.enquiryBasicInfo.value.message,
        user_id: this.userId
      },
      // enquiry_type: this.enquiryBasicInfo.value.enquiry_type,
      artists: [
        {
          avg_rating: "asd",
          review_count: "sadas",
          artist_name: "sdas",
          artist_category: "asd",
          artist_city: "szdasd",
          image_url: "sdas",
          artist_id: "sdas",
          is_admin_approved: true
        }
      ]
    }
    
    console.log(PayLoad);
    this.commonService.editEnquiry(PayLoad).subscribe(response =>{
      console.log(response.success);
      if(response.success === true){
        this.openSnackbar('Enquiry added Successfully', 'ok')
        this.enquiryBasicInfo.reset();
      } else {
        this.openSnackbar('Error Occured', 'ok')
      }
    })
  }

  openSnackbar(message:string, action: string){
    this.snackBar.open(message,action,{
      duration: 2000,
    });
  }
  goBack(){
    this._location.back();
  }
}


