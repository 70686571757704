import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { packageRoutingModule } from './package-routing.module';
import { PackageListComponent } from './package-list/package-list.component';
import { EditPackageComponent } from './edit-package/edit-package.component';
import { AddPackageComponent } from './add-package/add-package.component';
import { PackageDetailsComponent } from './package-details/package-details.component';

@NgModule({
    declarations: [
     
    PackageListComponent,
    EditPackageComponent,
    AddPackageComponent,
    PackageDetailsComponent], 
    entryComponents: [

    ],
    imports: [
      CommonModule,
      packageRoutingModule,
      FormsModule,
      MaterialModule
    ],
   
  })
  
  export class packageModule{}