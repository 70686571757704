import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  artist_id: any;
  reviewData: any=[];
  page=1;
  limit=10;

  constructor(    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(param => {
      this.artist_id = param.artist_id;
      console.log(this.artist_id)
    });
    this.getReviews();
  }

  getReviews(){
    this.commonService.getAllReviews(this.limit, this.page).subscribe(success =>{
      console.log(success.Response.result);
      this.reviewData = success.Response.result;
    })
  }

  loadMore(){
    this.page ++;
    this.commonService.getAllReviews(this.limit, this.page).subscribe(success=> {
      console.log(success.Response.result);
      success.Response.result.forEach(element => {
        console.log(element);
          this.reviewData.push(element)
          // console.log(this.artistList);

      })
      console.log(this.reviewData);
      
      
    })
  }

  updateReview(event, id){
    console.log(event.checked, id);
    
    this.commonService.updateReview(id,{is_live  : event.checked} ).subscribe(success => {
      console.log("review updated");
      
    })
  }

  // loadMore(){
    
  // }

}
