import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AccountListComponent } from './account-list/account-list.component';
import { AccountDetailsComponent } from './account-details/account-details/account-details.component';
import { AccountEditComponent } from './account-edit/account-edit/account-edit.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'account-list',
        pathMatch: 'full'
    },
    { path: 'account-list', component: AccountListComponent },
    { path: 'account-details', component: AccountDetailsComponent },
    { path: 'account-edit', component: AccountEditComponent }


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }