import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

interface Category {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-enquiry-detail',
  templateUrl: './enquiry-detail.component.html',
  styleUrls: ['./enquiry-detail.component.scss']
})
export class EnquiryDetailComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  liveId: any;
  firstName: any;
  lastName: any;
  contactName: any;
  email: any;
  artistCategory: any;
  enquiryType: any;
  eventCategory: any;
  locations: any;
  genreCategory: any;
  packages: any;
  userId: any;
  message: any;
  bargain: any;
  normal: any;
  selected: any;
  rejected: any;
  id: any;
  crowdStrength: any;
  soundRequired: any;
  duration: any;
  placeLocation: any;

  categories: Category[] = [
    { value: 'normal', viewValue: 'Normal' },
    // {value: 'bargain', viewValue: 'Bargain'},
    { value: 'selected', viewValue: 'Selected' },
    { value: 'rejected', viewValue: 'Rejected' }
  ];
  artistId: any;
  enquiryId: any;
  selectedValue: any;
  event_date: any;
  minBudget: any;
  maxBudget: any;
  artistData: any;
  isDirect: any;
  create_date: any;
  constructor(private activateRouter: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private location: Location) { }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.liveId = param.id;
    });
    // console.log(this.liveId);
    this.getEnquiryDetails();
  }

  selectChange(artistId, enquiryId, category) {
    this.selectedValue = category;
    this.artistId = artistId;
    this.enquiryId = enquiryId;

    this.update();
  }

  getEnquiryDetails() {
    const data = {
      enquiry_id: this.liveId
    }
    this.enableSpinner$.next(true);
    this.commonService.enquiryDetails(data).subscribe(data => {
      this.enableSpinner$.next(false);
      // console.log(data);

      console.log(data.Response[0]);
      this.crowdStrength = data.Response[0].crowd_strength;
      this.soundRequired = data.Response[0].sound_required === 'true' ? 'Yes' : 'No';
      this.duration = data.Response[0].performance_time + ' mins';
      this.placeLocation = data.Response[0].event_location.full_address;
      this.firstName = data.Response[0].user_information.first_name;
      this.lastName = data.Response[0].user_information.last_name;
      this.contactName = data.Response[0].user_information.contact_no;
      this.email = data.Response[0].user_information.email;
      this.userId = data.Response[0].user_information.user_id;
      this.message = data.Response[0].user_information.message;
      this.event_date = data.Response[0].event_date;
      this.create_date = data.Response[0].createdAt;

      this.bargain = data.Response[0].bargain;
      this.normal = data.Response[0].normal;
      this.selected = data.Response[0].selected;
      this.rejected = data.Response[0].rejected;
      this.artistData = data.Response[0].artists;
      this.isDirect = data.Response[0].enquiry_type;

      // console.log(data.Response[0].bargain)
      this.artistCategory = data.Response[0].artist_category;
      this.enquiryType = data.Response[0].enquiry_type;
      this.eventCategory = data.Response[0].event_category;
      this.genreCategory = data.Response[0].genre_category;
      this.packages = data.Response[0].packages;
      this.locations = data.Response[0].event_location;
      this.minBudget = data.Response[0].budget.min;
      this.maxBudget = data.Response[0].budget.max;

      this.id = data.Response[0].id;
    });
  }
  EditEnquiry(id) {
    this.router.navigate(["/enquiry", "editEnquiry"], { queryParams: { id } });
  }

  addArtistToEnquiry() {
    this.router.navigate(["/enquiry", "selectArtist"], { queryParams: { id: this.liveId } });
  }
  goBack() {
    this.location.back();
  }

  update() {
    const payLoad = {
      enquiry_id: this.enquiryId,
      artist_enquiry_id: this.artistId,
      artist_enquiry_status: this.selectedValue
    }
    // console.log("payload", payLoad)
    this.commonService.updateEnquiry(payLoad).subscribe(response => {
      console.log(response);
      if (response.success == true) {
        this.getEnquiryDetails();

      } else {
        console.log('error')
      }
    })
  }
}