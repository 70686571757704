import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EnquiryListsComponent } from './enquiry-lists/enquiry-lists.component';
import { EnquiryDetailComponent } from './enquiry-detail/enquiry-detail.component';
import { AddEnquiryComponent } from './add-enquiry/add-enquiry.component';
import { EditEnquiryComponent } from './edit-enquiry/edit-enquiry.component';
import { SelectArtistToEnquiryComponent } from './select-artist-to-enquiry/select-artist-to-enquiry.component';
import { AddArtistToEnquiryComponent } from './add-artist-to-enquiry/add-artist-to-enquiry.component';
import { SendQuotationComponent } from './send-quotation/send-quotation.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'enquiry-list',
        pathMatch: 'full'
    },
    { path: 'enquiry-list', component: EnquiryListsComponent },
    { path: 'enqDetail', component:EnquiryDetailComponent },
    { path: 'addEnquiry', component: AddEnquiryComponent },
    { path: 'editEnquiry', component: EditEnquiryComponent },
    { path: 'selectArtist', component: SelectArtistToEnquiryComponent },
    { path: 'addArtistToEnquiry', component: AddArtistToEnquiryComponent },
    { path: 'send-quotation', component: SendQuotationComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class enquiryRoutingModule { }