import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';

interface Select {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-suprise-list',
  templateUrl: './suprise-list.component.html',
  styleUrls: ['./suprise-list.component.scss']
})
export class SupriseListComponent implements OnInit {

  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  supriseList: any = [];
  artistSearchList: any = [];
  currentPage: number = 1;
  successResult: any = [];
  displayedColumns: string[] = [
    'accountId', 'name', 'image', 'travelCity',
    'ArtistCategory', 'GenreCategory', 'EventCategory', 'InstrumentsCategory', 'Actions'
  ];

  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  id: any;

  selection: Select[] = [
    {value: 'view', viewValue: 'View'},
    {value: 'edit', viewValue: 'Edit'},
    {value: 'delete', viewValue: 'Delete'}
  ];

  constructor(private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {

    this.tableData1 = {
      headerRow: ['Offer Id', 'Suprise Id', 'Name', 'Contact No', 'Email', 'Location', 'Price', 'Actions'],
    };
    this.getSupriseEntertain();
  }


  getSupriseEntertain() {
    this.currentPage = 1;
    const PayLoad = {
      type: 'entertainer'
    }

    this.commonService.getAllList(PayLoad, this.nextPage).subscribe(data => {
      this.totalPage = data.Response.count;
      this.nextPage += 1;

      data.Response.forEach(element => {
        this.supriseList.push(element)
      })

    })
  }

  viewDetails(id) {
    console.log(id);
    this.router.navigate(["/event", "event-Details"], { queryParams: { id: id } });

  }
  editArtist(id) {
    console.log(id);
    this.router.navigate(["/event", "event-edit"], { queryParams: { id: id } });
  }

  viewMoreArtists() {
    this.getSupriseEntertain();
  }

  //search
  search(event) {
    this.searchResult = event.target.value;
    setTimeout(() => {
      this.currentPage = 1;
      this.searchApiCal();
    }, 1500);

  }

  searchApiCal() {
    if (this.searchResult.length >= 3) {
      this.supriseList = [];
      this.nextPage = 1;
      const payload = {
        first_name: true,
        artist: this.searchResult
      }
      console.log("searc")
      this.commonService.search(payload, this.currentPage).subscribe(success => {
        this.currentPage += 1;
        this.successResult = success.Response.result;
        success.Response.result.forEach(el => {
          this.supriseList.push(el);
          console.log(this.supriseList);
        })
      });
    } else if (this.searchResult.length < 1) {
      this.nextPage = 1;
      this.supriseList = [];
      this.getSupriseEntertain();
    }
  }

  addUserPackage(){
    
  }

  viewMoreSearch() {
    const payload = {
      first_name: true,
      artist: this.searchResult
    }
    this.commonService.search(payload, this.currentPage).subscribe(success => {
      this.currentPage += 1;
      this.successResult = success.Response.result;
      success.Response.result.forEach(el => {
        this.supriseList.push(el);
      })
    });
  }

  selectChange(event){
    console.log(event.value)
  }
}
