import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PackageListComponent } from './package-list/package-list.component';
import { EditPackageComponent } from './edit-package/edit-package.component';
import { AddPackageComponent } from './add-package/add-package.component';
import { PackageDetailsComponent } from './package-details/package-details.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'package-list',
        pathMatch: 'full'
    },
    { path: 'package-list', component: PackageListComponent },
    { path: 'package-details', component: PackageDetailsComponent },
    { path: 'package-edit', component: EditPackageComponent },
    { path: 'package-add', component: AddPackageComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class packageRoutingModule {}