import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { DatePipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

interface Select {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
  currentPage: number;
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  totalPage: any;
  dataReceived: any;
  accountList: any = [];
  tableData1: { headerRow: string[]; };
  loaded: boolean;
  nextPage: number = 1;
  filter: boolean = false;
  filterValue: any;
  showSearch: boolean = false;
  artistFilter: boolean = false;
  queryParamsForSearch: any;
  eventFilter: boolean = false;
  onlySearch: boolean = false;
  searchResult: any;
  selection: Select[] = [
    { value: 'view', viewValue: 'View' },
    { value: 'edit', viewValue: 'Edit' },
    // {value: 'delete', viewValue: 'Delete'}
  ];
  selectedValue: any;
  payload: any;
  showCalendar: boolean = false;
  createdDate: any;
  filter_list = [
    { value: 'account_name', label: 'Account Name' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'email', label: 'Email' },
    // { value: 'live101_id', label: 'Live101 Id' },
    // { value: 'city', label: 'City' },
    // { value: 'artist_category', label: 'Artist Category' },
    // { value: 'event_category', label: 'Event Category' },
    // { value: 'gender', label: 'Gender' },
    // { value: 'budget', label: 'Budget' },
    { value: 'created_date', label: 'Create Date' }
  ];


  constructor(private commonService: CommonService, private router: Router, private snackBar: MatSnackBar, private http: HttpClient, private datePipe: DatePipe) { }

  ngOnInit() {
    this.tableData1 = {
      headerRow: ['Name', 'Contact No', 'Email',
        // 'Account Type', 
        'Create Date',
        // 'Modified Date', 
        // 'Last Login Date', 
        // 'Admin Approved', 
        'Actions'],
    };
    this.getAccountsList();
  }
  onDateSelected(created_date) {
    this.createdDate = this.datePipe.transform(created_date, 'yyyy-MM-dd');
    this.searchApiCal(this.createdDate);
  }
  search(event) {
    this.searchResult = event.target.value;
    setTimeout(() => {
      this.currentPage = 1;
      this.searchApiCal(this.searchResult);
    }, 500);
  }
  searchApiCal(input) {
    this.accountList = [];
    this.nextPage = 1;
    if(this.filterValue === "account_name") {
      this.queryParamsForSearch = `&name=${input}`;
    } else if(this.filterValue === "mobile") {
      this.queryParamsForSearch = `&mobile=${input}`;
      
    } else if(this.filterValue === "email") {
      this.queryParamsForSearch = `&email=${input}`;
      
    } else if(this.filterValue === "created_date") {
      this.queryParamsForSearch = `&createDate=${input}`;
    }
    this.commonService.getAccountBySearch(this.queryParamsForSearch).subscribe((response) => {
      this.accountList = response.Response;
    })
  }

  onFilterSelect(event) {
    this.filterValue = event.value;
    if (this.filterValue === "account_name" || this.filterValue === "mobile" || this.filterValue === "email") {
      this.showSearch = true;
      this.eventFilter = false;
      this.artistFilter = false;
      this.showCalendar = false;
    } else if (this.filterValue === "created_date") {
      this.showSearch = false;
      this.showCalendar = true;
    }
    // else if (this.filterValue === "artist_category") {
    //   this.showSearch = false;
    //   this.eventFilter = false;
    //   this.BudgetFilter = false;

    //   this.artistFilter = true;
    //   this.filterCategory = this.artist_category_list;
    // } else if (this.filterValue === "event_category") {
    //   this.showSearch = false;
    //   this.BudgetFilter = false;

    //   this.eventFilter = true;
    //   this.artistFilter = false;
    //   this.filterCategory = this.event_category_list;
    // } else if (this.filterValue === "city") {
    //   this.showSearch = false;
    //   this.eventFilter = false;
    //   this.BudgetFilter = false;

    //   this.artistFilter = true;
    //   this.getJSON(this.citiUrl).subscribe(data => {
    //     console.log(data);
    //     this.filterCategory = data.cities;
    //   })
    // } else if (this.filterValue === "gender") {
    //   this.showSearch = false;
    //   this.eventFilter = false;
    //   this.artistFilter = true;
    //   this.BudgetFilter = false;
    //   this.filterCategory = [{ value: 'male', label: 'Male' },
    //   { value: 'female', label: 'Female' },];
    // }
    // else if (this.filterValue === "budget") {
    //   this.showSearch = false;
    //   this.eventFilter = false;
    //   this.artistFilter = false;
    //   this.BudgetFilter = true;
    //   this.filterCategory = [
    //     { label: "3,000-5,000", min_budget: 3000, max_budget: 5000 },
    //     { label: "5,000-10,000", min_budget: 5000, max_budget: 10000 },
    //     { label: "10,000-25,000", min_budget: 10000, max_budget: 25000 },
    //     { label: "25,000-50,000", min_budget: 25000, max_budget: 50000 },
    //     { label: "50,000-1,00,000", min_budget: 50000, max_budget: 100000 },
    //     { label: "1,00,000-2,50,000", min_budget: 100000, max_budget: 250000 },
    //     { label: "2,50,000-5,00,000", min_budget: 250000, max_budget: 500000 },
    //     { label: "5,00,000-10,00,000", min_budget: 500000, max_budget: 1000000 },
    //     { label: "10,00,000 & above", min_budget: 1000000, max_budget: 250000 },
    //   ];
    // } else if (this.filterValue == 'is_admin_approved') {
    //   this.nextPage = 0;
    //   this.artistList = [];
    //   this.getArtistList(true);
    // }

  }
  getAccountsList() {
    this.currentPage = 1;
    this.enableSpinner$.next(true);
    let inputData = {
      "isBusiness": this.filter
    }
    this.commonService.getAccounts(this.nextPage, inputData).subscribe(data => {
      this.enableSpinner$.next(false);
      this.totalPage = data.length;
      this.nextPage += 1;
      // data.Response.forEach(element => {
      //   this.accountList.push(element)
      // })
      this.accountList = data.Response.filter(eachVal => { return eachVal.accountType === 'USER' });
      this.loaded = true;
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');

    })
  }

  applyFilter = (event => {
    this.accountList = []
    if (event.value === "user") {
      this.filter = false;
    } else { this.filter = true }
    this.getAccountsList();
  })

  updateAdminApprove(event, accountId) {
    let inputData = {
      "isApproved": event.checked,
      "accountId": accountId
    }
    this.updateAdminApproved(inputData, accountId);
  }

  updateAdminApproved(inputData, accountId) {
    this.commonService.updateAdminApproved(inputData, accountId).subscribe(data => {
      this.openSnackbar('Account Updated Successfully', 'ok');

    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');

    })
  }

  selectChange(event, id) {
    this.selectedValue = event.value;
    if (this.selectedValue === 'view') {
      this.router.navigate(["/account", "account-details"], { queryParams: { id: id } });
    } else if (this.selectedValue === 'edit') {
      this.router.navigate(["/account", "account-edit"], { queryParams: { id: id } });
    } else {
      console.log('delete')
    }
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }
}
