import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-package',
  templateUrl: './add-package.component.html',
  styleUrls: ['./add-package.component.scss']
})
export class AddPackageComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  addBasicPackage: FormGroup;
  add_ons: FormArray;
  add_onData: FormGroup;
  private artistCategory_json = 'assets/artist_categories.json'
  items_provided: FormArray;

  fileDataLand: File = null;
  fileDataPotrait: File = null;
  image_url_Land: any;
  image_url_pot: any;
  landscape_image_url: any;
  potrait_image_url: any;
  artistJsonData: any;
  artist_category_list: any;
  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private _location: Location,    private http: HttpClient ) {
      this.getJSON(this.artistCategory_json).subscribe(data => {
        console.log(data);
        this.artistJsonData = data.artist_categories;
        this.artist_category_list = data.artist_categories;
  
        
      })
     }

  ngOnInit() {
    this.addBasicPackage = this.formBuilder.group({
      package_name: ["", Validators.required],
      package_description: ["", Validators.required],
      package_price: ["", Validators.required],
      discount_price: ["", Validators.required],
      currency: ["", Validators.required],
      title: ["", Validators.required],
      performance_time: ["", Validators.required],
      duration: ["", Validators.required],
      items_provided: this.formBuilder.array([ this.createItemProvided() ]),
      // items_provided
      // artist_category: ["", Validators.required],
      // item_detail_display: ["", Validators.required],
      // artist_subcategory: ["", Validators.required],
      // genre_category: ["", Validators.required],
      // event_category: ["", Validators.required],
      // quantity: ["", Validators.required],
      // add_ons
      add_ons: this.formBuilder.array([ this.createItem() ])
    })
  }

  createItemProvided(): FormGroup {
    return this.formBuilder.group({
      artist_category: ["", Validators.required],
            item_detail_display: ["", Validators.required],
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      price: ["", Validators.required],
      name: ["", Validators.required],
      description: ["", Validators.required]
    });
  }

  addNewAddons(): void {
    this.add_ons = this.addBasicPackage.get('add_ons') as FormArray;
    this.add_ons.push(this.createItem());
  }

  addNewItemProvided(): void {
    this.items_provided = this.addBasicPackage.get('items_provided') as FormArray;
    this.items_provided.push(this.createItemProvided());
  }

  public getJSON(jsonUrl): Observable<any> {
    return this.http.get(jsonUrl);
  }

  uploadImageland(fileInput: any) {
    this.fileDataLand = <File>fileInput.target.files[0];
    this.previewLand();
  }
  previewLand() {
    var mimeType = this.fileDataLand.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var readers = new FileReader();
    readers.readAsDataURL(this.fileDataLand);
    readers.onload = (_event) => {
      this.image_url_Land = readers.result;
    }
    this.enableSpinner$.next(true);
    this.commonService.uploadImage(this.fileDataLand).subscribe(response => {
      this.enableSpinner$.next(false);
      if(response["Data"]){
        console.log(response);
        this.landscape_image_url = response.Data[0];
        this.commonService.imgurl = this.landscape_image_url[0];
      }
    })
  }

  uploadImagePotrait(fileInput: any) {
    this.fileDataPotrait = <File>fileInput.target.files[0];
    this.previewPotrait();
  }
  previewPotrait() {
    var mimeType = this.fileDataPotrait.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileDataPotrait);
    reader.onload = (_event) => {
      this.image_url_pot = reader.result;
    }
    this.enableSpinner$.next(true);
    this.commonService.uploadImage(this.fileDataPotrait).subscribe(response => {
      this.enableSpinner$.next(false);
      if(response["Data"]){
        console.log(response);
        this.potrait_image_url = response.Data[0];
        this.commonService.imgurl = this.potrait_image_url[0];
      }
    })
  }
  
  saveForm() {
    console.log('before payload',this.addBasicPackage.value)
       const payLoad =  {
          "package_name":this.addBasicPackage.value.package_name,
          "description": this.addBasicPackage.value.package_description,
          "code":"kid_bday",
          "price": this.addBasicPackage.value.package_price,
          "discount_price":this.addBasicPackage.value.discount_price,
          "currency": this.addBasicPackage.value.currency,
          "title": this.addBasicPackage.value.title,
          "landscape_image_url": this.landscape_image_url,
          "potrait_image_url": this.potrait_image_url,
          "performance_time": this.addBasicPackage.value.performance_time,
          "duration": this.addBasicPackage.value.duration,
          "items_provided": this.addBasicPackage.value.items_provided,
               "add_ons":this.addBasicPackage.value.add_ons
               }
      //  {
      // package_name: this.addBasicPackage.value.package_name,
      // description: this.addBasicPackage.value.package_description,
      // image_url: this.profile_image_url[0],
      // price: this.addBasicPackage.value.package_price,
      // currency: "",
      // duration: "2000",
      // items_provided: [{
      //   artist_category: [String],
      //   artist_subcategory: [String],
      //   quantity: "2000",
      //   genre_category: [String],
      //   event_category: [String],
      // }],
      // sorting_order: "2000",
      // event_category: "",
    // }
    console.log('after payload',payLoad)
    this.enableSpinner$.next(true);
    this.commonService.addPackage(payLoad).subscribe(response => {
      this.enableSpinner$.next(false);
      if (response.success == true) {
        this.openSnackbar('Enquiry added Successfully', 'ok')
        this.addBasicPackage.reset();
      } else {
        this.openSnackbar('Error Occured', 'ok')
      }
    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  goBack() {
    this._location.back();
  }
}


// {
//   "package_name":"Kids Birthday Package",
//   "description":"Get one Singer and one magician in this bundled package to celebrate your kids birthday. Event will be online via Zoom.",
//   "code":"kid_bday",
//   "price":18000,
//   "discount_price":14999,
//   "currency":"INR",
//   "title":"Kids Birthday Package",
//   "landscape_image_url": "https://s3.ap-south-1.amazonaws.com/com.file.upload/folder/1589892229359Frame%2040_landscape.jpg",
//   "potrait_image_url": "https://s3.ap-south-1.amazonaws.com/com.file.upload/folder/1589892229360Frame%2041_potrait.jpg",
//   "performance_time":"30 Minutes",
//   "duration":1800,
 
//   "items_provided:[ 
//     {  "artist_category" : ["singer"],
//        "artist_subcategory": ["sufi"],
//        "genre_category": ["classic"],
//        "quantity": 1,
//        "event_category": ["wedding", "birthday"]
//    },
//        {
//            "artist_category" : ["magician"],
//            "artist_subcategory": ["table_magician"],
//            "genre_category": ["table_magician"], 
//            "quantity": 1,
//            "event_category": ["table_magician", "wedding"]
//        }],

//        "add_ons":[{"id":"dj_system", 
//                     "price":3000,
//                     "name":"D J",
//                     "description":"Full sound system for DJ performance by singer."}]


//        }

