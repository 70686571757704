import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

interface Select {
  value: string;
  viewValue: string;
}

export interface UserData {
  accountId: string;
  name: string;
  image: string;
  travelCity: string;
  ArtistCategory: string;
  GenreCategory: string;
  EventCategory: string;
  InstrumentsCategory: string;
  Actions: string;

}
@Component({
  selector: 'app-artist-list',
  templateUrl: './artist-list.component.html',
  styleUrls: ['./artist-list.component.scss']
})
export class ArtistListComponent implements OnInit {
  // p: number = 1;
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  artistList: any = [];
  artistSearchList: any = [];
  currentPage: number = 1;
  successResult: any = [];
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  loaded = false;
  payload: any;
  private _jsonURL = 'assets/artistData.json';
  private citiUrl = 'assets/travelCities.json';
  private artistCategory_json = 'assets/artist_categories.json';
  filter_list = [
    { value: 'artist_name', label: 'Artist Name' },
    { value: 'createdAt', label: 'Created Date'},
    { value: 'mobile', label: 'Mobile' },
    { value: 'email', label: 'Email' },
    { value: 'live101_id', label: 'Live101 Id' },
    { value: 'city', label: 'City' },
    { value: 'artist_category', label: 'Artist Category' },
    { value: 'event_category', label: 'Event Category' },
    { value: 'gender', label: 'Gender' },
    { value: 'budget', label: 'Budget' },
    { value: 'is_admin_approved', label: 'Admin Approved' }
  ];

  selection: Select[];
  selectedValue: string;
  filterValue: any;
  showSearch: boolean = false;
  showCalendar: boolean =  false;
  artistJsonData: any;
  artist_category_list: any;
  event_category_list: any;
  instrument_category_list: any;
  filterCategory: any;
  eventFilter: boolean = false;
  artistFilter: boolean = false;
  BudgetFilter: boolean = false;
  isMobileorEmailFilter: boolean = false;
  min_budget: any;
  max_budget: any;
  editScore: boolean = false;
  enableEditIndex = null;
  live101_score: any;
  updated_live101_score: any;
  live101_score_regex = /^[1-9]$|^[1-9][0-9]$|^(100)$/;
  onlySearch: boolean = false;
  dateFilterValue: any;
  createdDate: any;


  constructor(private commonService: CommonService, private router: Router, private snackBar: MatSnackBar, private http: HttpClient, private datePipe: DatePipe) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.tableData1 = {
      headerRow: ['Artist ID', 'Name', 'Images', 'City', 'Artist Category', 'Created Date', 'Genre Category', 'Mobile No.', 'Email', 'Live101 Score (Max 100)', 'Actions', 'Website link'],
    };
    this.getArtistList();
    if (localStorage.getItem('username') == 'admin') {
      this.selection = [
        { value: 'view', viewValue: 'View' },
        { value: 'edit', viewValue: 'Edit' },
        { value: 'delete', viewValue: 'Delete' }
      ];
    } else if (localStorage.getItem('username') == 'live101_admin') {
      this.onlySearch = true;
      this.selection = [
        { value: 'view', viewValue: 'View' }
      ];
    } else {
      this.selection = [
        { value: 'view', viewValue: 'View' },
        { value: 'edit', viewValue: 'Edit' }
      ];
    }

    this.getJSON(this.artistCategory_json).subscribe(data => {
      this.artistJsonData = data.artist_categories;
      this.artist_category_list = data.artist_categories;
    })
    this.getJSON(this._jsonURL).subscribe(data => {
      this.event_category_list = data.event_category;
    });
  }


  public getJSON(jsonUrl): Observable<any> {
    return this.http.get(jsonUrl);
  }

  scoreChange(e, i) {
    this.editScore = true;
    this.enableEditIndex = i;
  }

  //Update Live101 Score
  editLiveSCore(event, id, index) {
    const payload = { live101_score: this.updated_live101_score };
    this.commonService.saveArtistInfo(id, payload).subscribe(response => {
      if (response.success == true) {
        this.artistList[index] = response.Response;
        this.openSnackbar('Artist Details updated Successfully', 'ok');
        this.editScore = false;
        this.enableEditIndex = null;
      }
      else {
        this.openSnackbar('Error Occured', 'ok');
      }
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');
    })
  }

  getArtistList(is_admin_approved?) {
    this.currentPage = 1;
    this.enableSpinner$.next(true);
    this.commonService.getArtist(this.nextPage, is_admin_approved).subscribe(data => {
      this.enableSpinner$.next(false);
      this.totalPage = data.Response.count;
      this.nextPage += 1;
      this.dataReceived = data.Response.result;

      data.Response.result.forEach(element => {
        if (!element.is_deleted) {
          this.artistList.push(element)
        }

      })
      this.loaded = true;
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');

    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  selectChange(event, id) {
    this.selectedValue = event.value;
    if (this.selectedValue === 'view') {
      this.router.navigate(["/artists", "viewDetail"], { queryParams: { artist_id: id } });
    } else if (this.selectedValue === 'edit') {
      this.router.navigate(["/artists", "editArtist"], { queryParams: { artist_id: id } });
    } else if (this.selectedValue === 'delete') {
      this.commonService.saveArtistInfo(id, { is_deleted: true }).subscribe(response => {
        if (response.success == true) {
          this.openSnackbar('Artist Profile Deleted Successfully', 'ok')
          this.artistList = this.artistList.filter(item => item.artist_id !== id);
        }
        else {
          this.openSnackbar('Error Occured', 'ok');
        }
      }, err => {
        console.log(err);
        this.openSnackbar('Error Occured', 'ok');

      })
    }
  }

  viewDetails(artist_id) {
    this.router.navigate(["/artists", "viewDetail"], { queryParams: { artist_id: artist_id } });

  }
  editArtist(artist_id) {
    this.router.navigate(["/artists", "editArtist"], { queryParams: { artist_id: artist_id } });
  }

  viewMoreArtists() {
    this.getArtistList();
  }

  //search
  search(event) {
    this.searchResult = event.target.value;
    setTimeout(() => {
      this.currentPage = 1;
      this.searchApiCal();
    }, 500);
  }

  searchApiCal() {
    this.artistList = [];
    this.nextPage = 1;
    if (this.filterValue === "artist_name" || this.onlySearch) {
      this.payload = {
        first_name: true,
        artist: this.searchResult
      }
    } else if (this.filterValue === "mobile") {
      this.payload = {
        first_name: true,
        mobile: this.searchResult
      }
    } else if (this.filterValue === "email") {
      this.payload = {
        first_name: true,
        email: this.searchResult
      }
    } else if (this.filterValue === "city") {
      this.payload = {
        first_name: true,
        city: this.searchResult
      }
    } else if (this.filterValue === "live101_id") {
      this.payload = {
        first_name: true,
        live101_id: this.searchResult
      }
    } else if (this.filterValue === "artist_category") {
      this.payload = {
        first_name: true,
        artist_category: this.searchResult
      }
    } else if (this.filterValue === "event_category") {
      this.payload = {
        first_name: true,
        event_category: this.searchResult
      }
    } else if (this.filterValue === "gender") {
      this.payload = {
        first_name: true,
        gender: this.searchResult
      }
    } else if (this.filterValue === "budget") {
      this.payload = {
        first_name: true,
        min_budget: this.min_budget,
        max_budget: this.max_budget
      }
    } else if (this.filterValue === "is_admin_approved") {
      this.payload = {
        is_admin_approved: true
      }
    } else if (this.filterValue === "createdAt") {
      this.payload = {
        first_name: true,
        createdAt: this.createdDate
      }
    }
    this.commonService.search(this.payload, this.currentPage).subscribe(success => {
      if (success.Response) {
        this.currentPage += 1;
        this.successResult = success.Response.result;
        success.Response.result.forEach(el => {
          if (!el.is_deleted) {
            this.artistList.push(el);
          }
        })
      } else {
        console.log("error")
      }

    });
    if (this.searchResult.length < 1) {
      this.nextPage = 1;
      this.artistList = [];
      this.getArtistList();
    }
  }

  onFilterCatSelect(event) {
    this.searchResult = event.value;
  }

  applyFilter() {
    this.currentPage = 1;
    this.searchApiCal();
  }

  onBudgetSelect(event) {
    this.min_budget = event.value.min_budget;
    this.max_budget = event.value.max_budget;
  }

  onDateSelected(dateValue) {
    this.createdDate = this.datePipe.transform(dateValue, 'yyyy-MM-dd');
  }
  
  onFilterSelect(event) {
    this.filterValue = event.value;
    
    if (this.filterValue === "artist_name" || this.filterValue === "mobile" || this.filterValue === "live101_id" || this.filterValue === "email") {
      this.showSearch = true;
      this.eventFilter = false;
      this.artistFilter = false;
      this.showCalendar = false;
      this.isMobileorEmailFilter = true;
    } else if(this.filterValue === "createdAt") {
      this.showSearch = false;
      this.eventFilter = false;
      this.BudgetFilter = false;
      this.artistFilter = false;
      this.showCalendar = true;

    }
    else if (this.filterValue === "artist_category") {
      this.showSearch = false;
      this.eventFilter = false;
      this.BudgetFilter = false;
      this.artistFilter = true;
      this.showCalendar = false;
      this.filterCategory = this.artist_category_list;
      this.filterCategory.splice(0, 0, {value: 'blank', label: 'Blank', genres: []})
    } else if (this.filterValue === "event_category") {
      this.showSearch = false;
      this.BudgetFilter = false;

      this.eventFilter = true;
      this.artistFilter = false;
      this.filterCategory = this.event_category_list;
      this.showCalendar = false;
    } else if (this.filterValue === "city") {
      this.showSearch = false;
      this.eventFilter = false;
      this.BudgetFilter = false;
      this.showCalendar = false;
      
      this.artistFilter = true;
      this.getJSON(this.citiUrl).subscribe(data => {
        this.filterCategory = [];
        this.filterCategory = data.cities;
        this.filterCategory.splice(0, 0, { id: 0, value: 'blank', label: 'Blank', icon: 'icon-blank', bool: false })
        
      })
    } else if (this.filterValue === "gender") {
      this.showSearch = false;
      this.eventFilter = false;
      this.artistFilter = true;
      this.BudgetFilter = false;
      this.showCalendar = false;
      this.filterCategory = [{ value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },];
    }
    else if (this.filterValue === "budget") {
      this.showSearch = false;
      this.eventFilter = false;
      this.artistFilter = false;
      this.BudgetFilter = true;
      this.showCalendar = false;
      this.filterCategory = [
        { label: "3,000-5,000", min_budget: 3000, max_budget: 5000 },
        { label: "5,000-10,000", min_budget: 5000, max_budget: 10000 },
        { label: "10,000-25,000", min_budget: 10000, max_budget: 25000 },
        { label: "25,000-50,000", min_budget: 25000, max_budget: 50000 },
        { label: "50,000-1,00,000", min_budget: 50000, max_budget: 100000 },
        { label: "1,00,000-2,50,000", min_budget: 100000, max_budget: 250000 },
        { label: "2,50,000-5,00,000", min_budget: 250000, max_budget: 500000 },
        { label: "5,00,000-10,00,000", min_budget: 500000, max_budget: 1000000 },
        { label: "10,00,000 & above", min_budget: 1000000, max_budget: 250000 },
      ];
    } else if (this.filterValue == 'is_admin_approved') {
      this.nextPage = 0;
      this.artistList = [];
      this.showCalendar = false;
      this.getArtistList(true);
    }

  }

  viewNewArtists() {
    this.router.navigate(["/new-artists-registered"]);
  }

  viewMoreSearch() {
    this.commonService.search(this.payload, this.currentPage).subscribe(success => {
      this.currentPage += 1;
      this.successResult = success.Response;
      success.Response.result.forEach(el => {
        this.artistList.push(el);
      })
    });
  }
  addArtist() {
    this.router.navigate(["/artists", "addArtist"]);
  }
}

