import { Component, OnInit, Inject} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-mat-dialog',
  templateUrl: './mat-dialog.component.html',
  styleUrls: ['./mat-dialog.component.scss']
})
export class MatDialogComponent implements OnInit {
  messageForm: FormGroup;
  constructor( private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<MatDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
              ) {}

  ngOnInit() {
    console.log(this.data)
    this.messageForm = this.formBuilder.group({
      message: ["", Validators.required],
    })
  }

  saveForm(): void {
    let msg= this.messageForm.value.message
    console.log(this.messageForm.value.message);
    this.dialogRef.close(msg);
  }
}
