import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { userPackageRoutingModule } from './userPackage-routing.module';
import { UserPackageListComponent } from './user-package-list/user-package-list.component';
import { AddUserPackageComponent } from './add-user-package/add-user-package.component';
import { UserPackageDetailsComponent } from './user-package-details/user-package-details.component';
import { UserPackageEditComponent } from './user-package-edit/user-package-edit.component';


@NgModule({
    declarations: [
    UserPackageListComponent,
    AddUserPackageComponent,
    UserPackageDetailsComponent,
    UserPackageEditComponent], 
    
    entryComponents: [

    ],
    imports: [
      CommonModule,
      FormsModule,
      MaterialModule,
      userPackageRoutingModule
    ],
   
  })
  
  export class userPackageModule{}