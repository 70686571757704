import { Component, OnInit } from '@angular/core';
import { LocationStrategy, PlatformLocation, Location } from '@angular/common';
import { LegendItem, ChartType } from '../lbd/lbd-chart/lbd-chart.component';
import * as Chartist from 'chartist';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { DatePipe } from '@angular/common'
import { ArtistCategoryPipe } from 'app/pipes/artist.pipe';
import { CitiesPipe } from 'app/pipes/cities.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    endDate = new Date()
    startDate = new Date().setDate(this.endDate.getDate()-30)


    public emailChartType: ChartType;
    public emailChartData: any;
    public emailChartLegendItems: LegendItem[];

    public hoursChartType: ChartType;
    public hoursChartData: any;
    public hoursChartOptions: any;
    public hoursChartResponsive: any[];
    public hoursChartLegendItems: LegendItem[];
    public artistCategories = [];
    public artistCount = [];
    public topArtistCategory =[];
    public topArtistCount = [];

    public activityChartType: ChartType;
    public activityChartData: any;
    public activityChartOptions: any;
    public activityChartResponsive: any[];
    public activityChartLegendItems: LegendItem[];
  artistCategoryChartType: ChartType;
  budgetChartType : ChartType;
  artistCategoryLegendItems: LegendItem[];
  artistChartData: { series: number[]; };
  budgetChartData: { series: number[]; };

  artistRegistered: void;
  enquiryCreated: void;
  eventCreated: void;
  budgetCount: any[];
  budgetRange: any[];
  topBudgetLabel: any;
  topBudgetCount: any;
  budgetLegendItems:  LegendItem[];
  cityCount: any[];
  cityLabel: any[];
  topcityLabel: any;
  topcityCount: any;
  cityChartType: ChartType;
  cityChartData: { series: any[]; };
  cityChartLegendItems: { title: any; imageClass: string; }[];
  constructor(private commonService: CommonService, public datepipe: DatePipe,public artistPipe: ArtistCategoryPipe, public citiPipe: CitiesPipe) { }

  ngOnInit() {
    this.getDashbordDetails();
    this.getDailyCount();
      this.emailChartType = ChartType.Pie;
      this.emailChartData = {
        labels: ['62%', '32%', '6%'],
        series: [62, 32, 6]
      };
      this.emailChartLegendItems = [
        { title: 'Wedding', imageClass: 'fa fa-circle text-info' },
        { title: 'Private Event', imageClass: 'fa fa-circle text-danger' },
        { title: 'House Party', imageClass: 'fa fa-circle text-warning' }
      ];

      this.artistCategoryChartType = ChartType.Pie;
      this.artistChartData = {
        series: [...this.topArtistCount]
      };
      this.artistCategoryLegendItems = [
        { title: 'Singer', imageClass: 'fa fa-circle text-info' },
        { title: 'Music Band', imageClass: 'fa fa-circle text-danger' },
        { title: 'Dj', imageClass: 'fa fa-circle text-warning' },
        { title: 'Musician', imageClass: 'fa fa-circle blue-color' },
        { title: 'Comedian', imageClass: 'fa fa-circle mediumOrchid' },
        // { title: 'Magician', imageClass: 'fa fa-circle IndianRed' },
        // { title: 'Emcee/Anchor', imageClass: 'fa fa-circle LightCyan' },
        // { title: 'Dancer', imageClass: 'fa fa-circle Azure' },
        // { title: 'Speaker', imageClass: 'fa fa-circle LightPink' },
        // { title: 'Variety Artist', imageClass: 'fa fa-circle HotPink' },
        // { title: 'Celebrity', imageClass: 'fa fa-circle Beige' },
        // { title: 'Kids Entertainers', imageClass: 'fa fa-circle SandyBrown' },
        // { title: 'Unique Acts', imageClass: 'fa fa-circle Lavender' },
        // { title: 'Live Acts', imageClass: 'fa fa-circle RosyBrown' },


      ];

      this.hoursChartType = ChartType.Line;
      this.hoursChartData = {
        labels: ['9:00AM', '12:00AM', '3:00PM', '6:00PM', '9:00PM', '12:00PM', '3:00AM', '6:00AM'],
        series: [
          [287, 385, 490, 492, 554, 586, 698, 695, 752, 788, 846, 944],
          [67, 152, 143, 240, 287, 335, 435, 437, 539, 542, 544, 647],
          [23, 113, 67, 108, 190, 239, 307, 308, 439, 410, 410, 509]
        ]
      };
      this.hoursChartOptions = {
        low: 0,
        high: 800,
        showArea: true,
        height: '245px',
        axisX: {
          showGrid: false,
        },
        lineSmooth: Chartist.Interpolation.simple({
          divisor: 3
        }),
        showLine: false,
        showPoint: false,
      };
      this.hoursChartResponsive = [
        ['screen and (max-width: 640px)', {
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            }
          }
        }]
      ];
      this.hoursChartLegendItems = [
        { title: 'Open', imageClass: 'fa fa-circle text-info' },
        { title: 'Click', imageClass: 'fa fa-circle text-danger' },
        { title: 'Click Second Time', imageClass: 'fa fa-circle text-warning' }
      ];

      this.activityChartType = ChartType.Line;
      // this.activityChartData = {
      //   labels: this.artistCategories,
      //   series: this.artistCount
      // };
      this.activityChartData = {
        labels: this.artistCategories,
        series: [...this.artistCount
        ]
      };
      this.activityChartOptions = {
        seriesBarDistance: 10,
        axisX: {
          showGrid: false
        },
        height: '245px'
      };
      this.activityChartResponsive = [
        ['screen and (width: 100%)', {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            }
          }
        }]
      ];


    }
  getDailyReport() {
    throw new Error('Method not implemented.');
  }

    onDate(date){
      console.log(date);
      this.startDate = date;
    }
    onEndDate(date){
      console.log(date);
      this.artistCategories = [];
      this.artistCount = [];
      this.topArtistCategory = [];
      this.topArtistCount = [];
      this.endDate = date;
      this.getDashbordDetails();
    }

    getDailyCount(){
      let date = this.datepipe.transform(new Date(), "yyyy-MM-dd");
      this.commonService.getDashboardData({
        "filter": "artist",
        "date": date
      }).subscribe(res => this.artistRegistered = res.Response.count);

      this.commonService.getDashboardData({
        "filter": "enquiry",
        "date": date
      }).subscribe(res => this.enquiryCreated = res.Response.count);

      this.commonService.getDashboardData({
        "filter": "event",
        "date": date
      }).subscribe(res => this.eventCreated = res.Response.count);
    }
    getDashbordDetails(){
      let start =this.datepipe.transform(this.startDate, 'yyyy-MM-dd');
      let end = this.datepipe.transform(this.endDate, 'yyyy-MM-dd');
      console.log(start, end);

      const payload ={
        "filter": "artist_category",
        "date": {
            "start": start,
            "end": end
        }
    }
      this.commonService.getDashboardData(payload).subscribe(res => {
        this.artistCount= [];
        this.artistCategories =[];
        console.log(res.Response);
        let data = res.Response;
        data.sort(function(a, b) {
          return (b.count) - (a.count)
      });
      console.log(data);
      
        data.forEach(element => {
          console.log(element)
          this.artistCategories.push(this.artistPipe.transform(element.artist_category));
          this.artistCount.push(element.count);
          console.log(this.artistCount);
        });
        this.topArtistCategory = this.artistCategories.slice(0,5);
        this.topArtistCount = this.artistCount.slice(0,5);
        console.log(this.topArtistCategory);

        this.activityChartData = {
          labels: this.artistCategories,
          series: [[...this.artistCount]
          ]
          
        };

        this.artistChartData = {
          series: [...this.topArtistCount]
        };

        console.log(this.artistChartData);


        this.artistCategoryLegendItems = [
          { title: this.topArtistCategory[0], imageClass: 'fa fa-circle text-info' },
          { title: this.topArtistCategory[1], imageClass: 'fa fa-circle text-danger' },
          { title: this.topArtistCategory[2], imageClass: 'fa fa-circle text-warning' },
          { title: this.topArtistCategory[3], imageClass: 'fa fa-circle blue-color' },
          { title: this.topArtistCategory[4], imageClass: 'fa fa-circle greenColor' },
        ];
        console.log(this.activityChartData);


      })

      this.commonService.getDashboardData({
        "filter": "budget",
        "date": {
            "start": start,
            "end": end
        }
      }).subscribe(res => {
        this.budgetCount= [];
        this.budgetRange =[];
        let data = res.Response;
        data.sort(function(a, b) {
          return (b.count) - (a.count)
      });
      console.log(data);
      
        data.forEach(element => {
          console.log(element)
          this.budgetRange.push(element.min + "-" + element.max);
          this.budgetCount.push(element.count);
        });
        this.topBudgetLabel = this.budgetRange.slice(0,5);
        this.topBudgetCount = this.budgetCount.slice(0,5);
        console.log(this.topBudgetCount);

        this.budgetChartType = ChartType.Pie;

        this.budgetChartData = {
          series: [...this.topBudgetCount]   
        };


        console.log(this.budgetChartData);



        // this.artistChartData = {
        //   series: [...this.topArtistCount]
        // };

        this.budgetLegendItems = [
          { title: this.topBudgetLabel[0], imageClass: 'fa fa-circle text-info' },
          { title: this.topBudgetLabel[1], imageClass: 'fa fa-circle text-danger' },
          { title: this.topBudgetLabel[2], imageClass: 'fa fa-circle text-warning' },
          { title: this.topBudgetLabel[3], imageClass: 'fa fa-circle blue-color' },
          { title: this.topBudgetLabel[4], imageClass: 'fa fa-circle greenColor' },
        ];


      })

      this.commonService.getDashboardData({
        "filter": "city",
        "date": {
            "start": start,
            "end": end
        }
      }).subscribe(res => {
        this.cityCount= [];
        this.cityLabel =[];
        let data = res.Response;
        data.sort(function(a, b) {
          return (b.count) - (a.count)
      });
      console.log(data);
      
        data.forEach(element => {
          console.log(element)
          this.cityLabel.push(this.citiPipe.transform(element.city));
          this.cityCount.push(element.count);
        });
        this.topcityLabel = this.cityLabel.slice(0,5);
        this.topcityCount = this.cityCount.slice(0,5);
        console.log(this.topBudgetCount);

        this.cityChartType = ChartType.Pie;

        this.cityChartData = {
          series: [...this.topcityCount]   
        };


        // console.log(this.budgetChartData);



        // this.artistChartData = {
        //   series: [...this.topArtistCount]
        // };

        this.cityChartLegendItems = [
          { title: this.topcityLabel[0], imageClass: 'fa fa-circle text-info' },
          { title: this.topcityLabel[1], imageClass: 'fa fa-circle text-danger' },
          { title: this.topcityLabel[2], imageClass: 'fa fa-circle text-warning' },
          { title: this.topcityLabel[3], imageClass: 'fa fa-circle blue-color' },
          { title: this.topcityLabel[4], imageClass: 'fa fa-circle greenColor' },
        ];


      })
    }

}
