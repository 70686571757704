import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-offer-package-add',
  templateUrl: './offer-package-add.component.html',
  styleUrls: ['./offer-package-add.component.scss']
})
export class OfferPackageAddComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  addOfferPackageForm: FormGroup;
  allOfferPackage: any;
  offerPackageList: any = [];
  selectOffer: any;

  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private _location: Location
  ) { }


  ngOnInit() {
    this.addOfferPackageForm = this.formBuilder.group({
      user_Name: new FormControl('',[Validators.required]),
      user_Email: new FormControl('',[Validators.required]),
      user_Contact: new FormControl('',[Validators.required]),
      // user_Message: new FormControl('',[Validators.required]),
      // location: new FormControl('',[Validators.required]),
      recommendation: new FormControl('',[Validators.required]),
      price: new FormControl('',[Validators.required]),
      currency: new FormControl('',[Validators.required]),
      total_amount: new FormControl('',[Validators.required]),
      addon: new FormControl('',[Validators.required]),
      addon_name: new FormControl('',[Validators.required]),
      addon_price: new FormControl('',[Validators.required]),
      addon_description: new FormControl('',[Validators.required]),
      transaction_amount: new FormControl('',[Validators.required]),
      transaction_currency: new FormControl('',[Validators.required]),
      packageSelection: new FormControl('',[Validators.required]),
    })
    this.getOfferPackage()
  }

  getOfferPackage(){
    this.enableSpinner$.next(true);
    this.commonService.offerGetpackages().subscribe(data =>{
      this.enableSpinner$.next(false);
      if(data.Response){
      this.allOfferPackage= data.Response.result;

      data.Response.result.forEach(element => {
        this.offerPackageList.push(element)
        console.log(this.offerPackageList.name);
      })
    }
    })
  }
  selectChange(selected){
    console.log(selected.value.id);
    this.selectOffer= selected.value.id;
  }

  saveForm() {
    console.log(this.addOfferPackageForm.value);
    console.log(this.addOfferPackageForm.value);
    const payLoad = {
      user_name: this.addOfferPackageForm.value.user_Name,
      // user_id: { type: String },
      email: this.addOfferPackageForm.value.user_Email,
      contact: this.addOfferPackageForm.value.user_Contact,
      addon: [this.addOfferPackageForm.value.addon],
      transaction_details: { 
        // transaction_id: this.addOfferPackageForm.value.transaction_id,
        amount: this.addOfferPackageForm.value.transaction_amount, 
        currency: this.addOfferPackageForm.value.transaction_currency,
         },
      addons_array: [{ 
        name: this.addOfferPackageForm.value.addon_name,
        // id: this.addOfferPackageForm.value,
        price: this.addOfferPackageForm.value.addon_price,
        description: this.addOfferPackageForm.value.addon_description,
      }],
      recommendations: this.addOfferPackageForm.value.recommendation,
      offer_id: this.selectOffer,
      price: this.addOfferPackageForm.value.price,
      currency: this.addOfferPackageForm.value.currency,
      total_amount: this.addOfferPackageForm.value.total_amount,
    }
    console.log('hello');
    this.enableSpinner$.next(true);
    this.commonService.addOfferPackage(payLoad).subscribe(response => {
      this.enableSpinner$.next(false);
      console.log(response)
      if (response.success == true) {
        this.openSnackbar('Enquiry added Successfully', 'ok')
        this.addOfferPackageForm.reset();
      }
      else {
        this.openSnackbar('Error Occured', 'ok')
      }
    });
  }


  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  goBack() {
    this._location.back();
  }
} 
