import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserPackageListComponent } from './user-package-list/user-package-list.component';
import { AddUserPackageComponent } from './add-user-package/add-user-package.component';
import { UserPackageDetailsComponent } from './user-package-details/user-package-details.component';
import { UserPackageEditComponent } from './user-package-edit/user-package-edit.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'userPackage-list',
        pathMatch: 'full'
    },
    { path: 'userPackage-list', component: UserPackageListComponent },
    { path: 'add-userPackage', component: AddUserPackageComponent },
    { path: 'user-package-details', component: UserPackageDetailsComponent },
    { path: 'user-package-edit', component: UserPackageEditComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class userPackageRoutingModule {}