import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'language'})
export class Language implements PipeTransform {

    // private _jsonURL = 'assets/languages.json';
    languageData: any=[
        {
 
            "hindi": "Hindi",
            "english": "English",
            "marathi": "Marathi",
            "gujrati": "Gujrati",
            "punjabi": "Punjabi",
            "bengali": "Bengali",
            "kannada": "Kannada",
            "assasmese": "Assamese",
            "rajasthani": "Rajasthani",
            "other": "Other"
          }
    ];
    incomingValues : any = [];
  

    transform(value: any){
        this.incomingValues = value;
        console.log(this.languageData);
        
        return this.incomingValues.map(val => this.languageData[0][val]);
        
    }
}