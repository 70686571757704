import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


export interface UserData {
  accountId: string;
  name: string;
  image: string;
  travelCity: string;
  ArtistCategory: string;
  GenreCategory: string;
  EventCategory: string;
  InstrumentsCategory: string;
  Actions: string;
}

@Component({
  selector: 'app-select-artist-to-enquiry',
  templateUrl: './select-artist-to-enquiry.component.html',
  styleUrls: ['./select-artist-to-enquiry.component.scss']
})
export class SelectArtistToEnquiryComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  artistList: any =[];
  artistSearchList: any =[];
  currentPage:number = 1;
  successResult:any = [];
  displayedColumns: string[] = [
    'accountId', 'name', 'image', 'travelCity',
    'ArtistCategory', 'GenreCategory', 'EventCategory', 'InstrumentsCategory'
  ];
  dataSource: MatTableDataSource<UserData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  enquiry_id: any;
  requestType: any;
  artistSelected: any;
  artistJsonData: any;
  artist_category_list: any;
  event_category_list: any;
  instrument_category_list: any;
  citiesList: any;
  genre_category_list: any;
  budgetList = [    
    {label: "3,000-5,000", min_budget: 3000 ,max_budget: 5000},
    {label: "5,000-10,000", min_budget: 5000 ,max_budget: 10000},
    {label: "10,000-25,000", min_budget: 10000 ,max_budget: 25000},
    {label: "25,000-50,000", min_budget: 25000 ,max_budget: 50000},
    {label: "50,000-1,00,000", min_budget: 50000 ,max_budget: 100000},
    {label: "1,00,000-2,50,000", min_budget: 100000 ,max_budget: 250000},
    {label: "2,50,000-5,00,000", min_budget: 250000 ,max_budget: 500000},
    {label: "5,00,000-10,00,000", min_budget: 500000 ,max_budget: 1000000},
    {label: "10,00,000 & above", min_budget: 1000000 ,max_budget: 250000},
];
genderList = [    {value: 'male', label: 'Male'},
{value: 'female', label: 'Female'}];
  private _jsonURL = 'assets/artistData.json';
  private citiUrl = 'assets/travelCities.json';
  private artistCategory_json = 'assets/artist_categories.json';
  payload: { event_category: any; genre_category: any; artist_category: any; travel_city: any; gender: any; languages: any; budget: any; is_popular: any; };
  eventSelected: any;
  genreSelected: any;
  categorySelected: any;
  locationSelected: any;
  genderSelected: any;
  rangeSelected: any;
  filterApply: boolean = false;

  constructor( private commonService: CommonService,
               private router:Router,
               private _location: Location,
               private activateRoute : ActivatedRoute,
               private snackBar: MatSnackBar,
               private http: HttpClient) {

    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(param =>{
      console.log(param);
      this.requestType = param.type;
      this.enquiry_id = param.id;
    })
    this.tableData1 = {
      headerRow: ['Selected Artist','Artist ID', 'Name', 'Images', 'City', 'Artist Category', 'Genre Category', 'Mobile No.' ,'Email'],

  };
  this.getJSON(this.artistCategory_json).subscribe(data => {
    console.log(data);
    this.artistJsonData = data.artist_categories;
    this.artist_category_list = data.artist_categories;   
  })
  this.getJSON(this._jsonURL).subscribe(data => {
    console.log(data);
    this.event_category_list = data.event_category;
  });

  this.getJSON(this.citiUrl).subscribe(data => {
    console.log(data); 
    this.citiesList = data.cities;
  }) 
    this.getArtistList();
  }

  public getJSON(jsonUrl): Observable<any> {
    return this.http.get(jsonUrl);
  }
  getArtistList(){
    this.currentPage =1;
    this.enableSpinner$.next(true);
    let adminApproved= true;
    this.commonService.getArtist(this.nextPage,adminApproved).subscribe(data =>{
      this.enableSpinner$.next(false);
      this.totalPage= data.Response.count;
      this.nextPage +=1;
      console.log(this.totalPage)
      this.dataReceived= data.Response.result;

      data.Response.result.forEach(element => {
        this.artistList.push(element)
        console.log(this.artistList);
        // console.log("data received" + JSON.stringify(this.artistList));
      })

    })
  }

  viewMoreArtists(){
    this.getArtistList();
  }

  //search
  search(event){
    this.searchResult= event.target.value;
    setTimeout (() => {
      this.currentPage = 1;
      this.searchApiCal();
   }, 1500);
        
        }

openSnackbar(message: string, action: string) {
  this.snackBar.open(message, action, {
    duration: 1000,
  });
}


  viewMoreSearch(){
    const payload={
      first_name: true,
      artist: this.searchResult
  }
  this.enableSpinner$.next(true);
    this.commonService.search(payload,this.currentPage).subscribe(success =>{
      this.enableSpinner$.next(false);
      this.currentPage +=1;
      this.successResult= success.Response;
      success.Response.forEach(el => {
        this.artistList.push(el);
})
      });
  }
    addArtist(){
    this.router.navigate(["/addArtist"]);
  }

  artistChange(event){
    console.log(event);
    
    console.log(this.artistJsonData.findIndex(x => x.value === event));

    let index = this.artistJsonData.findIndex(x => x.value === event);
    const genreList = this.artistJsonData[index]['genres'];
    this.genre_category_list = genreList;



  }

  applyFilter(){
    this.currentPage = 1;
    this.filterApply = true;
    this.searchApiCal();

  }
  searchApiCal() {
    if(this.currentPage == 1){
      this.artistList = [];

    }
    console.log(this.searchResult)
 this.nextPage = 1;
this.payload = {"event_category":this.eventSelected,"genre_category":this.genreSelected,"artist_category":this.categorySelected,"travel_city":this.locationSelected,"gender":this.genderSelected,"languages":null,"budget":this.rangeSelected,"is_popular":null}
// console.log(payload) 
this.commonService.searchMultiple(this.payload, this.currentPage).subscribe(success => {
   if (success.Response) {
    //  this.currentPage += 1;
     this.successResult = success.Response.result;
     console.log(this.successResult)
     success.Response.result.forEach(el => {
       if(!el.is_deleted){
         this.artistList.push(el);
         console.log(this.artistList);
       }

     })
   } else {
     console.log("error")
   }

 });
// if (this.searchResult.length < 1) {
//   this.nextPage = 1;
//   this.artistList = [];
//   this.getArtistList();
// }
}

viewMoreWithFilter(){
  this.currentPage = this.currentPage + 1;
  this.searchApiCal();

}
  getArtistDetails(id){
    this.commonService.viewDetails(id).subscribe(res => {
      console.log(res);
      this.artistSelected = res.Response;
      console.log(this.artistSelected);
      const payload = {
        "artists": [this.artistSelected]
      }
      this.commonService.pushArtistToEvent(this.enquiry_id,payload).subscribe(res => {
        console.log(res);
        this.openSnackbar('Artist added Successfully', 'ok')
         this.router.navigate(['/event']);


      })
    })
  }

  async selectArtist(id){
    console.log('selectd Artist',id)
    if(this.requestType === "event"){
      console.log(id, this.enquiry_id);
      this.getArtistDetails(id);
    }else{
    this.router.navigate(['/enquiry', 'addArtistToEnquiry'],{ queryParams: {live101_id:id, enquiry_id:this.enquiry_id}});

    }
  }
  goBack(){
    this._location.back();
  }
}

