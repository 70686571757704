import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'eventPipe' })
export class EventPipe implements PipeTransform {

    eventData: any={

        "wedding": "Wedding",
      
        "private_event": "Private Event",
      
        "house_party": "House Party",
      
        "anniversary_celebration": "Anniversary Celebration",
      
        "birthday_party": "Birthday Party",
      
        "office_event": "Office Event",
      
        "corporate_event": "Corporate Event",
      
        "cafe": "Cafe",
      
        "lounges": "Lounges",
      
        "bars": "Bars",
      
        "restaurants": "Restaurants",
      
        "5_star": "5 Star",
      
        "hotels": "Hotels",
      
        "musical_surprise": "Musical Surprise",
      
        "resorts": "Resorts",
      
        "house_warming": "House Warming",
      
        "engagement": "Engagement",
      
        "baby_shower": "Baby Shower",
      
        "family_get_together": "Family Get-together",
      
        "friends_reuninon": "Friends Reunion",
      
        "social_gathering": "Social Gathering"
      
      };
    incomingValues : any = [];

    

    transform(value: any){
        this.incomingValues = value;
        return this.incomingValues.map(val => this.eventData[val]);
        
    }
}