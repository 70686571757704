import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-edit-package',
  templateUrl: './edit-package.component.html',
  styleUrls: ['./edit-package.component.scss']
})
export class EditPackageComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  id: any;
  editPackageInfoForm:FormGroup;
  // DateTime : DateTime
  date =new Date();
  userName: any;
  email: any;
  contact: any;
  location: any;
  message: any;
  addon: any;
  price: any;
  addonsArray: any;
  recommendation: any;
  totalAmount: any;
  currency: any;
  userID: any;
  // offerID: any;

  constructor(private ativateRouter: ActivatedRoute,
              private commonService: CommonService,
              private formBuilder : FormBuilder,
              private snackBar: MatSnackBar,
              private _location: Location) { }

  ngOnInit() {
    // editOfferPackage
    this.ativateRouter.queryParams.subscribe(param=>{
      this.id = param.id;
      console.log(param.id)
    })
    console.log(this.date)
    this.editPackageInfoForm =  this.formBuilder.group({
        user_name :  ["", Validators.required],
        email :  ["", Validators.required],
        contact :  ["", Validators.required],
        location :  ["", Validators.required],
        message :  ["", Validators.required],
        price :  ["", Validators.required],
        recommendation :  ["", Validators.required],
        total_amount :  ["", Validators.required],
        currency :  ["", Validators.required],
        addon : ["", Validators.required],
        addons_array :  ["", Validators.required],
        

    }) 
    this.getoffer();
  }

getoffer(){
  const payLoad= {
    user_offer_id : this.id,
  }
  this.enableSpinner$.next(true);
  this.commonService.getOfferpackage(this.id).subscribe(data =>{
    this.enableSpinner$.next(false);
    if(data.Response){
    console.log(data);
    this.userID = data.Response.user_id;
    // this.offerID = data.Response.offer_id;
    this.userName =  data.Response.user_name;
    this.email =  data.Response.email;
    this.contact =  data.Response.contact;
    this.location =  data.Response.location;
    this.message =  data.Response.message;
    this.price =  data.Response.price;
    this.recommendation =  data.Response.recommendation;
    this.totalAmount =  data.Response.total_amount;
    this.currency =  data.Response.currency;
    this.addon = data.Response.addon;
    this.addonsArray =  data.Response.addons_array;
    this.editPackageInfoForm.patchValue({
      user_name : this.userName,
        email : this.email,
        contact : this.contact,
        location : this.location,
        message : this.message,
        price :  this.price,
        recommendation : this.recommendation,
        total_amount : this.totalAmount,
        currency : this.currency,
        addon : this.addon ,
        addons_array :  this.addonsArray,
    })
  } 
 })

}
saveForm(){
  console.log(this.editPackageInfoForm.value)
  const payLoad={
  user_name: this.editPackageInfoForm.value.user_name,
  user_id: this.userID,
  email: this.editPackageInfoForm.value.email,
  contact: this.editPackageInfoForm.value.contact,
  // addon: [String],
  // transaction_details:{transaction_id:{type:String},amount:{type:Number},currency:{type:String}},
  // addons_array: [{name: {type: String}, id: {type: String}, price: {type: Number}, description: {type: String}}],
  recommendations: this.editPackageInfoForm.value.recommendation,
  // offer_id: this.offerID,
  price:this.editPackageInfoForm.value.price,
  currency: this.editPackageInfoForm.value.currency,
  total_amount:this.editPackageInfoForm.value.total_amount,
  }
  console.log(payLoad)
  this.enableSpinner$.next(true);
  this.commonService.editOfferPackage(this.id,payLoad).subscribe(response =>{
  this.enableSpinner$.next(false);
  console.log(response);
  if (response.success == true) {
    this.openSnackbar('Package Saved Successfully', 'ok')
  }
  else {
    this.openSnackbar('Error Occured', 'ok')
  }
})
}

openSnackbar(message: string, action: string) {
  this.snackBar.open(message, action, {
    duration: 2000,
  });
}
goBack() {
  this._location.back();
}
}
