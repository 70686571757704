import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'genre'})
export class Genre implements PipeTransform {

    genreData: any={
        "bollywood_musician":"Bollywood",
        "english_musician":"English",
        "soulful_musician":"Bhajan",
        "sufi":"Sufi",
        "jazz":"Jazz",
        "hip_hop":"Hip-Hop",
        "rap":"Rap",
        "ghazal":"Ghazal",
        "punjabi":"Punjabi",
        "classical":"Classical",
        "folk_regional":"Folk Regional",
        "jazz_bands":"Jazz Bands",
        "rock":"Rock",
        "western":"Western",
        "bollywood":"Bollywood",
        "fusion":"Fusion",
        "english":"English",
        "underground_music":"Underground Music",
        "techno":"Techno",
        "dubstep":"Dubstep",
        "edm":"EDM",
        "bollywood_dj":"Bollywood DJ",
        "commercial":"Commercial",
        "rock_and_roll":"Rock & Roll",
        "hotel_music":"Hotel Music",
        "soothing":"Soothing",
        "classical_musician":"Classical Musician",
        "standup":"Standup",
        "spoof_or_prop_comedian":"Spoof or Prop Comedian",
        "character_comedian":"Character Comedian",
        "mimicry_artist":"Mimicry Artist",
        "comedy_group":"Comedy Group",
        "poet_comedian":"Poet Comedian",
        "table_magicians":"Table Magicians",
        "psychological_magicians":"Psychological Magicians",
        "illusionists":"Illusionists",
        "stage_magicians":"Stage Magicians",
        "card_magicians":"Card Magicians",
        "street_magicians":"Street Magicians",
        "emcee": "Emcee",
        "wedding_emcee":"Wedding Emcee",
        "corporate_emcee":"Corporate Emcee",
        "party_emcee":"Party Emcee",
        "private_party_emcee":"Private Party Emcee",
        "event_emcee":"Event Emcee",
        "folk":"Folk",
        "wedding":"Wedding",
        "salsa":"Salsa",
        "belly_dancers":"Belly Dancers",
        "locking_popping":"Locking & Popping",
        "freestyle":"Freestyle",
        "punjabi_dance_troupe":"Punjabi Dance Troupe",
        "garba_dance_troupe":"Garba Dance Troupe",
        "kathak":"Kathak",
        "bharatnatyam":"Bharatnatyam",
        "tedx":"TedX",
        "business":"Business",
        "motivational":"Motivational",
        "beat_boxer":"Beat Boxer",
        "poet":"Poet",
        "unique_acts":"Unique Acts",
        "photographer":"Photographer",
        "russian_artist":"Russian Artist",
        "puppet_artist":"Puppet Artist",
        "sand_artist":"Sand Artist",
        "capella":"Capella",
        "zumba_fitness": "Zumba & Fitness"
     };
    incomingValues : any = [];


    transform(value: any){
        this.incomingValues = value;
        return this.incomingValues.map(val => this.genreData[val]);
        
    }
}