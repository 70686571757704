import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import {Location} from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  liveId: any;
  firstName: any;
  lastName: any;
  contactName: any;
  email: any;
  artistCategory: any;
  enquiryType: any;
  eventCategory: any;
  location: any;
  genreCategory: any;
  packages: any;
  userId: any;
  message: any;
  bargain: any;
  normal: any;
  selected: any;
  rejected: any;
  id: any;
  newComment: string;
  newAdminComment: string;


 
  artistSubCategory: any;
  eventType: any;
  description: any;
  artist: any;
  eventId: any;
  eventDate: any;
  price: any;
  reportsData: any;
  withdraw_artist: any;
  constructor(private activateRouter: ActivatedRoute,
              private commonService: CommonService,
              private router: Router,
              private _location: Location){ }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.liveId= param.id;
    });
    console.log(this.liveId);
    this.getEventDetails();
    this.getReport();
  }

  getReport(){
    this.enableSpinner$.next(true);
    this.commonService.eventReport(this.liveId).subscribe(data =>{
      this.enableSpinner$.next(false);
    console.log(data.Response.result);
    this.reportsData = data.Response.result;
    
    });
  }

getEventDetails(){
  this.enableSpinner$.next(true);
  this.commonService.eventDetails(this.liveId).subscribe(data =>{
    this.enableSpinner$.next(false);
  console.log(data.Response[0]);
  this.firstName= data.Response[0].user.first_name;
  this.lastName= data.Response[0].user.last_name;
  this.contactName= data.Response[0].user.contact_no;
  this.email= data.Response[0].user.email;
  this.userId= data.Response[0].user.user_id;
  this.message= data.Response[0].user.message;
  this.artistCategory= data.Response[0].artist_category;
  this.artistSubCategory= data.Response[0].artist_subcategory;
  this.eventType= data.Response[0].event_type;
  this.genreCategory= data.Response[0].genre;
  this.location= data.Response[0].event_location;
  this.description= data.Response[0].description;
  this.id= data.Response[0].id;
  this.artist= data.Response[0].artists;
  this.withdraw_artist = data.Response[0].withdraw_artists;
  this.eventId = data.Response[0].event_id;
  this.eventDate = data.Response[0].event_date;
  this.price = data.Response[0].enquiry_transaction_details;


  console.log(this.artist, this.withdraw_artist)

  });
} 

editEvent(id){
  this.router.navigate(["/event","event-edit"],{ queryParams: {id}});
}

sendComment(id){
  console.log(this.newComment);
  let comment;
  if(this.newComment){
    comment= this.newComment;
  }else if(this.newAdminComment){
    comment = this.newAdminComment;
  }
  const payLoad = {
      comments:[{
      account_id:"5e2dd4f9058af651a6e98b0b" ,
      comment:comment,
      user_type:"admin"
      }]
  }
  this.commonService.sendComment(id, payLoad).subscribe(response => {
    console.log(response)
    if (response.success == true) {
      this._location.back();
      // this.openSnackbar('Package Saved Successfully', 'ok')
    }
    else {
      // this.openSnackbar('Error Occured', 'ok')
      console.log("error");
      
    }
  })
  
}

addArtistToEvent(){
  this.router.navigate(["/event", "selectArtist"],{ queryParams: {type: "event", id :this.liveId}});
}


goBack(){
  this._location.back();
}
}
