import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ImageModalComponent } from 'app/image-modal/image-modal.component';
import { YoutubeModalComponent } from 'app/youtube-modal/youtube-modal.component';


@Component({
  selector: 'app-view-artist-detail',
  templateUrl: './view-artist-detail.component.html',
  styleUrls: ['./view-artist-detail.component.scss']
})
export class ViewArtistDetailComponent implements OnInit {
  loaded = false;
  artist_id: number;
  artistProfile: any;
  artistImage: any;
  aboutImage: any;
  firstName: any;
  lastName: any;
  accountId: any;
  intro: any;
  languages: any;
  price: any;
  social: any;
  artistCategory: any;
  songList: any;
  travelCity: any;
  bankDetails: any;
  bankdetails: { type: string; value: unknown; }[];
  kycDetails: { type: string; value: unknown; }[];
  gallery: any;
  videos: any;
  facebook: any;
  instagram: any;
  youtubeId: any;
  id: number;
  websiteLink: string;

  name = 'Angular 6';
  safeSrc: SafeResourceUrl;

  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  city: any;
  experience: any;
  gigs: any;
  is_travel_city: any;
  mobile: any;
  is_popular: any;
  is_premium: any;
  is_admin_approved: any;
  email: any;
  reviewData: any;
  reviewAvailable: boolean= true;
  live101_score: any;
  is_bank_verified: any;
  is_pan_verified: any;
  is_adhaar_verified: any;
  commision: any;
  about: any;
  createdAt: any;
  onlySearch: boolean = false;
  constructor(
              private commonService: CommonService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private _location: Location,
              private sanitizer: DomSanitizer,
              public dialog: MatDialog
            ) { 
              // this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/c9F5kMUfFKk");
            }

  ngOnInit() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    this.activatedRoute.queryParams.subscribe(param => {
    this.artist_id = param.artist_id;
    });
    this.getArtistDetails();
    this.getReviews();
    if(localStorage.getItem('username') == 'live101_admin'){
      this.onlySearch = true;
    }
  }

  getReviews(){
    this.commonService.getReview(this.artist_id,3).subscribe(success =>{
      console.log(success.Response.result);
      this.reviewData = success.Response.result;
      if (this.reviewData.length === 0) {
        this.reviewAvailable = false;
      }
      console.log(this.reviewData.length);
      
    })
  }

  viewAllReview(){
    this.router.navigate(["/artists","artistReview"], { queryParams: { artist_id: this.artist_id}});
  }
  getArtistDetails(){
    this.enableSpinner$.next(true);
    this.commonService.viewDetails(this.artist_id).subscribe(success =>{
      console.log(success.Response)
      this.enableSpinner$.next(false);
        this.artistProfile = success.Response;
        this.artistImage = success.Response.personal_info.profile_image_url;
        this.aboutImage = success.Response.image;
        this.about = success.Response.about;
        this.firstName = success.Response.personal_info.first_name;
        this.lastName = success.Response.personal_info.last_name;
        this.accountId = success.Response.personal_info.account_id;
        this.intro = success.Response.about.intro;
        this.languages = success.Response.languages;
        this.price = success.Response.hourly_price;
        this.websiteLink = "https://www.live101.in/artist-profile/" + success.Response.artist_id;
        // this.social= success.Response.social_media;
        // this.facebook= success.Response.social_media.fb;
        // this.instagram=  success.Response.social_media.instagram;
        // this.youtubeId= success.Response.social_media.youtube;
        this.artistCategory= success.Response.artist_category;
        this.songList= success.Response.song_list;
        this.city= success.Response.personal_info.city;
        this.travelCity= success.Response.travel_city;
        var bankDetails= success.Response.artist_payment_details.bank_details;
        this.experience = success.Response.year_of_exp;
        this.gigs = success.Response.live101_gigs;
        this.live101_score = success.Response.live101_score;
        this.is_travel_city = success.Response.is_travel_city;
        this.is_popular = success.Response.is_popular;
        this.commision= success.Response.commission_percentage;
        this.is_premium = success.Response.is_premium;
        this.is_admin_approved= success.Response.is_admin_approved;
        this.is_bank_verified = success.Response.artist_payment_details.bank_details.is_bank_verified;
        this.is_pan_verified = success.Response.artist_payment_details.kyc_details.is_pan_verified;
        this.is_adhaar_verified = success.Response.artist_payment_details.kyc_details.is_adhaar_verified;

        this.mobile = success.Response.personal_info.mobile;
        this.createdAt = success.Response.createdAt;
        this.email = success.Response.personal_info.email;
        this.bankdetails = Object.entries(bankDetails).map(([type, value]) => ({type, value}));
        console.log(this.bankdetails);

        var kyc= success.Response.artist_payment_details.kyc_details;
        this.kycDetails = Object.entries(kyc).map(([type, value]) => ({type, value}));
        
        if(success.Response.gallery.images.length >0){
          this.gallery = success.Response.gallery.images;
        }

        if(success.Response.gallery.videos.length >0){
          this.videos = success.Response.gallery.videos;
          console.log(this.videos);
          // this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/FEabQOpdIiE");
        }

        this.loaded = true;
    })
  }

  openDialog(imageUrl) {
    console.log(imageUrl);
    
    this.dialog.open(ImageModalComponent, {
      data: {
        imageUrl
      }
    });
  }

  openYoutubeDialog(url) {
    console.log(url);
    
    this.dialog.open(YoutubeModalComponent, {
      data: {
        url
      }
    });
  }

  gotoEdit(){
    this.router.navigate(["/artists","editArtist"], { queryParams: { artist_id: this.artist_id}});
  }
  goBack(){
    this._location.back();
  }

  getVideo(id){
    const safeSrca =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/" + id);
    return safeSrca;
  }
}
