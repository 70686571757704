import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FooterModule } from './shared/footer/footer.module';
import { SidebarModule } from './sidebar/sidebar.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './shared/materialModule/material.module';
import { CommonMatDialogComponent } from './shared/components/common-mat-dialog/common-mat-dialog.component';
import { CommonMatInputComponent } from './shared/components/common-mat-input/common-mat-input.component';
import { UserComponent } from './user/user.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ArtistCategoryPipe } from './pipes/artist.pipe';
import { EventPipe } from './pipes/event.pipe';
import { Genre } from './pipes/genre.pipe';
import { Instrument } from './pipes/instrument.pipe';
import { Language } from './pipes/language.pipe';
import { CitiesPipe } from './pipes/cities.pipe';
import { DatePipe } from '@angular/common';
import { PaymentComponent } from './payment/payment.component';
import { ReviewsComponent } from './reviews/reviews.component';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    AppRoutingModule,
    MaterialModule,
    IvyCarouselModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,  
    CommonMatDialogComponent,
    CommonMatInputComponent,
    UserComponent,
  ],
  providers: [DatePipe,ArtistCategoryPipe,CitiesPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
