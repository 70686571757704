import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { eventRoutingModule } from './event-routing.module';
import { EventListComponent } from './event-list/event-list.component';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EditEventsComponent } from './edit-events/edit-events.component';
import { artistModule } from 'app/artists/artist.module';
import { enquiryModule } from 'app/enquiry/enquiry.module';

@NgModule({
    declarations: [
    EventListComponent,
    EventDetailsComponent,
    EditEventsComponent], 
    entryComponents: [

    ],
    imports: [
      CommonModule,
      eventRoutingModule,
      FormsModule,
      MaterialModule,
      artistModule,
      enquiryModule
    ],
   
  })
  
  export class eventModule{}