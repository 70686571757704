import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-add-artist-to-enquiry',
  templateUrl: './add-artist-to-enquiry.component.html',
  styleUrls: ['./add-artist-to-enquiry.component.scss']
})
export class AddArtistToEnquiryComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  addEnquiryForm: FormGroup;
  artist_id: any;
  firstName: any;
  lastName: any;
  gender: any;
  careerAchiev: any;
  artistRequirementList =[
    {
    "id": 0,
    "name": "power_plus",
    "value": "Power Plus",
    "selected": false
    },
    {
    "id": 1,
    "name": "space_for_performance",
    "value": "Space for Performance",
    "selected": false
    },
    {
    "id": 2,
    "name": "3pc_time_for_setup_and_sound_check",
    "value": "3 PC Time for setup and sound check",
    "selected": false
    },
    {
    "id": 3,
    "name": "tech_rider",
    "value": "Tech Rider",
    "selected": false
    },
    {
    "id": 4,
    "name": "sound_setup",
    "value": "Sound Setup",
    "selected": false
    },
    {
    "id": 5,
    "name": "air_fare",
    "value": "Air Fare",
    "selected": false
    },
    {
    "id": 6,
    "name": "outstation_travel_charges",
    "value": "Outstation Travel Charges",
    "selected": false
    },
    {
    "id": 7,
    "name": "pickup_and_drop",
    "value": "Pikcup and Drop",
    "selected": false
    },
    {
    "id": 8,
    "name": "accomodation",
    "value": "Accomodation",
    "selected": false
    },
    {
    "id": 9,
    "name": "food_and_water",
    "value": "Food and Water",
    "selected": false
    },
    {
    "id": 10,
    "name": "table_and_chair",
    "value": "Table and Chair",
    "selected": false
    }
    ];
  quotes_list = [{
    id: 0,
    name: "sound_system",
    value: "Sound System"
    },
    {
    id: 1,
    name: "dj_set",
    value: "DJ Set updated"
    },
    
    {
    id: 2,
    name: "pink_roses_bouquet",
    value: "Pink Roses Bouquet"
    },
    {
    id: 3,
    name: "cake_with_a_personal_card",
    value: "Cake with a Personal Card"
    },
    {
    id: 4,
    name: "house_party_sound_system",
    value: "House Party Sound System"
    },
    {
    id: 5,
    name: "3k_portable_sound",
    value: "3K Portable Sound"
    },
    {
    id: 6,
    name: "8k_big_sound",
    value: "8K Big Sound"
    },
    {
    id: 7,
    name: "cake_with_a_message",
    value: "Cake with a Message"
    },
    {
    id: 8,
    name: "1k_sound_system",
    value: "1K Sound System"
    },
    {
    id: 9,
    name: "4k_sound_system",
    value: "4K Sound System"
    },
    {
    id: 10,
    name: "5k_sound_system",
    value: "5K Sound System"
    },
    {
    id: 11,
    name: "6k_sound_system",
    value: "6K Sound System"
    },
    {
    id: 12,
    name: "7k_sound_system",
    value: "7K Sound System",
    }, {
    id: 13,
    name: "red_roses_bouquet",
    value: "Red Roses Bouquet",
    
    },];
  enquiry_id: any;
  constructor(private _location: Location,
              private commonServices: CommonService,
              private formBuilder: FormBuilder,
              private router: Router,
              private activateRoute : ActivatedRoute,
              private commonService : CommonService,private snackBar: MatSnackBar) {}

  ngOnInit() {
    console.log(this.quotes_list);
    
    this.activateRoute.queryParams.subscribe(param =>{
      this.artist_id = param.live101_id;
      this.enquiry_id = param.enquiry_id
      console.log('check id', this.artist_id)
    })
    this.addEnquiryForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      gender: ["", Validators.required],
      career_achievements: ["", Validators.required],
      budget: ["", Validators.required],
      bid_amount: ["", Validators.required],
      travel_expense: ["", Validators.required],
      artist_management_fee: ["", Validators.required],
      event_date: ["", Validators.required],
      description: ["", Validators.required],
      quotes_included: ["", Validators.required],
      performance_time: ["",Validators.required],
      artist_quote: ["",Validators.required],
      artist_requirements: ["", Validators.required]


    })
    this.getArtistDetails();
  }

  getArtistDetails() {
    console.log('success', this.artist_id);
    this.enableSpinner$.next(true);
    this.commonService.viewDetails(this.artist_id).subscribe(data =>{
      this.enableSpinner$.next(false);
      console.log(data)
      if(data.Response){
        this.firstName=  data.Response.personal_info.first_name;
        this.lastName= data.Response.personal_info.last_name;
        this.gender= data.Response.personal_info.gender;
        this.careerAchiev = data.Response.about.career_achievements;
    
        this.addEnquiryForm.patchValue({
          first_name: this.firstName,
          last_name:  this.lastName,
          gender: this.gender,
          career_achievements: this.careerAchiev
        })
      }

    })
  }
  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  saveForm(){
    const PayLoad={
      "enquiry_id": this.enquiry_id,
      "budget": this.addEnquiryForm.value.budget,
      "qoutes_includes": this.addEnquiryForm.value.quotes_included,
      "bid_amount": this.addEnquiryForm.value.bid_amount,
      "artist_requirements": this.addEnquiryForm.value.artist_requirements,
      // "travel_expense":  this.addEnquiryForm.value.travel_expense,
      // "artist_management_fee":  this.addEnquiryForm.value.artist_management_fee,
      "description": this.addEnquiryForm.value.description,
      "event_date": this.addEnquiryForm.value.event_date ,
      // "is_success": false,
      "artist_id": this.artist_id,
      "artist_quote": this.addEnquiryForm.value.artist_quote,
      "performance_time": this.addEnquiryForm.value.performance_time,
      // "enquiry_id":  this.addEnquiryForm.value.enquiry_id,
      // "enquiry_type":  this.addEnquiryForm.value.enquiry_type,
      // "artist": {
      //   // "avg_rating": this.addEnquiryForm.value.artist.avg_rating,
      //   // "review_count": this.addEnquiryForm.value.artist.avg_rating,
      //   // "artist_name": this.addEnquiryForm.value.artist.artist_name,
      //   // "artist_category": this.addEnquiryForm.value.artist.artist_category,
      //   // "artist_city": this.addEnquiryForm.value.artist.artist_city,
      //   // "image_url": "",
      //   // "artist_id":  this.addEnquiryForm.value.artist.artist_id,
      //   "is_admin_approved": true
      // }
    }
    this.commonServices.addEnquiry(PayLoad).subscribe(
      (response) =>{
      console.log(response.success);
      if(response.success == true){
        this.openSnackbar('Artist added Successfully', 'ok')
        this.router.navigate(['/enquiry']);
      }
    })
  }
  goBack(){
    this._location.back();
  }
}

