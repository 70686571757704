import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PaymentComponent } from './payment.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'payment',
        pathMatch: 'full'
    },
    { path: 'payment', component: PaymentComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PaymentRoutingModule { }