import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { EventListComponent } from './event-list/event-list.component';
import { EventDetailsComponent } from './event-details/event-details.component';
import { EditEventsComponent } from './edit-events/edit-events.component';
import { SelectArtistToEnquiryComponent } from '../enquiry/select-artist-to-enquiry/select-artist-to-enquiry.component';


export const routes: Routes = [
    {
        path: '',
        redirectTo: 'event-List',
        pathMatch: 'full'
    },
    { path: 'event-List', component: EventListComponent },
    { path: 'event-Details', component: EventDetailsComponent },
    { path: 'event-edit', component: EditEventsComponent },
    { path: 'selectArtist', component: SelectArtistToEnquiryComponent },

   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class eventRoutingModule {}