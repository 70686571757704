import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
// import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-package-edit',
  templateUrl: './user-package-edit.component.html',
  styleUrls: ['./user-package-edit.component.scss']
})
export class UserPackageEditComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  editUserPackageForm: FormGroup;
  id: any;
  userName: any;
  email: any;
  contact: any;
  location: any;
  recommendation: any;
  currency: any;
  price: any;
  totalAmount: any;
  message: any;
  addon: any;
  addonsArray: any;
  artist_category: any;
  private _jsonURL = 'assets/artistData.json';

  constructor(private activateRoute: ActivatedRoute,
              private commonService: CommonService,
              private _location: Location,
              private http: HttpClient,
              private router: Router,
              // private snackBar: MatSnackBar,
              private formBuilder: FormBuilder)
               { this.getJSON().subscribe(data => {
                // console.log("json data api" + JSON.stringify(data));
                this.artist_category = data.artist_category;              
               });
              }
              public getJSON(): Observable<any> {
                return this.http.get(this._jsonURL);
              }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(param =>{
      this.id= param.id;
      console.log( this.id)
    })

    this.editUserPackageForm= this.formBuilder.group({
      user_name: ['', Validators.required],
      email: ['', Validators.required],
      contact: ['', Validators.required],
      location: ['', Validators.required],
      recommendation: ['', Validators.required],
      currency: ['', Validators.required],
      price: ['', Validators.required],
      total_amount: ['', Validators.required],
      message: ['', Validators.required],
      addon: ['', Validators.required],
      addons_array: ['', Validators.required],
    })
    this.getUserpackageDetails();
  }

  getUserpackageDetails(){
    const payLoad={
      user_package_id: this.id,
    }
    this.enableSpinner$.next(true);
    this.commonService.userPackageDetails(payLoad).subscribe(data =>{
      this.enableSpinner$.next(false);
      if(data.Response){
      console.log(data)
    this.userName= data.Response.user_package.user_name;
    this.email= data.Response.user_package.email;
    this.contact= data.Response.user_package.contact;
    this.location= data.Response.user_package.contact;
    this.recommendation= data.Response.user_package.recommendation;
    this.currency= data.Response.user_package.currency;
    this.price= data.Response.user_package.price;
    this.totalAmount= data.Response.user_package.total_amount;
    this.message= data.Response.user_package.message;
    this.addon= data.Response.user_package.addon;
    this.addonsArray= data.Response.user_package.addonsArray;

    this.editUserPackageForm.patchValue({
      user_name: this.userName,
      email: this.email,
      contact: this.contact,
      location: this.location,
      recommendation: this.recommendation,
      currency: this.currency,
      price: this.price,
      total_amount: this.totalAmount,
      message: this.message,
      addon: this.addon,
      addons_array: this.addonsArray
    })
  }
    })
  }

  saveForm(){
    console.log(this.editUserPackageForm.value);
      const payLoad = {
        user_name: this.editUserPackageForm.value.user_name,
        // user_id: "1",
        email: this.editUserPackageForm.value.email,
        contact: this.editUserPackageForm.value.contact,
        message: this.editUserPackageForm.value.message,
        // transaction_details:{
          // transaction_id:{type:String},
        // amount:{type:Number},
        // currency:{type:String}},
        // addons_array: [{
          // name: {type: String}, 
          // id:{type: String}, 
          // price: {type: Number},
          //  description:    {type: String}}],//selected addon from master package
        location: this.editUserPackageForm.value.location,
        recommendation: this.editUserPackageForm.value.recommendation,
        price: this.editUserPackageForm.value.price,
        currency: this.editUserPackageForm.value.currency,
        total_amount: this.editUserPackageForm.value.total_amount,
      }
  console.log(payLoad)
  this.enableSpinner$.next(true);
    this.commonService.editUserPackage(this.id,payLoad).subscribe(response =>{
      this.enableSpinner$.next(false);
      console.log(response)
      if (response.success == true) {
        // this.openSnackbar('Package Saved Successfully', 'ok')
      }
      else {
        // this.openSnackbar('Error Occured', 'ok')
      }
    })
  }
  // openSnackbar(message: string, action: string) {
  //   this.snackBar.open(message, action, {
  //     duration: 2000,
  //   });
  // }
  goBack(){
    this._location.back();
  }
}
