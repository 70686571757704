

import { Component, OnInit, ViewChild} from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

interface Select {
  value: string;
  viewValue: string;
}

export interface UserData {
  accountId: string;
  name: string;
  image: string;
  travelCity: string;
  ArtistCategory: string;
  GenreCategory: string;
  EventCategory: string;
  InstrumentsCategory: string;
  Actions: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  eventList: any =[];
  artistSearchList: any =[];
  currentPage:number = 1;
  successResult:any = [];
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  id: any;
  selection: Select[] = [
    {value: 'view', viewValue: 'View'},
    // {value: 'edit', viewValue: 'Edit'},
    // {value: 'delete', viewValue: 'Delete'}
  ];
  selectedValue: string;

  constructor( private commonService: CommonService,
               private router:Router) {}

  ngOnInit() {
 
    this.tableData1 = {
      headerRow: [ 'Event ID', 'Name', 'Contact No','Email', 'Location', 'Artist Category', 'Event Category', 'Genre Category', 'Actions'],
  };
    this.getEventList("upcoming");
  }

  applyFilter(event){
    console.log(event.value);
    this.nextPage =1;
    this.eventList=[];
    if(event.value === "complete"){
      let payload = {
        "payment_status":"complete"
      }
      this.getEventList(payload);
    }else{

      this.getEventList();
    }

  }


  getEventList(payload?){
    this.currentPage =1;
    this.enableSpinner$.next(true);
    console.log(payload);
    this.commonService.getEvents(this.nextPage,'','', payload).subscribe(data =>{
      this.enableSpinner$.next(false);
      this.totalPage= data.Response.count;
      this.nextPage +=1;
      console.log(this.totalPage)
      this.dataReceived= data.Response.result;

      data.Response.result.forEach(element => {
        this.eventList.push(element)
      })
    })
  }

  selectChange(event , id){
    console.log(event.value);
    console.log(id);
    this.selectedValue= event.value;
    if(this.selectedValue === 'view'){
      this.router.navigate(["/event", "event-Details"], { queryParams: { id: id }});
    } else if(this.selectedValue === 'edit'){
      this.router.navigate(["/event", "event-edit"], { queryParams: { id: id }});
    } else {
      console.log( 'delete')
    }
  }

  // viewDetails(id){
  //   console.log(id);
  //   this.router.navigate(["/event", "event-Details"], { queryParams: { id: id }});

  // }
  // editArtist(id){
  //   console.log(id);
  //   this.router.navigate(["/event", "event-edit"], { queryParams: { id: id }});
  // }

  viewMoreArtists(){
    this.getEventList();
  }

  //search
  search(event){
    this.searchResult= event.target.value;
    setTimeout (() => {
      this.currentPage = 1;
      this.searchApiCal();
   }, 1500);
        
        }

searchApiCal(){
  if(this.searchResult.length >= 3 ){
    this.eventList=[];
    this.nextPage = 1;
      const payload={
          first_name: true,
          artist: this.searchResult
      }
      this.enableSpinner$.next(true);
      this.commonService.search(payload,this.currentPage).subscribe(success =>{
        this.enableSpinner$.next(false);
      this.currentPage +=1;
      this.successResult= success.Response.result;
      success.Response.result.forEach(el => {
        this.eventList.push(el);
        console.log(this.eventList);
})
      });
  }else if(this.searchResult.length < 1){  
    this.nextPage = 1;
    this.eventList=[];
     this.getEventList();
  }
}

  viewMoreSearch(){
    const payload={
      first_name: true,
      artist: this.searchResult
  }
  this.enableSpinner$.next(true);
    this.commonService.search(payload,this.currentPage).subscribe(success =>{
      this.enableSpinner$.next(false);
      this.currentPage +=1;
      this.successResult= success.Response.result;
      success.Response.result.forEach(el => {
        this.eventList.push(el);
})
      });
  }
  
}



