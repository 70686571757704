import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { artistRoutingModule } from './artist-routing.module';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { ArtistListComponent } from './artist-list/artist-list.component';
import { AddArtistComponent } from './add-artist/add-artist.component';
import { EditArtistComponent } from './edit-artist/edit-artist.component'
import { ViewArtistDetailComponent } from './view-artist-detail/view-artist-detail.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ArtistCategoryPipe } from 'app/pipes/artist.pipe';
import { CitiesPipe } from 'app/pipes/cities.pipe';
import { EventPipe } from 'app/pipes/event.pipe';
import { Genre } from 'app/pipes/genre.pipe';
import { Instrument } from 'app/pipes/instrument.pipe';
import { Language } from 'app/pipes/language.pipe';
import { ImageModalComponent } from 'app/image-modal/image-modal.component';
import { YoutubeModalComponent } from 'app/youtube-modal/youtube-modal.component';
import { SafePipe } from 'app/pipes/SafePipe.pipe';
import { ViewReviewComponent } from './view-review/view-review.component';

@NgModule({
    declarations: [
      ArtistListComponent,
      AddArtistComponent,
      EditArtistComponent,
      CitiesPipe,
      ArtistCategoryPipe,
      Genre,
      Instrument,
      Language,
      EventPipe,
      ViewArtistDetailComponent,
      SafePipe,
    ImageModalComponent,
  YoutubeModalComponent,
  ViewReviewComponent],
    entryComponents: [

      ImageModalComponent, YoutubeModalComponent
    ],
    imports: [
      CommonModule,
      artistRoutingModule,
      FormsModule,
      MaterialModule,YouTubePlayerModule, IvyCarouselModule
    ],
    exports:[CitiesPipe,
      ArtistCategoryPipe,
      Genre,
      Instrument,
      Language,
      EventPipe]
   
  })
  
  export class artistModule{}