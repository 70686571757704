import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

interface Select {
  value: string;
  viewValue: string;
}

export interface UserData {
  accountId: string;
  name: string;
  image: string;
  travelCity: string;
  ArtistCategory: string;
  GenreCategory: string;
  EventCategory: string;
  InstrumentsCategory: string;
  Actions: string;

}
@Component({
  selector: 'app-new-artist-registered',
  templateUrl: './new-artist-registered.component.html',
  styleUrls: ['./new-artist-registered.component.scss']
})
export class NewArtistRegisteredComponent implements OnInit {
  // p: number = 1;
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  artistList: any = [];
  artistSearchList: any = [];
  currentPage: number = 1;
  successResult: any = [];
  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  loaded = false;
  payload: any;
  private _jsonURL = 'assets/artistData.json';
  private citiUrl = 'assets/travelCities.json';
  private artistCategory_json = 'assets/artist_categories.json';
  filter_list = [
    { value: 'artist_name', label: 'Artist Name' },
    { value: 'mobile', label: 'Mobile' },
    { value: 'email', label: 'Email' },
    { value: 'live101_id', label: 'Live101 Id' },
    { value: 'city', label: 'City' },
    { value: 'artist_category', label: 'Artist Category' },
    { value: 'event_category', label: 'Event Category' },
    { value: 'gender', label: 'Gender' },


  ];

  selection: Select[];
  selectedValue: string;
  filterValue: any;
  showSearch: boolean = false;
  artistJsonData: any;
  artist_category_list: any;
  event_category_list: any;
  instrument_category_list: any;
  filterCategory: any;
  eventFilter: boolean = false;
  artistFilter: boolean = false;
  newStartDate: Date;
  newEndDate: Date;
  start_date: string;
  end_date: string;
  totalResults: any;

  constructor(private commonService: CommonService, private router: Router, private snackBar: MatSnackBar, private http: HttpClient, private datePipe: DatePipe) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.tableData1 = {
      headerRow: ['Artist ID', 'Name', 'Images', 'City', 'Artist Category', 'Genre Category', 'Mobile No.', 'Email', 'Actions'],
    };
    this.newStartDate = new Date();
    this.newStartDate.setMonth(this.newStartDate.getMonth() - 3);
    this.newEndDate = new Date();
    this.start_date = this.datePipe.transform(this.newStartDate, 'yyyy-MM-dd');
    this.end_date = this.datePipe.transform(this.newEndDate, 'yyyy-MM-dd');
    this.getArtistList();
    if (localStorage.getItem('username') == 'admin') {
      this.selection = [
        { value: 'view', viewValue: 'View' },

      ];
    } else {
      this.selection = [
        { value: 'view', viewValue: 'View' }
      ];
    }

    this.getJSON(this.artistCategory_json).subscribe(data => {
      this.artistJsonData = data.artist_categories;
      this.artist_category_list = data.artist_categories;
    })
    this.getJSON(this._jsonURL).subscribe(data => {
      this.event_category_list = data.event_category;
    });
  }


  public getJSON(jsonUrl): Observable<any> {
    return this.http.get(jsonUrl);
  }

  onDate(date) {
    this.start_date = this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  onEndDate(date) {
    this.end_date = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.nextPage = 1;
    this.artistList = [];
    this.getArtistList();
  }

  getArtistList() {
    this.currentPage = 1;
    this.enableSpinner$.next(true);
    this.commonService.getNewArtist(this.nextPage, this.start_date, this.end_date).subscribe(data => {
      this.enableSpinner$.next(false);
      this.totalResults = data.Response.count;
      this.totalPage = data.Response.count;
      this.nextPage += 1;
      this.dataReceived = data.Response.result;
      data.Response.result.forEach(element => {
        if (!element.is_deleted) {
          this.artistList.push(element)
        }
      })
      this.loaded = true;
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');

    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }

  selectChange(event, id) {
    this.selectedValue = event.value;
    if (this.selectedValue === 'view') {
      this.router.navigate(["/artists", "viewDetail"], { queryParams: { artist_id: id } });
    } else if (this.selectedValue === 'edit') {
      this.router.navigate(["/artists", "editArtist"], { queryParams: { artist_id: id } });
    } else if (this.selectedValue === 'delete') {
      this.commonService.saveArtistInfo(id, { is_deleted: true }).subscribe(response => {
        if (response.success == true) {
          this.openSnackbar('Artist Profile Deleted Successfully', 'ok')
          this.artistList = this.artistList.filter(item => item.artist_id !== id);
        }
        else {
          this.openSnackbar('Error Occured', 'ok');
        }
      }, err => {
        console.log(err);
        this.openSnackbar('Error Occured', 'ok');

      })
    }
  }

  viewDetails(artist_id) {
    this.router.navigate(["/artists", "viewDetail"], { queryParams: { artist_id: artist_id } });

  }
  editArtist(artist_id) {
    this.router.navigate(["/artists", "editArtist"], { queryParams: { artist_id: artist_id } });
  }

  viewMoreArtists() {
    this.getArtistList();
  }

  //search
  search(event) {
    this.searchResult = event.target.value;
    setTimeout(() => {
      this.currentPage = 1;
      this.searchApiCal();
    }, 500);
  }

  searchApiCal() {
    if (this.searchResult.length >= 3) {
      this.artistList = [];
      this.nextPage = 1;
      if (this.filterValue === "artist_name") {
        this.payload = {
          first_name: true,
          artist: this.searchResult
        }
      } else if (this.filterValue === "mobile") {
        this.payload = {
          first_name: true,
          mobile: this.searchResult
        }
      } else if (this.filterValue === "email") {
        this.payload = {
          first_name: true,
          email: this.searchResult
        }
      } else if (this.filterValue === "city") {
        this.payload = {
          first_name: true,
          city: this.searchResult
        }
      } else if (this.filterValue === "live101_id") {
        this.payload = {
          first_name: true,
          live101_id: this.searchResult
        }
      } else if (this.filterValue === "artist_category") {
        this.payload = {
          first_name: true,
          artist_category: this.searchResult
        }
      } else if (this.filterValue === "event_category") {
        this.payload = {
          first_name: true,
          event_category: this.searchResult
        }
      } else if (this.filterValue === "gender") {
        this.payload = {
          first_name: true,
          gender: this.searchResult
        }
      }
      this.commonService.search(this.payload, this.currentPage).subscribe(success => {
        if (success.Response) {
          this.currentPage += 1;
          this.successResult = success.Response.result;
          success.Response.result.forEach(el => {
            if (!el.is_deleted) {
              this.artistList.push(el);
            }

          })
        } else {
          console.log("error")
        }

      });
    } else if (this.searchResult.length < 1) {
      this.nextPage = 1;
      this.artistList = [];
      this.getArtistList();
    }
  }

  onFilterCatSelect(event) {
    this.searchResult = event.value;
  }

  applyFilter() {
    this.searchApiCal();
  }

  onFilterSelect(event) {
    this.filterValue = event.value;
    if (this.filterValue === "artist_name" || this.filterValue === "mobile" || this.filterValue === "live101_id" || this.filterValue === "email") {
      this.showSearch = true;
      this.eventFilter = false;
      this.artistFilter = false;


    }
    else if (this.filterValue === "artist_category") {
      this.showSearch = false;
      this.eventFilter = false;
      this.artistFilter = true;
      this.filterCategory = this.artist_category_list;
    } else if (this.filterValue === "event_category") {
      this.showSearch = false;
      this.eventFilter = true;
      this.artistFilter = false;
      this.filterCategory = this.event_category_list;
    } else if (this.filterValue === "city") {
      this.showSearch = false;
      this.eventFilter = false;
      this.artistFilter = true;
      this.getJSON(this.citiUrl).subscribe(data => {
        this.filterCategory = data.cities;
      })
    } else if (this.filterValue === "gender") {
      this.showSearch = false;
      this.eventFilter = false;
      this.artistFilter = true;
      this.filterCategory = [{ value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },];
    }

  }

  viewMoreSearch() {
    // this.searchApiCal();
    this.commonService.search(this.payload, this.currentPage).subscribe(success => {
      this.currentPage += 1;
      this.successResult = success.Response;
      success.Response.result.forEach(el => {
        this.artistList.push(el);
      })
    });
  }
  addArtist() {
    this.router.navigate(["/artists", "addArtist"]);
  }
}


