import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  accountId: any;
  accountDetails: any;

  constructor(private activateRouter: ActivatedRoute,
    private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.accountId= param.id;
    });
    this.getAccountDetails();
  }
  editAccount(){
    this.router.navigate(["/account", "account-edit"], { queryParams: { id: this.accountId }});
  }

  getAccountDetails(){
    this.commonService.getAccountsDetails(this.accountId).subscribe(data => {
      this.accountDetails =JSON.parse(JSON.stringify(data.Response));
    })
  }
}
