import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { OfferPackageListComponent } from './offer-package-list/offer-package-list.component';
import { EditPackageComponent } from './edit-package/edit-package.component';
import { OfferPackageDetailsComponent } from './offer-package-details/offer-package-details.component';
import { OfferPackageAddComponent } from './offer-package-add/offer-package-add.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'offerPackage-list',
        pathMatch: 'full'
    },
    { path: 'offerPackage-list', component: OfferPackageListComponent },
    { path: 'offerPackage-edit', component: EditPackageComponent },
    { path: 'offerPackage-details', component: OfferPackageDetailsComponent },
    { path: 'offerPackage-add', component: OfferPackageAddComponent },
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class offerPackageRoutingModule {}