import { Routes } from '@angular/router';
import { PaymentComponent } from 'app/payment/payment.component';

import { HomeComponent } from '../../home/home.component';
import { ReviewsComponent } from 'app/reviews/reviews.component';
import { ReviewsModule } from 'app/reviews/reviews.module';

export const AdminLayoutRoutes: Routes = [
    { path: 'dashboard', component: HomeComponent },
    {
      path: 'artists',
      loadChildren: () => import('app/artists/artist.module').then(m => m.artistModule)
    },
    {
      path: 'artist-sourcing',
      loadChildren: () => import('app/artist-sourcing/artist-sourcing.module').then(m => m.ArtistSourcingModule)
    },
    {
      path: 'new-artists-registered',
      loadChildren: () => import('app/new-artist-registered/new-artist-registered.module').then(m => m.NewArtistRegisteredModule  )
    },
    { path: 'payment',
  loadChildren:()=> import('app/payment/payment.module').then(m => m.PaymentModule) },

    {
      path: 'enquiry',
      loadChildren: () => import('app/enquiry/enquiry.module').then(m => m.enquiryModule)
    },
    {
      path: 'business-enquiry',
      loadChildren: () => import('app/business-enquiry/business-enquiry.module').then(m => m.BusinessEnquiryModule)
    },{
      path: 'business-events',
      loadChildren: () => import('app/business-events/business-events.module').then(m => m.BusinessEventModule)
    },
    {
      path: 'account',
      loadChildren: () => import('app/account/account.module').then(m => m.AccountModule)
    },
    {
      path: 'package',
      loadChildren: () => import('app/packages/package.module').then(m => m.packageModule)
    },
    {
      path: 'reviews',
      loadChildren: () => import('app/reviews/reviews.module').then(m => m.ReviewsModule)
    },
    {
      path: 'event',
      loadChildren: () => import('app/event/event.module').then(m => m.eventModule)
    },
    {
      path: 'chat',
      loadChildren: () => import('app/chat/chat.module').then(m => m.chatModule)  
    },
    {
      path: 'userPackage',
      loadChildren: () => import('app/userPackage/userPackage.module').then(m => m.userPackageModule)  
    },
    {
      path: 'offerPackage',
      loadChildren: () => import('app/offerPackage/offerPackage.module').then(m => m.offerPackageModule)  
    },
    {
      path: 'suprise',
      loadChildren: () => import('app/supriseentertainer/supriseentertainer.module').then(m => m.supriseModule)  
    },

    // loadChildren: 'app/en/add.module#AddModule'
    // {
    //     path: 'enquiry',
    //     loadChildren: () => import('../../enquiry/enquiry.module').then(m => m.enquiryModule)
    //   },
    //   {
    //     path: 'package',
    //     loadChildren: () => import('../../packages/package.module').then(m => m.packageModule)
    //   },
    //   {
    //     path: 'event',
    //     loadChildren: () => import('../../event/event.module').then(m => m.eventModule)
    //   },
    //   {
    //     path: 'chat',
    //     loadChildren: () => import('../../chat/chat.module').then(m => m.chatModule)
    //   },
];