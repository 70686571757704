import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ArtistCategoryPipe } from 'app/pipes/artist.pipe';
import { CitiesPipe } from 'app/pipes/cities.pipe';
import { EventPipe } from 'app/pipes/event.pipe';
import { Genre } from 'app/pipes/genre.pipe';
import { Instrument } from 'app/pipes/instrument.pipe';
import { Language } from 'app/pipes/language.pipe';
import { ImageModalComponent } from 'app/image-modal/image-modal.component';
import { YoutubeModalComponent } from 'app/youtube-modal/youtube-modal.component';
import { SafePipe } from 'app/pipes/SafePipe.pipe';
import { BusinessEventsComponent } from './business-events.component';
import { BusinessEventsRoutingModule } from './businessEvents-routing';
import { artistModule } from 'app/artists/artist.module';

@NgModule({
    declarations: [
      BusinessEventsComponent],
    entryComponents: [

      ImageModalComponent, YoutubeModalComponent
    ],
    imports: [
      CommonModule,
      BusinessEventsRoutingModule,
      artistModule,
      FormsModule,
      MaterialModule,YouTubePlayerModule, IvyCarouselModule
    ],
    exports:[
      Genre,
      Instrument,
      Language,
      EventPipe]
   
  })
  
  export class BusinessEventModule{}