import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-edit-package',
  templateUrl: './edit-package.component.html',
  styleUrls: ['./edit-package.component.scss']
})
export class EditPackageComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  
  add_ons: FormArray;
  items_provided: FormArray;
  add_onData: FormGroup;
  private artistCategory_json = 'assets/artist_categories.json'

  artistJsonData: any;
  artist_category_list: any;
  editBasicPackage: FormGroup;
  itemProvidedForm : FormGroup;
  addOnsForm: FormGroup;
  liveId: any;
  packageName: any;
  price: any;
  description: any;
  imagePotrait: any;
  imageLandScape: any;
  image_url: any;
  profile_image_url_Potrait: any | undefined;
  profile_image_url_Landscape: any | undefined;
  fileData: File = null;
  fileDatas: File = null;
  uploadedUrlPotrait: any;
  uploadedUrlLandScape: any;
  url_Potrait: any;
  url_LandScape: any;
  discountPrice: any;
  currency: any;
  title: any;
  items: any =[];
  addOns: any = [];
  performanceTime: any;
  duration: any;
  constructor(private formBuilder: FormBuilder,
    private activateRouter: ActivatedRoute,
    private _location: Location,
    private snackBar: MatSnackBar,
    private commonService: CommonService, private http: HttpClient ) {

      this.getJSON(this.artistCategory_json).subscribe(data => {
        console.log(data);
        this.artistJsonData = data.artist_categories;
        this.artist_category_list = data.artist_categories;
  
        
      })

  }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.liveId = param.package_id;
    });
    console.log(this.liveId)

    this.editBasicPackage = this.formBuilder.group({
      package_name: new FormControl('', [Validators.required]),
      package_price: new FormControl('', [Validators.required]),
      package_description: new FormControl('', [Validators.required]),
      discount_price : new FormControl('', [Validators.required]),
      currency : new FormControl('', [Validators.required]),
      duration : new FormControl('', [Validators.required]),
      performance_time : new FormControl('', [Validators.required]),
            // items_provided
            items_provided: this.formBuilder.array([ this.createItemProvided() ]),
            // artist_category: ["", Validators.required],
            // item_detail_display: ["", Validators.required],
            // artist_subcategory: ["", Validators.required],
            // genre_category: ["", Validators.required],
            // event_category: ["", Validators.required],
            // quantity: ["", Validators.required],
            // add_ons
            add_ons: this.formBuilder.array([ this.createItem() ])
    })
    this.getPackageDetails();
  }

  createItemProvided(): FormGroup {
    return this.itemProvidedForm = this.formBuilder.group({
      artist_category: ["", Validators.required],
            item_detail_display: ["", Validators.required],
    });
  }

  createItem(): FormGroup {
    return this.addOnsForm = this.formBuilder.group({
      price: ["", Validators.required],
      name: ["", Validators.required],
      description: ["", Validators.required]
    });
  }

  addNewAddons(): void {
    this.add_ons = this.editBasicPackage.get('add_ons') as FormArray;
    this.add_ons.push(this.createItem());
  }

  addNewItemProvided(): void {
    this.items_provided = this.editBasicPackage.get('items_provided') as FormArray;
    this.items_provided.push(this.createItemProvided());
  }

  public getJSON(jsonUrl): Observable<any> {
    return this.http.get(jsonUrl);
  }

  getPackageDetails() {
    this.enableSpinner$.next(true);
    this.commonService.packageDetails(this.liveId).subscribe(data => {
      this.enableSpinner$.next(false);
      console.log(data.Response);
      if (data.Response) {
        this.imagePotrait = data.Response.potrait_image_url;
        this.imageLandScape = data.Response.landscape_image_url;
        this.packageName = data.Response.package_name;
        this.price = data.Response.price;
        this.discountPrice = data.Response.discount_price;
        this.description = data.Response.description;
        this.currency = data.Response.currency;
        this.duration = data.Response.duration;
        this.performanceTime = data.Response.performance_time;
        this.items = data.Response.items_provided;
        this.addOns = data.Response.add_ons;
        this.items.map(item => {
          console.log(item);
          this.itemProvidedForm.patchValue({
            artist_category: item.artist_category[0],
            item_detail_display : item.item_detail_display
          })
          this.addNewItemProvided();
        });

        this.addOns.map(item => {
          this.addOnsForm.patchValue({
            price: item.price,
            name: item.name,
            description: item.description
          })
          this.addNewAddons();
        });

        console.log(this.packageName, '+', this.price, '+', this.description)

        this.editBasicPackage.patchValue({
          package_name: this.packageName,
          package_price: this.price,
          package_description: this.description,
          discount_price:  this.discountPrice,
          currency : this.currency,
          duration :  this.duration,
          performance_time :  this.performanceTime,
        })
      }
    });
  }

  uploadImagePotrait(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.previewPotrait();
  }
  previewPotrait() {
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var readers = new FileReader();
    readers.readAsDataURL(this.fileData);
    readers.onload = (_event) => {
      this.image_url = readers.result;
    }
    this.enableSpinner$.next(true);
    this.commonService.uploadImage(this.fileData).subscribe(response => {
      this.enableSpinner$.next(false);
      this.profile_image_url_Potrait = response["Data"];
      this.commonService.imgurl = this.profile_image_url_Potrait[0];
      this.uploadedUrlPotrait = this.profile_image_url_Potrait[0];
    })
  }

  uploadImageLandScape(fileInput: any) {
    this.fileDatas = <File>fileInput.target.files[0];
    this.previewLandScape();
  }
  previewLandScape() {
    var mimeType = this.fileDatas.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(this.fileDatas);
    reader.onload = (_event) => {
      this.image_url = reader.result;
    }
    this.enableSpinner$.next(true);
    this.commonService.uploadImage(this.fileDatas).subscribe(response => {
      this.enableSpinner$.next(false);
      this.profile_image_url_Landscape = response["Data"];
      this.commonService.imgurl = this.profile_image_url_Landscape[0];
      this.uploadedUrlLandScape = this.profile_image_url_Landscape[0];
    })
  }

  saveForm() {
    console.log("value is", this.profile_image_url_Potrait)
    if (this.profile_image_url_Potrait === undefined || this.profile_image_url_Landscape === undefined) {
      console.log("if");
      this.url_Potrait = this.imagePotrait;
      this.url_LandScape = this.imageLandScape;
    } else {
      console.log("else");
      this.url_Potrait = this.profile_image_url_Potrait[0];
      this.url_LandScape = this.profile_image_url_Landscape[0];
    }

    const payLoad = {
      "package_name": this.editBasicPackage.value.package_name,
      "description": this.editBasicPackage.value.package_description,
      // "code":"kid_bday",
      "price": this.editBasicPackage.value.package_price,
      "discount_price":this.editBasicPackage.value.discount_price,
      "currency": this.editBasicPackage.value.currency,
      "landscape_image_url": this.url_LandScape,
      "potrait_image_url": this.url_Potrait,
      "performance_time": this.editBasicPackage.value.performance_time,
      "duration": this.editBasicPackage.value.duration,
      "items_provided": this.editBasicPackage.value.items_provided,
      "add_ons":this.editBasicPackage.value.add_ons
     
      // "items_provided":[ 
      //   {  "artist_category" : ["singer"],
      //      "artist_subcategory": ["sufi"],
      //      "genre_category": ["classic"],
      //      "quantity": 1,
      //      "event_category": ["wedding", "birthday"],
      //  }],
    
      //      "add_ons":[{"id": '', 
      //                   "price": this.addBasicPackage.value.price,
      //                   "name": this.addBasicPackage.value.name,
      //                   "description": this.addBasicPackage.value.description
      //                 }]
    }

    this.enableSpinner$.next(true);
    this.commonService.editPackage(this.liveId, payLoad).subscribe(response => {
      this.enableSpinner$.next(false);
      console.log(response)
      if (response.success == true) {
        this._location.back();
        this.openSnackbar('Package Saved Successfully', 'ok')
      }
      else {
        this.openSnackbar('Error Occured', 'ok')
      }
    })
  }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
  goBack() {
    this._location.back();
  }
}
