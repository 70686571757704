import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-send-quotation',
  templateUrl: './send-quotation.component.html',
  styleUrls: ['./send-quotation.component.scss']
})
export class SendQuotationComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  liveId: any;
  enquiryData: any;
  quoteSelected: any;
  allSelectedQuotes: any = [];
  requirementSelected: any;
  allRequirementsSelected: any = [];
  artist_id: any;
  errorMessage: boolean = false;
  performaceTime: any;
  quote: any;
  comment: any;
  live101_platform_fee: any;
  gst_platform_fee: any;
  performanceTime: any;
  artistSelected: any;
  addOns = [{
    id: 0,
    name: "sound_system",
    value: "Sound System"
  },
  {
    id: 1,
    name: "dj_set",
    value: "DJ Set"
  },

  {
    id: 2,
    name: "pink_roses_bouquet",
    value: "Pink Roses Bouquet"
  },
  {
    id: 3,
    name: "cake_with_a_personal_card",
    value: "Cake with a Personal Card"
  },
  {
    id: 4,
    name: "house_party_sound_system",
    value: "House Party Sound System"
  },
  {
    id: 5,
    name: "outstation_travel",
    value: "Outstation Travel"
  },
  {
    id: 6,
    // name: "lights_setup",
    name: "light_setup_quote",
    value: "Lights Setup"
  },
  {
    id: 7,
    name: "cake_with_a_message",
    value: "Cake with a Message"
  },
  {
    id: 8,
    name: "red_roses_bouquet",
    value: "Red Roses Bouquet",

  },
  {
    id: 9,
    name: "food_and_water",
    value: "Food And Water",

  },
  {
    id: 10,
    name: "stay_and_lodging",
    value: "Stay And Lodging",

  },
  {
    id: 11,
    name: "tech_rider_quote",
    value: "Tech Rider",

  },
  ]
  fixedAddon = [
    {
      id: 0,
      name: "artist_fees",
      value: "Artist Fees"
    },

    {
      id: 2,
      name: "local_travel_cost",
      value: "Local Travel Cost"
    },
    {
      id: 3,
      name: "live_tracking",
      value: "Live Tracking"
    },
    {
      id: 4,
      name: "artist_backup",
      value: "Artist Backup"
    },
  ]
  requirements = [
    {
      "id": 2,
      "name": "power_plug_and_electricity",
      "value": "Power Plug And Electricity",
      "selected": false
    },
    {
      "id": 3,
      "name": "tech_rider_requirement",
      "value": "Tech Rider",
      "selected": false
    },
    {
      "id": 4,
      "name": "sound_setup",
      "value": "Sound Setup",
      "selected": false
    },
    {
      "id": 5,
      "name": "air_fare",
      "value": "Air Fare",
      "selected": false
    },
    {
      "id": 6,
      "name": "outstation_travel_charges",
      "value": "Outstation Travel Charges",
      "selected": false
    },
    {
      "id": 8,
      "name": "stay_and_lodging",
      "value": "Stay and lodging",
      "selected": false
    },
    {
      "id": 9,
      "name": "light_setup_requirement",
      "value": "Light Setup",
      "selected": false
    },
  ]
  fixedAddonArtistRequirements = [
    {

      "name": "space_for_performance",
      "value": "Space for Performance",

    }]
  performanceTimeValues = [
    {
      name: 'Virtual Performace',
      value: '30'
    },
    {
      name: '1 Hour Performace',
      value: '60'
    },
    {
      name: '1 and half hour',
      value: '90'
    },
    {
      name: '2 Hour Performace',
      value: '120'
    },
    {
      name: '3 Hour Performace',
      value: '180'
    },
    {
      name: '4 Hour Performace',
      value: '240'
    }
  ]
  constructor(private activateRouter: ActivatedRoute, private commonService: CommonService,private _location: Location, private snackBar: MatSnackBar, private router: Router ) { }

  ngOnInit() {
    this.activateRouter.queryParams.subscribe(param => {
      this.liveId = param.id;
    });
    this.getEnquiryDetails();
  }
  onFilterSelectPerformanceTime(event) {
    this.performanceTime = event.value;
  }

  getEnquiryDetails() {
    const data = {
      enquiry_id: this.liveId
    }
    this.enableSpinner$.next(true);
    this.commonService.enquiryDetails(data).subscribe(data => {
      this.enableSpinner$.next(false);
      this.enquiryData = data.Response[0];
      // console.log("this.enquiryData", this.enquiryData)
    });
  }

  onFilterSelectQuote(event) {
    this.allSelectedQuotes = [];
    event.value.forEach(
      eachValue => {
        const rec = this.addOns.find(eachAddOn => { return eachAddOn.name === eachValue; });
        this.allSelectedQuotes.push({
          "name": rec.name,
          "value": rec.value
        })
      }
    )
  }

  onFilterSelectRequirement(event) {
    this.allRequirementsSelected = [];
    event.value.forEach(
      eachValue => {
        const rec = this.requirements.find(eachRequiremnt => { return eachRequiremnt.name === eachValue; });
        this.allRequirementsSelected.push({
          "name": rec.name,
          "value": rec.value
        })
      }
    )
  }

  onFilterSelectArtist(event) {
    this.artist_id = event.value;
  }
  sendQuotation() {
    const isAllFieldsFilled = this.checkAllFields();
    if (isAllFieldsFilled) {
      this.fixedAddon.forEach(
        eachFixedOn =>{
          this.allSelectedQuotes.push(
            {
              name: eachFixedOn.name,
              value: eachFixedOn.value
            }
          )
        }
      );
      this.fixedAddonArtistRequirements.forEach(
        eachFixedOnRequirement => {
          this.allRequirementsSelected.push(
            {
              name: eachFixedOnRequirement.name,
              value: eachFixedOnRequirement.value
            }
          )
        }
      );
      this.errorMessage = false;
      this.live101_platform_fee = (this.quote * 20) / 100;
      this.gst_platform_fee = (this.live101_platform_fee * 18) /100;
      const payload = {
        "enquiry_id": this.liveId,
        "qoutes_includes": this.allSelectedQuotes,
        "artist_requirements": this.allRequirementsSelected,
        "performance_time": this.performanceTime,
        "artist_quote": Number(this.quote),
        "gstin": "",
        "live101_platform_fee": this.live101_platform_fee,
        "gst_platform_fee": this.gst_platform_fee,
        "gst": 0,
        "artist_earning": this.quote - this.live101_platform_fee - this.gst_platform_fee,
        "quote_to_client": Number(this.quote),
        "description": this.comment,
        "bid_amount": Number(this.quote),
        "enquiry_type": this.enquiryData.enquiry_type,
        "artist_id": this.artist_id,
        "client_budget": {
          "min": this.enquiryData.budget.min,
          "max": this.enquiryData.budget.max
        },
        "event_date": this.enquiryData.event_date,
      }
      this.commonService.sendQuotation(payload)
      .subscribe((response)=>{
        if (response.success == true) {
          this.openSnackbar('Quotation send Successfully', 'ok');
          this.router.navigate(["/enquiry", "enquiry-list"]);
        }
        else {
          this.openSnackbar('Error Occured', 'ok');
        }
      },
      (error)=>{
        if(error.error.Error === 'no artist can apply more then once') {
          this.openSnackbar(`${error.error.message}, ${error.error.Error}`, 'ok');
        } else if(error.error.Error === 'artist not approved') {
          this.openSnackbar(`${error.error.message}`, 'ok');
        }
      })
      
    } else {
      this.errorMessage = true;
    }
  }
  checkAllFields() {
    return true;
  }
  
  goBack() {
    this.router.navigate(["/enquiry", "enquiry-list"]);
  }
  
  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
