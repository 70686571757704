import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';

@Component({
    // moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    private toggleButton: any;
    private sidebarVisible: boolean;
    currentPage:number = 1;
    searchbox= false;
    searchForm: FormGroup;
    searchResult: any;
    successResult:any = [];
    artistDetails: any;
    nextPage: number = 1;
    artistList: any =[];
    dataReceived: any;
    totalPage: any

    constructor(location: Location,
                private element: ElementRef,
                private formBuilder: FormBuilder,
                private commonService: CommonService,
                private router: Router) {
      this.location = location;
          this.sidebarVisible = false;
    }


    ngOnInit(){
        // this.searchForm = this.formBuilder.group({
        //     search: ["", Validators.required]
        //   });

      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
    //   console.log(titlee)
        // if(titlee === '/dashboard' || titlee === '/artist'){
        //     this.hideHeader= 
        // }
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
          
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    logOut(){
        localStorage.clear();
        this.router.navigate(['/login']);
    }
}
