import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/shared/materialModule/material.module';
import { supriseRoutingModule } from './supriseentertainer-routing.module';
import { SupriseListComponent } from './suprise-list/suprise-list.component';


@NgModule({
    declarations: [

    SupriseListComponent], 
    entryComponents: [

    ],
    imports: [
      CommonModule,
      supriseRoutingModule,
      FormsModule,
      MaterialModule
    ],
   
  })
  
  export class supriseModule{}