import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

interface Select {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-offer-package-list',
  templateUrl: './offer-package-list.component.html',
  styleUrls: ['./offer-package-list.component.scss']
})
export class OfferPackageListComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  searchResult: any;
  artistDetails: any;
  nextPage: number = 1;
  offerList: any = [];
  artistSearchList: any = [];
  currentPage: number = 1;
  successResult: any = [];
  displayedColumns: string[] = [
    'accountId', 'name', 'image', 'travelCity',
    'ArtistCategory', 'GenreCategory', 'EventCategory', 'InstrumentsCategory', 'Actions'
  ];

  dataReceived: any;
  travelCity: any;
  tableData1: { headerRow: string[]; };
  totalPage: any;
  id: any;

  selection: Select[] = [
    { value: 'view', viewValue: 'View' },
    { value: 'edit', viewValue: 'Edit' },
    { value: 'delete', viewValue: 'Delete' }
  ];
  selectedValue: any;
  constructor(private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {

    this.tableData1 = {
      headerRow: ['User ID', 'Offer ID', 'Price', 'Name', 'Contact No', 'Email', 'Currency', 'Recommendation', 'Actions'],
    };
    this.getOfferPackage();
  }


  getOfferPackage() {
    this.currentPage = 1;
    const PayLoad = {
      type: 'offer'
    }
    this.enableSpinner$.next(true);
    this.commonService.getAllList(PayLoad, this.nextPage).subscribe(data => {
      this.enableSpinner$.next(false);
      if (data.Response) {
        this.totalPage = data.Response.count;
        this.nextPage += 1;

        data.Response.forEach(element => {
          this.offerList.push(element)
        })
      }
    })
  }

  selectChange(event, id) {
    console.log(event.value);
    console.log(id);
    this.selectedValue = event.value;
    if (this.selectedValue === 'view') {
      this.router.navigate(["/offerPackage", "offerPackage-details"], { queryParams: { id: id } });
    } else if (this.selectedValue === 'edit') {
      this.router.navigate(["/offerPackage", "offerPackage-edit"], { queryParams: { id: id } });
    } else {
      console.log('delete')
    }
  }

  addOfferPackage() {
    this.router.navigate(["/offerPackage", "offerPackage-add"]);
  }

  viewMoreArtists() {
    this.getOfferPackage();
  }

  //search
  search(event) {
    this.searchResult = event.target.value;
    setTimeout(() => {
      this.currentPage = 1;
      this.searchApiCal();
    }, 1500);

  }

  searchApiCal() {
    if (this.searchResult.length >= 3) {
      this.offerList = [];
      this.nextPage = 1;
      const payload = {
        first_name: true,
        artist: this.searchResult
      }
      this.enableSpinner$.next(true);
      this.commonService.search(payload, this.currentPage).subscribe(success => {
        this.enableSpinner$.next(false);
        this.currentPage += 1;
        this.successResult = success.Response.result;
        success.Response.result.forEach(el => {
          this.offerList.push(el);
          console.log(this.offerList);
        })
      });
    } else if (this.searchResult.length < 1) {
      this.nextPage = 1;
      this.offerList = [];
      this.getOfferPackage();
    }
  }

  viewMoreSearch() {
    const payload = {
      first_name: true,
      artist: this.searchResult
    }
    this.enableSpinner$.next(true);
    this.commonService.search(payload, this.currentPage).subscribe(success => {
      this.enableSpinner$.next(false);
      this.currentPage += 1;
      this.successResult = success.Response.result;
      success.Response.result.forEach(el => {
        this.offerList.push(el);
      })
    });
  }

}