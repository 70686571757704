import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NewArtistRegisteredComponent } from './new-artist-registered.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'new-artists-registered',
        pathMatch: 'full'
    },
    { path: 'new-artists-registered', component: NewArtistRegisteredComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NewArtistRoutingModule { }