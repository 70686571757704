
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { BehaviorSubject } from 'rxjs';
import * as XLSX from 'xlsx';

interface Select {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-business-enquiry',
  templateUrl: './business-enquiry.component.html',
  styleUrls: ['./business-enquiry.component.scss']
})
export class BusinessEnquiryComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  data:any= 1;
  fileName= 'ExcelSheet.xlsx';
  enquiryList: any =[];
  tableData1: { headerRow: string[]; };
  selectedValue: string;
  searchResult: any;
  currentPage:number = 1;
  nextPage:number = 1;
  successResult: any = [];
  
  selection: Select[] = [
    {value: 'view', viewValue: 'View'},
    // {value: 'edit', viewValue: 'Edit'},
    {value: 'delete', viewValue: 'Delete'},
    {value: 'sendAlert', viewValue: 'Send Alert'},

  ];
  constructor(private commonService: CommonService,
              private router:Router,private snackBar: MatSnackBar) { }

  ngOnInit() {
    window.scroll(0,0);
    this.tableData1 = {
      headerRow: [ 'Enquiry ID','Name','Email','Artist Category', 'Event Category', 'Genre Category', 'Event Date','Budget', 'Actions'],
  };
    this.getEnquiryList();

    console.log(this.enquiryList);
  }
  
 getEnquiryList(){
  this.currentPage =1;
  this.enableSpinner$.next(true);
    this.commonService.businessEnquiryList(this.nextPage).subscribe(data =>{
      this.enableSpinner$.next(false);
        this.nextPage +=1;
      data.Response.forEach(element => {
        if(!element.is_deleted){
          this.enquiryList.push(element)
          console.log(this.enquiryList)
        }
      })
    })
  }

    //search
    search(event) {
      this.searchResult = event.target.value;
      setTimeout(() => {
        this.currentPage = 1;
        this.searchApiCal();
      }, 1500);
    }
  
    searchApiCal() {
      if (this.searchResult.length >= 3) {
        this.enquiryList = [];
        this.nextPage = 1;
        const payload = {
          first_name: true,
          artist: this.searchResult
        }
        this.enableSpinner$.next(true);
        this.commonService.search(payload, this.currentPage).subscribe(success => {
          this.enableSpinner$.next(false);
          if (success.Response) {
            this.currentPage += 1;
            this.successResult = success.Response.result;
            success.Response.result.forEach(el => {
              if(!el.is_deleted){
                this.enquiryList.push(el);
                console.log(this.enquiryList);
              }
            })
          } else {
            console.log("error")
          }
  
        });
      } else if (this.searchResult.length < 1) {
        this.nextPage = 1;
        this.enquiryList = [];
        this.getEnquiryList();
      }
    }
  gotoAddEnquiry(){
    this.router.navigate(["/enquiry","addEnquiry"]);
  }

  viewMoreArtists(){
    this.getEnquiryList();
  }

  exportToExcel(){
        /* pass here the table id */
        let element = document.getElementById('excel-table');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
     
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     
        /* save to file */  
        XLSX.writeFile(wb, this.fileName);
  }


  selectChange(event , id){
    console.log(event.value);
    console.log(id);
    this.selectedValue= event.value;
    if(this.selectedValue === 'view'){
      this.router.navigate(["/enquiry", "enqDetail"], { queryParams: { id }});
    } else if(this.selectedValue === 'edit'){
      this.router.navigate(["/enquiry" ,"editEnquiry"],{ queryParams: {id}});
    } else if(this.selectedValue === 'delete'){
      this.commonService.deleteEnquiry(id, {is_deleted : true}).subscribe(response => {
        if (response.success == true) {
          this.openSnackbar('Enquiry Deleted Successfully', 'ok')
          this.enquiryList = this.enquiryList.filter(item => item.id !== id);
        }
        else {
          this.openSnackbar('Error Occured', 'ok');
        }
      }, err => {
        console.log(err);
        this.openSnackbar('Error Occured', 'ok');
        
      })
    }else if(this.selectedValue === "sendAlert"){
      this.sendAlert(id);
    }
  }

  sendAlert(id){
    this.commonService.sendAlertEnquiry(id).subscribe(data =>{
      console.log(data);
      this.openSnackbar('Alert Send Successfully', 'ok');
      
    }, err => {
      console.log(err);
      this.openSnackbar('Error Occured', 'ok');
      
    })
  }
  // viewEnquiryDetails(id){
  //   console.log('checking',id)
  //   this.router.navigate(["/enquiry", "enqDetail"], { queryParams: { id }});
  // }
  
  // EditEnquiry(id){
  //   this.router.navigate(["/enquiry" ,"editEnquiry"],{ queryParams: {id}});
  // }

  openSnackbar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 1000,
    });
  }
}

