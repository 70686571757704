import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({ name: 'instrument' })
export class Instrument implements PipeTransform {

    instrumentData: any={
        "cajon": "Cajon",
        "banjo": "Banjo",
        "tango": "Tango",
        "drum" : "Drum",
        "brass_band": "Brass Band",
        "beat_boxers": "Beat Boxer",
        "bands": "Bands",
        "orchestra": "Orchestra",
        "dj": "DJ",
        "rudraveena": "Rudraveena",
        "nagaswaran": "Nag aswaran",
        "junk_instruments": "Junk Instruments",
        "synthesizer": "Synthesizer",
        "dhol": "Dhol",
        "guitar": "Guitar",
        "tabla": "Tabla",
        "harmonium": "Harmonium",
        "electric_guitar": "Electric Guitar",
        "conga": "Conga",
        "flute": "Flute",
        "villain": "Villain",
        "saxophone": "Saxophone", 
        "piano": "Piano",
        "veena": "Veena",
        "trumpet": "Trumpet",
        "tubas": "Tubas",
        "sitar": "Sitar",
        "game": "Game",
        "xylophone": "Xylophone",
        "keyboard": "Keyboard",
        "mixer": "Mixer",
        "r_and_b": "R&B",
        "classic": "Classic",
        "rock": "Rock"
        };
    incomingValues : any = [];


    transform(value: any){
        this.incomingValues = value;
        return this.incomingValues.map(val => this.instrumentData[val]);
        
    }
}