import { Component, OnInit, ViewChild} from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialogComponent } from '../mat-dialog/mat-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {
  public enableSpinner$ = new BehaviorSubject<boolean>(false);
  chatList: any =[];
  // showToggle= true;
  message: any;
  approve= false;
  id: any;
  loaded = false;
  constructor( private commonService: CommonService,
               private router:Router,
               private snackBar: MatSnackBar,
               public dialog: MatDialog,
               private location: Location
               ) {}

  ngOnInit() {
    console.log("ngonit from chat list")
    this.getChatList();
  }

  getChatList(){
    this.enableSpinner$.next(true);
    this.commonService.getChatList().subscribe(data =>{
      this.enableSpinner$.next(false);
      data.Response.result.forEach(element => {
        this.chatList.push(element)
      })
      this.loaded = true;
    })
  }

  toggle(id){
      this.approve = true;
      // this.chatValueUpdate(id)
      const payLoad= {
        is_approved: this.approve
        }
        console.log(payLoad);
    this.commonService.update(id,payLoad).subscribe(response => {
      console.log(response)
      if(response.success === true){
        this.openSnackbar('Approved Successfully', 'ok')
      //  window.location.reload();
      this.chatList= [];
      this.getChatList();
      } else {
        this.openSnackbar('Error Occured', 'ok')
      }
    })
  }
  
  openDialog(id): void {
    this.id= id;
    console.log(this.id)
    const dialogRef = this.dialog.open(MatDialogComponent, {
      width: '400px',
      // disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result === '') { 
        console.log('click to  cancel');
      } else {
        console.log('click to send');
        console.log(result);
      this.message= result;
      const payLoad= {
          admin_text : this.message,
          }
          console.log(payLoad);
    this.commonService.update(id,payLoad).subscribe(response => {
      console.log(response);
      if(response.success === true){
        this.openSnackbar('Message Sent  Successfully', 'ok')
        this.chatList= [];
        this.getChatList();
      } else {
        this.openSnackbar('Error Occured', 'ok')
      }
    })
      }
    });
  }

  
  openSnackbar(message:string, action: string){
    this.snackBar.open(message,action,{
      duration: 2000,
    });
  }

// chatValueUpdate(id?){
  // const payLoad= {
  //   admin_text : this.message,
  //   is_approved: this.approve
  //   }
// console.log(payLoad);
    // this.commonService.update(id,payLoad).subscribe(response => {
    //   console.log(response)
    // })

// }

  
}
