import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  gotoDashboard() {
    if (this.loginForm.value.username == 'live101' && this.loginForm.value.password == 'live101@321' ||
      this.loginForm.value.username == 'live101_admin' && this.loginForm.value.password == 'live101admin@123' ||
      this.loginForm.value.username == 'admin' && this.loginForm.value.password == 'live101admin@007' ||
      this.loginForm.value.username == 'event_manager_delhi' && this.loginForm.value.password == 'live101@123!' ||
      this.loginForm.value.username == 'event_manager_mumbai' && this.loginForm.value.password == 'live101@123!') {
      if (this.loginForm.value.username == 'live101_admin') {
        this.router.navigate(['/artist-sourcing']);
      } else {
        this.router.navigate(['/artists']);
      }
      localStorage.setItem('username', this.loginForm.value.username);
    } else {
      this.openSnackbar('Wrong Crediantial', 'ok')
      console.log("error");
    }
  }

  openSnackbar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
